import { userProfileConstants } from "../../_constants";
import { profileDetails } from "../../_services";
import { toast } from "react-toastify";

export const userProfileRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: userProfileConstants.USERPROFILE_REQUEST, data: {} });
    profileDetails(data)
      .then((response) => {
        dispatch({
          type: userProfileConstants.USERPROFILE_SUCCESS,
          payload: response.data,
        });
        if (callback) callback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: userProfileConstants.USERPROFILE_FAILED,
          payload: error.response,
        });
        if (callback) callback(false);
      });
  };
};
