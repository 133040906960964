import { manualmarketmatchdashboardConstants } from "../../_constants";
import { listManualMarketMatchDashboard, createNewManualMarket, createFancy, listMarket, listMatchDetail, listFancyType, createExistManualSession, reset, createExistManualMarket, resetMarket } from "../../_services";
import { toast } from "react-toastify";

export const createRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: manualmarketmatchdashboardConstants.MANUALMARKET_CREATE_REQUEST, data: {} });
    createNewManualMarket(data)
      .then((response) => {
        callback(response.data);
        toast.success("Recorded added successfully");
        dispatch({
          type: manualmarketmatchdashboardConstants.MANUALMARKET_CREATE_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);

        callback(false);
        dispatch({ type: manualmarketmatchdashboardConstants.MANUALMARKET_CREATE_FAILED, data: error });
      });
  };
};

export const createFancyRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: manualmarketmatchdashboardConstants.FANCY_CREATE_REQUEST, data: {} });
    createFancy(data)
      .then((response) => {
        callback(response.data);
        toast.success("Recorded added successfully");
        dispatch({
          type: manualmarketmatchdashboardConstants.FANCY_CREATE_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: manualmarketmatchdashboardConstants.FANCY_CREATE_FAILED, data: error });
      });
  };
};

export const listRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: manualmarketmatchdashboardConstants.MANUALMARKETMATCHDASHBOARD_LIST_REQUEST, data: {} });
    listManualMarketMatchDashboard(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: manualmarketmatchdashboardConstants.MANUALMARKETMATCHDASHBOARD_LIST_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: manualmarketmatchdashboardConstants.MANUALMARKETMATCHDASHBOARD_LIST_FAILED,
          data: error.response,
        });
      });
  };
};

export const listMarketRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: manualmarketmatchdashboardConstants.MARKET_LIST_REQUEST, data: {} });
    listMarket(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: manualmarketmatchdashboardConstants.MARKET_LIST_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: manualmarketmatchdashboardConstants.MARKET_LIST_FAILED,
          data: error.response,
        });
      });
  };
};

export const listMatchDetailRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: manualmarketmatchdashboardConstants.MATCH_DETAIL_REQUEST, data: {} });
    listMatchDetail(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: manualmarketmatchdashboardConstants.MATCH_DETAIL_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: manualmarketmatchdashboardConstants.MATCH_DETAIL_FAILED,
          data: error.response,
        });
      });
  };
};

export const listFancyTypeRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: manualmarketmatchdashboardConstants.FANCY_TYPE_LIST_REQUEST, data: {} });
    listFancyType(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: manualmarketmatchdashboardConstants.FANCY_TYPE_LIST_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: manualmarketmatchdashboardConstants.FANCY_TYPE_LIST_FAILED,
          data: error.response,
        });
      });
  };
};

export const createExistManualSessionRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: manualmarketmatchdashboardConstants.EXIST_MANUAL_SESSION_CREATE_REQUEST, data: {} });
    createExistManualSession(data)
      .then((response) => {
        callback(response.data);
        toast.success("Recorded added successfully");
        dispatch({
          type: manualmarketmatchdashboardConstants.EXIST_MANUAL_SESSION_CREATE_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: manualmarketmatchdashboardConstants.EXIST_MANUAL_SESSION_CREATE_FAILED, data: error });
      });
  };
};


export const resetRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: manualmarketmatchdashboardConstants.RESET_CREATE_REQUEST, data: {} });
    reset(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: manualmarketmatchdashboardConstants.RESET_CREATE_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: manualmarketmatchdashboardConstants.RESET_CREATE_FAILED,
          data: error.response,
        });
      });
  };
};

export const createExistManualMarketRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: manualmarketmatchdashboardConstants.EXIST_MANUAL_MARKET_CREATE_REQUEST, data: {} });
    createExistManualMarket(data)
      .then((response) => {
        callback(response.data);
        toast.success("Recorded added successfully");
        dispatch({
          type: manualmarketmatchdashboardConstants.EXIST_MANUAL_MARKET_CREATE_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: manualmarketmatchdashboardConstants.EXIST_MANUAL_MARKET_CREATE_FAILED, data: error });
      });
  };
};

export const resetMarketRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: manualmarketmatchdashboardConstants.RESET_MARKET_REQUEST, data: {} });
    resetMarket(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: manualmarketmatchdashboardConstants.RESET_MARKET_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: manualmarketmatchdashboardConstants.RESET_MARKET_FAILED,
          data: error.response,
        });
      });
  };
};
