import { creditCompanyCoinsConstants } from "../../_constants";
import { creditCompanyCoins } from "../../_services";
import { toast } from "react-toastify";

export const creditCompanyCoinsRequest = (data, callback) => {
    return (dispatch) => {
      dispatch({ type: creditCompanyCoinsConstants.CREDIT_COMPANY_COINS_REQUEST, data: {} });
      creditCompanyCoins(data)
        .then((response) => {
          callback(response.data);
          dispatch({
            type: creditCompanyCoinsConstants.CREDIT_COMPANY_COINS_SUCCESS,
            data: response.data,
          });
        })
        .catch((error) => {
          callback(false);
          dispatch({
            type: creditCompanyCoinsConstants.CREDIT_COMPANY_COINS_FAILED,
            data: error.response,
          });
        });
    };
  };
  