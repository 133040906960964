import React, { Component } from "react";
import Main from "../../components/layouts/Main";
import { connect } from "react-redux";
import NumberForm from "components/MatkaNumber/NumberForm";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  editNumber = (data) => {

  };

  render() {
    return (
      <Main>
        <NumberForm
          {...this.props}
          breadcrumbTitle={'Edit Number'}
          editNumber={this.editNumber}
          edit={true}
        />
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});
export const EditMatkaNumber = connect(mapstatetop)(Edit);
