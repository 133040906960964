import React, { Component } from 'react'
import Main from "../../components/layouts/Main";
import ShowSettleComp from 'components/ManageLedger/ShowSettleComp';

export class ShowSettelment extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Main>
                <ShowSettleComp />
            </Main>
        )
    }
}

export default ShowSettelment
