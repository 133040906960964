import React, { Component } from "react";
import { Link } from "react-router-dom";
import InnerPageHeader from "../common/InnerPageHeader";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router-dom";
import Form from "react-bootstrap/Form";
import {
  createExistManualSessionRequest,
  resetRequest,
  fetchExistManualSessionRequest,
  listMatchDetailRequest,
} from "../../_actions/manualmarketmatchdashboard/manualmarketmatchdashboard.actions";
class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObj: {
        session_id: "",
        event_id: "",
        lay_price_1: "",
        back_price_1: "",
        status: ""
      },
      session_name: "",
      event_name: "",
      ExistManualSessionData: [],
    };

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    const {
      event_id,
      event_name,
      session_id,
      session_name,
      lay_price_1,
      back_price_1,
    } = this.props.match.params;
    this.state.formObj.lay_price_1 = lay_price_1;
    this.state.formObj.back_price_1 = back_price_1;
    this.state.formObj.event_id = event_id;
    this.state.formObj.session_id = session_id;
    this.state.session_name = session_name.replace(/\s+/g, " ");
    this.state.event_name = event_name.replace(/\s+/g, " ");

    let sendObjMatchDetail = {
      event_id: event_id,
    };

    this.props.dispatch(
      listMatchDetailRequest(sendObjMatchDetail, (resMatchDetail) => {
        if (resMatchDetail && resMatchDetail.data) {
          this.setState({
            ExistManualSessionData: resMatchDetail.data.fancy,
          });
        }
      })
    );
  }

  reset = (e) => {
    e.preventDefault();
    let { formObj } = this.state;
    this.props.dispatch(
      resetRequest({ ...formObj, back_price_1: "0", lay_price_1: "0" }, (res) => {
        this.setState((state) => ({
          formObj: {
            ...state.formObj,
            back_price_1: "0",
            lay_price_1: "0",
          },
        }));
        // if (res)
        //   this.setState({
        //     redirect: `/manual-market-match-dashboard/list/${this.state.formObj.event_id}/${this.state.event_name}`,
        //   });
      })
    );
  };

  onSubmit = (e) => {
    e.preventDefault();
    const customId = "custom-id-yes";
    let { formObj } = this.state;
    if (this.validator.allValid()) {
      this.props.dispatch(
        createExistManualSessionRequest(formObj, (res) => {
          //if (res) this.setState({ redirect: `/manual-market-match-dashboard/list/${this.state.formObj.event_id}/${this.state.event_name}` });
          if (res.error != "") {
            toast(res.error, {
              toastId: customId,
            });
          } else {
            toast.dismiss();
            toast({
              toastId: customId,
            });
          }
        })
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInput = (e) => {
    const { name, value } = e.target; // <-- moved outside asynchronous context
    this.setState((state) => ({
      formObj: {
        ...state.formObj,
        [name]: value,
      },
    }));
  };

  ballStart = (e) => {
    e.preventDefault();
    this.setState((state) => ({
      formObj: {
        ...state.formObj,
        back_price_1: "0",
        lay_price_1: "0"
      },
    }));
    const customId = "custom-id-yes";
    let { formObj } = this.state;
    if (this.validator.allValid()) {
      this.props.dispatch(
        createExistManualSessionRequest({ ...formObj, back_price_1: "0", lay_price_1: "0", status: "Ball Start" }, (res) => {
          //if (res) this.setState({ redirect: `/manual-market-match-dashboard/list/${this.state.formObj.event_id}/${this.state.event_name}` });
          if (res.error != "") {
            toast(res.error, {
              toastId: customId,
            });
          } else {
            toast.dismiss();
            toast({
              toastId: customId,
            });
          }
        })
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    const {
      redirect,
      ExistManualSessionData,
      formObj: { session_id, event_id, lay_price_1, back_price_1 },
      session_name,
      event_name,
    } = this.state;
    const breadcrumbPath = `/manual-market-match-dashboard/list/${event_id}/${event_name}`;

    const {
      heading,
      breadcrumbTitle,
      breadcrumb,
      navigationUrl,
      action,
      parentType,
    } = this.props;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <>
        <InnerPageHeader
          pageTitle={event_name}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Manual Market Dashboard", url: breadcrumbPath },
          ]}
          currenPageTitle="Add"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-content">
                  <form onSubmit={this.onSubmit} className="form-horizontal">
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Session Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="hidden"
                          name="event_id"
                          id="event_id"
                          value={event_id}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <input
                          type="text"
                          className="form-control"
                          value={session_name}
                          readOnly
                          onChange={(e) => this.handleInput(e)}
                        />
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">No</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="lay_price_1"
                          id="lay_price_1"
                          value={lay_price_1}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message(
                            "lay_price_1",
                            lay_price_1,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">Yes</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="back_price_1"
                          id="back_price_1"
                          value={back_price_1}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message(
                            "back_price_1",
                            back_price_1,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>

                    <div className="hr-line-dashed"></div>

                    <div className="form-group">
                      <div className="col-sm-4 col-sm-offset-2">
                        <button className="btn btn-primary" type="submit">
                          Update
                        </button>
                        <button
                          className="btn btn-primary"
                          style={{ marginLeft: "5px" }}
                          onClick={(e) => this.reset(e)}
                        >
                          Reset
                        </button>
                        <button
                          className="btn btn-primary"
                          style={{ marginLeft: "5px" }}
                          onClick={(e) => this.ballStart(e)}
                        >
                          Ball Start
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
