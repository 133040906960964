import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
export class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Fragment>
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-lg-10">
                        <h2>{`SEARCH`}</h2>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Dashboard</Link>
                            </li>

                            <li className="breadcrumb-item active">
                                <strong>{`Search`}</strong>
                            </li>
                        </ol>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
                <div className="wrapper wrapper-content animated fadeInRight">
                    <div className="row">
                        <div className="col-12">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <h5>{'Search'}</h5>
                                </div>
                                <div className="ibox-content">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            Search
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}

export default Search