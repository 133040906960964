import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import InnerPageHeader from "components/common/InnerPageHeader";
import "react-datepicker/dist/react-datepicker.css";
import {
  fastMatkaConfig,
  getFastMatkaConfigList,
  getMatkaConfigList,
  matkaConfig,
  getMarketWinning,
  updateMarketWinning,
  getFastMarketWinning,
  updateFastMarketWinning
} from "_services/matkaservice";
import { toast } from "react-toastify";
import Loader from "components/common/Loader";

class MatkaConfigForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: {
        jodi_min_stack: "",
        akda_min_stack: "",
        other_min_stack: "",
        jodi_max_match_stack: "",
        akda_max_match_stack: "",
        max_other_market_stack: "",
        other_max_stack: "",
        jodi_bet_close_time: "",
        other_bet_close_time: "",
        single_akda_bet_close_time: "",
        matka_open_time: "00:00",
        matka_close_time: "00:00",
        marketWinnerList: []
      },
      isLoading: false,
      isLoadingWinner: false
    };

    this.validator = new SimpleReactValidator({
      messages: {
        required: "This field is required",
        min: "Invalid time format",
      },
    });
    this.isFast = this.props?.breadcrumbTitle === "Fast Matka Config";
  }

  componentDidMount() {
    this.geConfigList();
    this.geMarketWinnerList();
  }

  geConfigList = async () => {
    try {
      const res = this.isFast
        ? await getFastMatkaConfigList()
        : await getMatkaConfigList();
      if (res?.data?.data) {
        const valueObj = {
          id: res?.data?.data?._id.$oid,
          ...res?.data?.data,
          single_akda_bet_close_time:
            res?.data?.data?.single_akda_bet_close_time,
          jodi_bet_close_time: res?.data?.data?.single_akda_bet_close_time,
          other_bet_close_time: res?.data?.data?.single_akda_bet_close_time,
        };
        this.setState({
          initialFormValue: valueObj,
          formValue: valueObj,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };
  geMarketWinnerList = async () => {
    try {
      const res = this.isFast
        ? await getFastMarketWinning()
        : await getMarketWinning();
      if (res?.data?.data) {

        this.setState({
          marketWinnerList: res.data.data
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };

  handleInput = (e) => {
    const {
      target: { value, name },
    } = e;
    this.setState((s) => ({
      formValue: { ...s.formValue, [name]: value },
    }));
  };

  onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.validator.allValid()) {
      this.setState({ isLoading: true });
      const payload = {
        ...this.state.formValue,
        single_akda_bet_close_time:
          this.state.formValue?.single_akda_bet_close_time,
        other_bet_close_time: this.state.formValue?.single_akda_bet_close_time,
      };
      if (!this.isFast) {
        payload["jodi_bet_close_time"] =
          this.state.formValue?.single_akda_bet_close_time;
      }
      try {
        const res = this.isFast
          ? await fastMatkaConfig(payload)
          : await matkaConfig(payload);
        if (res?.data?.error === "") {
          toast.success(res?.data.message);
          this.setState({ initialFormValue: payload });
        }
        this.setState({ isLoading: false });
      } catch (error) {
        toast.error(
          error?.response?.data.error ? error?.response?.data.error : ""
        );
        this.setState({ isLoading: false });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  onSubmitWinner = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.validator.allValid()) {
      this.setState({ isLoadingWinner: true });
      const payload = {
        data: this.state.marketWinnerList
      };

      try {
        const res = this.isFast
          ? await updateFastMarketWinning(payload)
          : await updateMarketWinning(payload);
        if (res?.data?.error === "") {
          toast.success(res?.data.message);
          this.geMarketWinnerList()
        }
        this.setState({ isLoadingWinner: false });
      } catch (error) {
        toast.error(
          error?.response?.data.error ? error?.response?.data.error : ""
        );
        this.setState({ isLoadingWinner: false });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInputChange = (value, name) => {
    const cleanValue = value.replace(/\D/g, "");
    let formattedValue = cleanValue;
    if (cleanValue.length > 2) {
      formattedValue = `${cleanValue.slice(0, 2)}:${cleanValue.slice(2)}`;
    }
    if (cleanValue.length === 1) {
      formattedValue = cleanValue == 1 || cleanValue == 2 ? cleanValue : "";
    }
    this.setState((s) => ({
      formValue: { ...s.formValue, [name]: formattedValue },
    }));
  };

  handleInputChangeWinner = (value, market_id,) => {
    this.setState(prevState => {
      const NewList = prevState.marketWinnerList.map(item => {
        if (item.matka_market_id === Number(market_id)) {
          item.winning_multiple = value
        }
        return item
      })
      return {
        marketWinnerList: NewList
      }
    })
  }
  render() {
    const {
      formValue: {
        jodi_min_stack,
        akda_min_stack,
        jodi_max_stack,
        akda_max_stack,
        other_min_stack,
        other_max_stack,
        jodi_bet_close_time,
        single_akda_bet_close_time,
        other_bet_close_time,
        matka_open_time,
        matka_close_time,
      },
      formValue,
      initialFormValue,
      isLoading,
      marketWinnerList,
      isLoadingWinner
    } = this.state;

    const { breadcrumbTitle } = this.props;
    return (
      <>
        <InnerPageHeader
          pageTitle={breadcrumbTitle}
          breadcrumbs={[{ title: "Dashboard", url: "/" }]}
          currenPageTitle={breadcrumbTitle}
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <form
                    style={{ margin: "20px 50px" }}
                    onSubmit={this.onSubmit}
                    className="input_form"
                  >
                    <div className="row input_form_div">
                      <div className="col-sm-6">
                        <label className="control-label">Bazar open time</label>
                        <input
                          className="form-control"
                          type="text"
                          name="matka_open_time"
                          onChange={(e) =>
                            e.target.value.length <= 5 &&
                            this.handleInputChange(
                              e.target.value,
                              e.target.name
                            )
                          }
                          value={matka_open_time}
                        />

                        <em>
                          {this.validator.message(
                            "matka_open_time",
                            matka_open_time,
                            "required|min:5"
                          )}
                        </em>
                      </div>
                      <div className="col-sm-6">
                        <label className=" control-label">
                          Bazar close time
                        </label>
                        <input
                          className="form-control"
                          name="matka_close_time"
                          value={matka_close_time}
                          onChange={(e) =>
                            e.target.value.length <= 5 &&
                            this.handleInputChange(
                              e.target.value,
                              e.target.name
                            )
                          }
                        />
                        {this.validator.message(
                          "matka_close_time",
                          matka_close_time,
                          "required|min:5"
                        )}
                      </div>
                      <div className="col-sm-12">
                        <label className="control-label">
                          Aakda Bet bet close time ( in minute )
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="single_akda_bet_close_time"
                          id="last_name"
                          value={single_akda_bet_close_time}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message(
                            "single_akda_bet_close_time",
                            single_akda_bet_close_time,
                            "required|numeric"
                          )}
                        </em>
                      </div>
                      {!this.isFast && (
                        <div className="col-sm-12">
                          <label className="control-label">
                            Jodi bet close time ( in minute )
                          </label>

                          <input
                            type="number"
                            className="form-control"
                            name="jodi_bet_close_time"
                            id="last_name"
                            disabled
                            value={single_akda_bet_close_time}
                            onChange={(e) => this.handleInput(e)}
                          />
                          {/* <em>
                          {this.validator.message(
                            "jodi_bet_close_time",
                            jodi_bet_close_time,
                            "required|numeric"
                          )}
                        </em> */}
                        </div>
                      )}
                      <div className="col-sm-12">
                        <label className="control-label">
                          TP + DP + SP bet close time ( in minute )
                        </label>{" "}
                        <input
                          type="number"
                          className="form-control"
                          name="other_bet_close_time"
                          id="last_name"
                          disabled
                          value={single_akda_bet_close_time}
                          onChange={(e) => this.handleInput(e)}
                        />
                        {/* <em>
                          {this.validator.message(
                            "other_bet_close_time",
                            other_bet_close_time,
                            "required|numeric"
                          )}
                        </em> */}
                      </div>
                      {!this.isFast && (
                        <>
                          <div className="col-sm-6">
                            <label className="control-label">
                              Jodi min stack
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="jodi_min_stack"
                              id="last_name"
                              value={jodi_min_stack}
                              onChange={(e) => this.handleInput(e)}
                            />
                            <em>
                              {this.validator.message(
                                "jodi_min_stack",
                                jodi_min_stack,
                                "required|numeric"
                              )}
                            </em>
                          </div>
                          <div className="col-sm-6">
                            <label className="control-label">
                              Jodi max stack
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="jodi_max_stack"
                              id="last_name"
                              value={jodi_max_stack}
                              onChange={(e) => this.handleInput(e)}
                            />
                            <em>
                              {this.validator.message(
                                "jodi_max_stack",
                                jodi_max_stack,
                                "required|numeric"
                              )}
                            </em>
                          </div>
                        </>
                      )}
                      <div className="col-sm-6">
                        <label className="control-label">Akda min stack</label>
                        <input
                          type="number"
                          className="form-control"
                          name="akda_min_stack"
                          id="last_name"
                          value={akda_min_stack}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message(
                            "akda_min_stack",
                            akda_min_stack,
                            "required|numeric"
                          )}
                        </em>
                      </div>
                      <div className="col-sm-6">
                        <label className="control-label">Akda max stack</label>
                        <input
                          type="number"
                          className="form-control"
                          name="akda_max_stack"
                          id="last_name"
                          value={akda_max_stack}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message(
                            "akda_max_stack",
                            akda_max_stack,
                            "required|numeric"
                          )}
                        </em>
                      </div>
                      <div className="col-sm-6">
                        <label className="control-label">Other min stack</label>
                        <input
                          type="number"
                          className="form-control"
                          name="other_min_stack"
                          id="last_name"
                          value={other_min_stack}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message(
                            "other_min_stack",
                            other_min_stack,
                            "required|numeric"
                          )}
                        </em>
                      </div>
                      <div className="col-sm-6">
                        <label className="control-label">Other max stack</label>
                        <input
                          type="number"
                          className="form-control"
                          name="other_max_stack"
                          id="last_name"
                          value={other_max_stack}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message(
                            "other_max_stack",
                            other_max_stack,
                            "required|numeric"
                          )}
                        </em>
                      </div>
                      <div className="form-group">
                        <div className="matka_form_submit">
                          <button
                            className="btn btn-white mr-3"
                            type="button"
                            style={{ marginRight: "1rem" }}
                            onClick={() => this.props.history.goBack()}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-primary d-flex"
                            type="submit"
                            disabled={
                              JSON.stringify(formValue) ===
                              JSON.stringify(initialFormValue) || isLoading
                            }
                          >
                            {isLoading && <Loader boxClass="active" />}Save
                            Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {marketWinnerList && marketWinnerList.length && (
            <div className="row">
              <div className="col-lg-12">
                <div className="ibox float-e-margins">
                  <div className="ibox-title">
                    <h5>Matka Market Winnings</h5>
                  </div>
                  <div className="ibox-title">
                    <form
                      style={{ margin: "20px 50px" }}
                      onSubmit={this.onSubmitWinner}
                      className="input_form"
                    >
                      <div className="row input_form_div">
                        {marketWinnerList.map((item, index) => (
                          <div className="col-sm-6">
                            <label className="control-label">{item.type}</label>
                            <input
                              className="form-control"
                              type="number"
                              name={item.matka_market_id}
                              onChange={(e) =>
                                e.target.value.length <= 5 &&
                                this.handleInputChangeWinner(
                                  e.target.value,
                                  e.target.name,
                                  item.type
                                )
                              }
                              value={item.winning_multiple}
                            />
                          </div>
                        ))}

                        <div className="form-group col-sm-12">
                          <div className="matka_form_submit">

                            <button
                              className="btn btn-primary d-flex"
                              type="submit"
                              disabled={isLoadingWinner}
                            >
                              {isLoadingWinner && <Loader boxClass="active" />}Save
                              Changes
                            </button>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>

            </div>)}
        </div>
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(MatkaConfigForm);
