import React, { useState, useEffect } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import { getMaintenanceSettings, updateMaintenanceSettings } from "_services";
import { useSelector } from "react-redux";
import "./maintenance.css";
import { toast } from "react-toastify";
import { Button, Modal, ToggleButton } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";

const MaintenanceComponent = () => {
    const role = localStorage.getItem("role");
    const [maintenanceStatus, setMaintenanceStatus] = useState(0)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [maintenanceCode, setMaintenanceCode] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [Userid, setUserID] = useState('')




    const getSettingMaintenance = () => {
        let sendData = {};
        getMaintenanceSettings(sendData)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    setMaintenanceStatus(response.data.data?.maintenance_mode)
                    setUserID(response.data.data?.id?.$oid)
                } else {
                }
            })
            .catch((error) => {
            });
    }

    useEffect(() => {
        getSettingMaintenance()
    }, [])

    const handleMaintenanceStatus = (e) => {

        setShowLoader(true)
        const payload = {
            id: Userid,
            maintenance_mode: maintenanceStatus,
            code: maintenanceCode

        }

        updateMaintenanceSettings(payload)
            .then((response) => {
                setIsLoading(false)
                if (response?.status === 200) {
                    setShowLoader(false)
                    toast.success(response?.data?.message)
                    setShowConfirmationModal(false)
                    getSettingMaintenance()
                    setMaintenanceCode('')
                } else {
                    toast.error(response?.data?.message)
                    setShowLoader(false)
                    setMaintenanceCode('')
                }
            })
            .catch((error) => {
                toast.error(error.response.data.error);
                setShowLoader(false)
                setIsLoading(false)

                setMaintenanceCode('')
            });

    }

    const ToggleButtonHandle = () => {

        setMaintenanceStatus(!maintenanceStatus ? 1 : 0)
        setShowConfirmationModal(true)
        setIsLoading(true)
    }





    return (
        <>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10">
                    <h2>Maintenance Settings</h2>
                    <ol className="breadcrumb">
                        <li>
                            <a href="/">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>Maintenance Settings</strong>
                        </li>
                    </ol>
                </div>
                <div className="col-lg-2"></div>
            </div>
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-12">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>Maintenance Settings</h5>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <div
                                        id="DataTables_Table_0_wrapper"
                                        className="dataTables_wrapper form-inline dt-bootstrap"
                                    >
                                        {/* <table className="table table-striped table-bordered table-hover dataTables-example">
                                            <thead>
                                                <tr>
                                                    <th>Mode</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="gradeX">
                                                    <td>Maintenance Mode</td>
                                                    <td><label className="switch">
                                                        <input
                                                            // disabled={isLoading}
                                                            type="checkbox"
                                                        // checked={isHideCasino}
                                                        // onChange={(e) => handleShowHideCasino(e)
                                                        // }
                                                        />
                                                        <span className="slider round">
                                                        </span>
                                                    </label></td>
                                                </tr>
                                            </tbody>
                                        </table> */}
                                        <div className='maintenanenceMode'>
                                            <div >
                                                <strong>
                                                    Maintenance Mode
                                                </strong>
                                            </div>
                                            <div>
                                                <label className="switch">
                                                    <input
                                                        disabled={isLoading}
                                                        type="checkbox"
                                                        checked={maintenanceStatus ? true : false}
                                                        onChange={(e) => ToggleButtonHandle()
                                                        }
                                                    />
                                                    <span className="slider round">
                                                    </span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row" style={{ margin: "15px 0px" }}>
                                            <div className="clearfix visible-xs"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="wrapper wrapper-content">
                    <div className="row" style={{ margin: "0 !important" }}>
                        <div className="match-index-row header-top-spacing">
                            <div
                                className="row"
                                style={{ paddingBottom: "30px", margin: "auto auto" }}
                            ></div>
                        </div>
                    </div>

                    <div className="wrapper wrapper-content">
                        <div className="row">
                            <div className="col-lg-12"></div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={showConfirmationModal}
                onHide={() => {
                    if (!showLoader) {
                        setShowConfirmationModal(false)
                        setMaintenanceStatus(!maintenanceStatus ? 1 : 0)

                    }
                }}
                style={{ opacity: 1 }}
            >
                <Modal.Header closeButton>Confrim Message</Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>
                            Please enter 4 digit's verification code.
                        </label>
                        <input
                            placeholder="verification code"
                            className="form-control"
                            value={maintenanceCode} onChange={(e) => setMaintenanceCode(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            if (!showLoader) {
                                setShowConfirmationModal(false)
                                setMaintenanceStatus(!maintenanceStatus ? 1 : 0)

                            }
                        }
                        }
                    >
                        No
                    </Button>
                    <Button
                        type="button"
                        onClick={() => !showLoader && handleMaintenanceStatus()}
                    //   disabled={rollbackCode.length !== 4}
                    >
                        {showLoader ? <ClipLoader color={"#FFFFFF"} loading={true} size={16} /> : 'Submit'}

                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MaintenanceComponent