import Main from "../../components/layouts/Main";
import ListUI from "../../components/MatkaCategory/List";
import React from "react";

export function FastMatkaCategory(props) {
  return (
    <Main>
      <ListUI type="fast_matka" {...props} />
    </Main>
  );
}
