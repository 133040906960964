import React, { useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import "./matka.css";
export default function MatkaMarketView(props) {
  const [toggle, setToggle] = useState(true);
  const { data, pindex, fetchMarketPosition, keyValue, liveData } = props;

  return (
    <div className="row scroll-form matka_container" key={pindex}>
      {data.market_type == 7 && (
        <div
          className="bet-place-tbl-th align-left"
          style={{ backgroundColor: "#6E8FC9" }}
        >
          <div className="d-flex justify-between flex-center">
            <p className="m-0">Market : {data.market_name}</p>
            <div className="d-flex">
              <p className="m-0 ">
                My Position :{" "}
                {data?.runners?.reduce(
                  (pre, item) =>
                    (pre += fetchMarketPosition(
                      data.market_id,
                      item.selectionId
                    )),
                  0
                )}
              </p>
              <div
                className="d-flex pointer"
                onClick={() => setToggle(!toggle)}
              >
                {toggle ? (
                  <AiOutlineDown style={{ marginLeft: "10px" }} />
                ) : (
                  <AiOutlineUp style={{ marginLeft: "10px" }} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {toggle && (
        <div
          className={
            (+data?.[keyValue] === 1 || // "Single Akda"
            +data?.[keyValue] === 4 // Tripple
              ? "runner_value_Singleakda"
              : "") +
            (+data?.[keyValue] === 2 //  singlep atti"
              ? "runner_value_singlepatti"
              : "") +
            (+data?.[keyValue] === 5 ? "runner_value_jodi" : "") + // jodi
            " matka_market_container"
          } // apply css according market name
        >
          {data.market_type == 7 ? (
            data?.runners?.length &&
            data?.runners.map((val, index) => (
              <React.Fragment key={index}>
                <div
                  className={
                    (+data?.[keyValue] === 5 ? "jodi_box " : "") +
                    (parseInt(
                      data.market_name === "Single Akda"
                        ? liveData?.event?.won_akhar
                        : data.market_name === "Jodi"
                        ? liveData?.event?.jodi_result
                        : liveData?.event?.won_by
                    ) == parseInt(val?.runnerName)
                      ? " winner_box "
                      : "") +
                    "matka_market_box"
                  }
                  key={index}
                >
                  <p className="matka_market_bhav"> {val?.runnerName}</p>
                  <p
                    className={
                      fetchMarketPosition(data.market_id, val.selectionId) > 0
                        ? "matka_market_value"
                        : ""
                    }
                  >
                    {fetchMarketPosition(data.market_id, val.selectionId)}
                  </p>
                </div>
                {(index + 1) % (data?.runners?.length / 10) === 0 && //separator jodi {10}, Single Patti {12}, double {9}
                data?.runners.length > 10 &&
                data?.runners.length !== index + 1 ? (
                  <div className="separator_box_line" />
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
}
