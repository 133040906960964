import React, { Component } from "react";
import Main from "../../../components/layouts/Main";
import { connect } from "react-redux";
import MatchesListComponent from "../../../components/MatchesList/MatchesListComponent";
import moment from "moment";
class MatchesList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Main>
        <MatchesListComponent data={moment().toISOString()}/>
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(MatchesList);
