import React, { Component } from 'react'
import Main from "../../components/layouts/Main";
import CreateGroupComponent from 'components/Staff/CreateGroup';
export class CreateGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Main>
                <CreateGroupComponent
                    stateData={this.props.location.state}
                />
            </Main>

        )
    }
}

export default CreateGroup;