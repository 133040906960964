import React, { Component } from "react";
import { Link } from "react-router-dom";
import InnerPageHeader from "../common/InnerPageHeader";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import Form from "react-bootstrap/Form";

import {
  createExistManualMarketRequest,
  resetMarketRequest,
  fetchExistManualMarketRequest,
  listMatchDetailRequest,
} from "../../_actions/manualmarketmatchdashboard/manualmarketmatchdashboard.actions";

import { io } from "socket.io-client";
import { isFloat } from "_helpers";
/*const socket = io('http://localhost:3000');
*/
const bhavNumber = [90, 91, 92, 93, 94, 95, 96, 97]
class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObj: {
        market_id: "",
        event_id: "",
        lay_price_1: 0,
        back_price_1: 0,
        lay_price_2: 0,
        back_price_2: 0,
        selectionId: "",
      },
      event_name: "",
      market_name: "",
      market_type: "",
      ExistManualMarketData: [],
      manualmatchdetail: [],
    };

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    const {
      event_id,
      event_name,
      market_id,
      market_name,
      market_type,
      lay_price_1,
      back_price_1,
      selectionId1,
      runnerName1,
      selectionId2,
      runnerName2,
    } = this.props.match.params;
    this.state.formObj.lay_price_1 = lay_price_1;
    this.state.formObj.back_price_1 = back_price_1;
    this.state.formObj.event_id = event_id;
    this.state.formObj.market_id = market_id;
    // this.state.market_name = market_name.replace(/\s+/g, " ");
    this.state.event_name = event_name.replace(/\s+/g, " ");
    this.state.selectionId1 = selectionId1;
    this.state.selectionId2 = selectionId2;
    this.state.runnerName1 = runnerName1.replace(/\s+/g, " ");
    this.state.runnerName2 = runnerName2.replace(/\s+/g, " ");
    this.state.market_type = market_type;

    let sendObjMatchDetail = {
      event_id: event_id,
    };

    this.props.dispatch(
      listMatchDetailRequest(sendObjMatchDetail, (resMatchDetail) => {
        if (resMatchDetail && resMatchDetail.data) {
          let allMarket = resMatchDetail.data.market;
          let marketName = '';
          for (let i = 0; i < allMarket.length; i++) {
            if (allMarket[i].market_id == market_id) {
              marketName = allMarket[i].market_name;
            }
          }
          this.setState({
            ExistManualMarketData: resMatchDetail.data.fancy,
            manualmatchdetail: resMatchDetail.data.market,
            market_name: marketName
          });
        }
      })
    );
  }

  update_run = (e, a) => {
    e.preventDefault();

    if (this.state.formObj.selectionID != "") {
      // this.state.formObj.lay_price_1 = 1;

      this.state.formObj.back_price_2 = a;
      this.state.formObj.back_price_1 = a;
      this.state.formObj.lay_price_1 = 0;
      this.state.formObj.lay_price_2 = 0;

      let { formObj } = this.state;

      if (this.validator.allValid()) {
        this.props.dispatch(
          createExistManualMarketRequest(formObj, (res) => {
            toast.dismiss();
            toast();
          })
        );
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  };

  resetMarket = (e) => {
    e.preventDefault();
    let { formObj } = this.state;
    this.props.dispatch(
      resetMarketRequest(formObj, (res) => {
        if (res)
          this.setState({
            redirect: `/manual-market-match-dashboard/addExistManualMarket/${this.state.formObj.event_id}/${this.state.event_name}/${this.state.formObj.market_id}/${this.state.selectionId1}/${this.state.runnerName1}/${this.state.selectionId2}/${this.state.runnerName2}/${this.state.market_type}`,
          });
      })
    );
  };

  onSubmit = (e) => {
    e.preventDefault();

    const customId = "custom-id-yes";
    let { formObj } = this.state;
    if (this.validator.allValid()) {
      this.props.dispatch(
        createExistManualMarketRequest(formObj, (res) => {
          //if (res) this.setState({ redirect: `/manual-market-match-dashboard/list/${this.state.formObj.event_id}/${this.state.event_name}` });
          if (res.error != "") {
            toast(res.error, {
              toastId: customId,
            });
          } else {
            toast.dismiss();
            toast({
              toastId: customId,
            });
          }
        })
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }


  };

  handleInput = (e) => {
    const { name, value } = e.target; // <-- moved outside asynchronous context
    if (name == "selectionId") {
      //get back price and lay price start
      {
        this.state.manualmatchdetail.map((item) => {
          if (item.hasOwnProperty("runner_odds")) {
            //check if runner_odds object exist
            {
              item.runner_odds.map((step, i) => {
                //console.log("market details"+step.ex.runner_odds);
                if (step.hasOwnProperty("selectionId")) {
                  //check if selectionId object exist
                  if (step.selectionId == value) {
                    if (step.hasOwnProperty("ex")) {
                      if (step.ex.hasOwnProperty("availableToBack")) {
                        //check if availableToBack object exist
                        {
                          step.ex.availableToBack.map((step1, i) => {
                            if (i == 0) {
                              if (step1.hasOwnProperty("price")) {
                                this.state.formObj.back_price_1 = step1.price && isFloat(step1.price) ? ((step1.price - 1) * 100).toFixed(0) : step1.price; // to append back_price_1 as button
                              }
                            }
                          });
                        }
                      }
                      if (step.ex.hasOwnProperty("availableToLay")) {
                        {
                          step.ex.availableToLay.map((step1, i) => {
                            if (i == 0) {
                              if (step1.hasOwnProperty("price")) {
                                this.state.formObj.lay_price_1 = step1.price && isFloat(step1.price) ? ((step1.price - 1) * 100).toFixed(0) : step1.price;
                              }
                            }
                          });
                        }
                      }
                    }
                  }
                }
              });
            }
          }
        });
      }
      //get back price and lay price stop
    }

    this.setState((state) => ({
      formObj: {
        ...state.formObj,
        [name]: value,
      },
    }));
  };

  diffreenceOption() {
    const option = []
    for (let i = 1; i <= 20; i++) {
      option.push(<option value={i}>{i}</option>)
    }
    return option
  }

  reset(e) {
    e.preventDefault();

    if (this.state.formObj.selectionID != "") {
      let { formObj } = this.state;

      const payload = {
        ...formObj,
        back_price_2: 0,
        back_price_1: 0,
        lay_price_1: 0,
        lay_price_2: 0,
        difference: ""
      }

      if (this.validator.allValid()) {
        this.props.dispatch(
          createExistManualMarketRequest({...formObj, ...payload}, (res) => {
            toast.dismiss();
            toast();
            this.setState({ formObj: { ...formObj, ...payload } })
          })
        );
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  }

  resetMarketHandle = (e) => {
    e.preventDefault();
    // console.log("hello")
    if (this.state.formObj.selectionID != "") {
      let { formObj } = this.state;

      const payload = {
        ...formObj,
        back_price_2: 0,
        back_price_1: 0,
        lay_price_1: 0,
        lay_price_2: 0,
        difference: ""
      }
      this.props.dispatch(
        resetMarketRequest(payload, (res) => {
        })
      );
    }

  }

  render() {
    const {
      ExistManualMarketData,
      market_name,
      market_type,
      event_name,
      formObj: { event_id, market_id, back_price_1, lay_price_1 },
      selectionId1,
      runnerName1,
      selectionId2,
      runnerName2,
    } = this.state;
    this.state.event_name = event_name.replace(/\s+/g, " ");
    const breadcrumbPath = `/manual-market-match-dashboard/list/${event_id}/${event_name}`;
    let { redirect } = this.state;
    const {
      heading,
      breadcrumbTitle,
      breadcrumb,
      navigationUrl,
      action,
      parentType,
    } = this.props;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <>

        <InnerPageHeader
          pageTitle={event_name}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Manual Market Dashboard", url: breadcrumbPath },
          ]}
          currenPageTitle="Add"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: "700" }}>{market_name}</h5>
                </div>
                <div className="ibox-content">
                  <form onSubmit={this.onSubmit} className="form-horizontal">
                    <div className="mx-0 mt-3 row">
                      <div className="col-sm-12 col-md-3 col-lg-4">
                        <label>Team</label>
                        <select
                          name="selectionId"
                          id="selectionId"
                          className="form-control"
                          onChange={(e) => this.handleInput(e)}
                        >
                          <option value="">Select</option>
                          <option value={selectionId1}>{runnerName1}</option>
                          <option value={selectionId2}>{runnerName2}</option>
                        </select>
                        <em>
                          {this.validator.message(
                            "Team",
                            this.state.formObj.selectionId,
                            "required"
                          )}
                        </em>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-2">
                        <label>Lagai</label>
                        <input
                          name="back_price_1"
                          maxLength="3"
                          value={back_price_1}
                          id="back_price_1"
                          type="text"
                          className="form-control"
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message(
                            "Back price",
                            back_price_1,
                            "required"
                          )}
                        </em>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2 col">
                        <label>Difference</label>
                        <select
                          name="difference"
                          maxLength="3"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            +back_price_1 > 0
                              ? this.handleInput({
                                target: {
                                  name: "lay_price_1",
                                  value: +back_price_1 + +e.target.value,
                                },
                              })
                              : this.handleInput(e)
                          }
                        >
                          {this.diffreenceOption()}
                        </select>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2 col">
                        <label>Khai</label>
                        <input
                          maxLength="3"
                          name="lay_price_1"
                          value={lay_price_1}
                          id="lay_price_1"
                          type="text"
                          className="form-control"
                          onChange={(e) => this.handleInput(e)}
                          disabled
                        />
                        <em>
                          {this.validator.message(
                            "Lay price",
                            lay_price_1,
                            "required"
                          )}
                        </em>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2 col">
                        <br />
                        <button className="btn btn-primary" type="submit">
                          Update
                        </button>
                        <button
                          className="btn btn-primary"
                          style={{ marginLeft: "5px" }}
                          onClick={(e) => this.resetMarketHandle(e)}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                    {market_type == 7 ?
                      <div className="mx-0 mt-3 row">
                        <div className="col-sm-12 col-md-3 col-lg-12">
                          <a
                            href="#"
                            className="btn btn-primary mr-2 pl-4 pr-4"
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                            onClick={(e) => this.update_run(e, 800)}
                          >
                            8.0
                          </a>
                          <a
                            href="#"
                            className="btn btn-primary mr-2 pl-4 pr-4"
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                            onClick={(e) => this.update_run(e, 850)}
                          >
                            8.5
                          </a>
                          <a
                            href="#"
                            className="btn btn-primary mr-2 pl-4 pr-4"
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                            onClick={(e) => this.update_run(e, 900)}
                          >
                            9.0
                          </a>
                          <a
                            href="#"
                            className="btn btn-primary mr-2 pl-4 pr-4"
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                            onClick={(e) => this.update_run(e, 950)}
                          >
                            9.5
                          </a>
                          <a
                            href="#"
                            className="btn btn-primary mr-2 pl-4 pr-4"
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                            onClick={(e) => this.update_run(e, 1000)}
                          >
                            10.0
                          </a>

                        </div>

                      </div>
                      :
                      <div className="mx-0 mt-3 row">
                        <div className="col-sm-12 col-md-3 col-lg-12">
                          {bhavNumber.map( num =>
                            <button
                              key={"bhav"+num}
                              className="btn btn-primary mr-2 pl-4 pr-4"
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                              onClick={(e) => this.update_run(e, num)}
                            >
                              {num}
                            </button>
                          )}
                        </div>
                      </div>
                    }
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
