import SectionTitle from "components/common/SectionTitle";
import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory, Link } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";
import { getCollectionReport } from "../../_services"

function CollectionReportMD() {

  const location = useLocation();
  const { matchData } = location.state;

  const history = useHistory();

  const { event_id } = useParams();

  const [collectionReportDataLena, setCollectionReportDataLena] = useState([])
  const [collectionReportDataDena, setCollectionReportDataDena] = useState([])


  useEffect(() => {
    if (event_id) {
      fetchCollectionReport();
    }
  }, []);

  const fetchCollectionReport = () => {
    let sendData = {};
    sendData.event_id = event_id;
    getCollectionReport(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          ((response.data.data)[1]) &&
          ((response.data.data)[2])
        ) {
          if (((response.data.data)[1])) {
            setCollectionReportDataLena(((response.data.data)[1]))
          }
          if (((response.data.data)[2])) {
            setCollectionReportDataDena(((response.data.data)[2]))
          }
        } else {
          setCollectionReportDataLena([]);
          setCollectionReportDataDena([]);
        }
      })
      .catch((error) => {
        setCollectionReportDataLena([]);
        setCollectionReportDataDena([]);
      });
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="Collection Report"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: matchData.sports_id == "6" ? "Games list" : "Matches", url: matchData.sports_id == "6" ? "/game" : "/matches_list" },
          {
            title: matchData.event_name, url: {
              pathname: `/${matchData.sports_id == "6" ? "games_list" : "match_dashboard"}/${event_id}`,
              state: { matchData: matchData },
            }
          },
        ]}
        currenPageTitle={"Collection Report"}
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-6">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>PAYMENT RECEIVING FROM (Lena He)</h5>
                <div className="ibox-tools"></div>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Client Id</th>
                      <th>Client Name</th>
                      <th>Current Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      collectionReportDataLena?.map((el, ind) => {

                        return (
                          <tr key={ind}>
                            <td>{el._id}</td>
                            <td>
                              <a
                                href="/"
                                onClick={(e) => {
                                  localStorage.setItem("username", JSON.stringify(el.name));
                                  e.preventDefault();
                                  history.push(`/userview/collection-report/${el.user_guid}/${el.username}`
                                  );
                                }}
                              >
                                {el.username}{" "}( {el.name} )
                                {/* {el.username}{" "}({el.name.split(' ')[0]}) */}
                              </a>
                            </td>
                            <td>{el.ledger_balance}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Total</th>
                      <th></th>
                      <th>
                        {Math.round(((collectionReportDataLena?.reduce((a, v) => (a) = (+(a)) + (+(v.ledger_balance)), 0))) * 100) / 100}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>PAYMENT PAID TO (Dena He)</h5>
                <div className="ibox-tools"></div>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Client Id</th>
                      <th>Client Name</th>
                      <th>Current Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      collectionReportDataDena?.map((el, ind) => {
                        return (
                          <tr key={ind}>
                            <td>{el._id}</td>
                            <td>
                              <a
                                href="/"
                                onClick={(e) => {
                                  localStorage.setItem("username", JSON.stringify(el.name));
                                  e.preventDefault();
                                  history.push(`/userview/collection-report/${el.user_guid}/${el.username}`
                                  );
                                }}
                              >
                                {el.username}{" "}( {el.name} )
                                {/* {el.username}{" "}({el.name.split(' ')[0]}) */}
                              </a>
                            </td>
                            <td>{el.ledger_balance}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Total</th>
                      <th></th>
                      <th>
                        {Math.round(((collectionReportDataDena?.reduce((a, v) => (a) = (+(a)) + (+(v.ledger_balance)), 0))) * 100) / 100}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollectionReportMD;