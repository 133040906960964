import React from "react";
import { Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { getStorage } from "../../utils";
import { connect } from "react-redux";
import { profileDetails } from "_services/userprofile.services";

const PrivateRoute = ({ component: Component, ...rest }) => {
  let { data } = getStorage("userLogin", "object", {});
  let isLoggedIn = rest?.auth?.userLogin?.user_id ? true : false;

  // if (!isLoggedIn) {
  //   toast.error("Please login to continue.");
  // }

  if (localStorage.getItem("token") && !localStorage.getItem("profile")) {
    profileDetails()
      .then((response) => {
        if (response && response.data && response.data.data) {
          localStorage.setItem("profile", JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {});
  }

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(PrivateRoute);
