import React, { Component } from 'react'
import Main from "../../components/layouts/Main";
import PrivilegesComponent from 'components/Staff/PrivilegesGroup';
export class Privileges extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Main>
                <PrivilegesComponent />
            </Main>

        )
    }
}

export default Privileges