import { sportConstants } from "_constants";

let initialState = {
  sportList: {},
  isLoader: false,
};

export function sports(state = initialState, action) {
  switch (action.type) {
    case sportConstants.SPORTS_LIST_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case sportConstants.SPORTS_LIST_SUCCESS:
      return {
        ...state,
        isLoader: false,
      };
    case sportConstants.SPORTS_LIST_FAILED:
      return {
        ...state,
        isLoader: false,
      };
    case sportConstants.SPORTS_LIST_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case sportConstants.SPORTS_LIST_SUCCESS:
      return {
        ...state,
        sportsList: action.data,
        isLoader: false,
      };
    case sportConstants.SPORTS_LIST_FAILED:
      return {
        ...state,
        isLoader: false,
      };
    default:
      return state;
  }
}
