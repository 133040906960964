import React, { useEffect, useState, useMemo } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  getMatchLiveReport,
  getMarketPosition,
  getSessionPosition,
  getDeclaredFancyLive,
  getDeclaredMarketLive,
  getLiveMatches,
  changeLocalMarketing,
  changeFancyBetLock,
  rollbackSession,
  rollbackMarket,
  showHideEvent,
  fancySectionLock,
  updateAnnouncement,
} from "_services";
import InnerPageHeader from "components/common/InnerPageHeader";
import { useSelector } from "react-redux";
import { confirm } from "react-confirm-box";
import { Button, Modal } from "react-bootstrap";

import "./newCss.css";
import "./liveReports.css";

import ClipLoader from "react-spinners/ClipLoader";
import PositionModal from "./PositionModal";
import SocketHelper from "components/layouts/SocketHelper";
import LiveMatchesList from "components/LiveMatchesList/LiveMatchesList";
import { toast } from "react-toastify";
import VideoModal from "components/common/VideoModel";
import {
  awaitApiCallFuncation,
  detectMobView,
  differentFancyList,
  getFilterTypeMarketList,
} from "components/layouts/Helpers";
import DeclaredOddsFancyView from "./DeclaredOddsFancyView";
import MarketView from "./MarketView";
import MatkaMarketView from "./MatkaMarketView";
import SessionView from "./SessionView";
import LiveReportHeader from "./LiveReportHeader";

const marketInterval = Math.floor(Math.random() * (31 - 20)) + 20;
const fancyInterval = Math.floor(Math.random() * (31 - 20)) + 20;

let liveDataFancy = "";
let liveDataMarket = "";

function LiveReport(props) {
  const { event_id } = useParams();
  const location = useLocation();
  const [liveData, setLiveData] = useState("");
  const [positionData, setPositionData] = useState([]);
  const [fancyData, setFancyData] = useState([]);
  const [declaredFancyData, setDeclaredFancyData] = useState([]);
  const [liveMatches, setLiveMatches] = useState([]);
  const [eventName, setEventName] = useState(
    location?.state?.matchData?.event_name
  );
  const sdata = useSelector((state) => state.socketDataReducer);
  const socketData = sdata.socketData;
  const [showPositionModalFlag, updateShowModalFlag] = useState(false);
  const [selectedSesstion, setSelectedSession] = useState(null);
  const [declaredMarketData, updateDeclaredMarketData] = useState([]);
  const [showConfirmPopup, updateConfirmPopup] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState({
    isOpen: false,
    dataForRoll: socketData.rollback,
  });

  const [showLoader, updateShowLoader] = useState(false);
  const [rollbackData, updateRollbackData] = useState(null);
  const [rollbackType, updateRollbackType] = useState(null);
  const userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const [userRole, setUserRole] = useState(userLogin);
  const [closeOpenRoom, updateCloseOpenRoom] = useState("");
  const [matchToggleButton, setMatchToggleButton] = useState(false);
  const [rollbackCode, setRollbackCode] = useState("");
  const [toggleTv, setToggleTv] = useState(false);
  const [showDeclared, setDeclaredColumn] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [APIcall, setAPICall] = useState({ market: true, fancy: true });
  const [marketType, setActiveMarket] = useState(false);

  const [announcement, setAnnouncement] = useState({
    showAnnouncementRowId: null,
    announcement: "",
    MarketAnnouncementPosting: false,
  });

  const history = useHistory();
  // const [marketIdList,updateMarketIdList] = useState([]);

  useEffect(() => {
    fetchLiveReport(event_id);
    updateCloseOpenRoom(!closeOpenRoom);
    return () => {
      liveDataFancy = [];
      liveDataMarket = [];
      setLiveData({});
      console.clear();
    };
  }, [event_id]);

  useEffect(() => {
    if (liveData?.event?.sport_id && event_id) {
      fetchLiveMatches(event_id, liveData.event.sport_id);
    }
  }, [liveData?.event?.sport_id]);

  useEffect(() => {
    if (
      socketData &&
      socketData.updateMarketOdds &&
      socketData.updateMarketOdds.odds
    ) {
      try {
        const updatedData = socketData.updateMarketOdds;
        let finalData = [];
        let data = liveData;
        finalData = data.market;
        data.market.map((market, markey) => {
          if (updatedData.market_id == market.market_id) {
            market.runners.map((run, key) => {
              updatedData.odds.find((odd) => {
                if (run.selectionId === odd.selectionId) {
                  run["runner_odd"] = odd;
                  finalData[markey].runners[key] = run;
                }
              });
            });
          }
        });
        data.market = finalData;
        setLiveData(data);
      } catch (error) {}
    } else if (socketData && socketData.marketAnnouncement) {
      try {
        const updatedData = socketData.marketAnnouncement;
        let finalData = [];
        let data = liveData;
        finalData = data.market;
        data.market.map((market, markey) => {
          if (updatedData.market_id == market.market_id) {
            market.announcement = updatedData.announcement;
          }
        });
        data.market = finalData;
        setLiveData(data);
      } catch (error) {}
    } else if (socketData && socketData.updateFancyOdds) {
      try {
        if (event_id == socketData.updateFancyOdds.event_id) {
          let finalData = [];
          let data = liveData;
          finalData = data.fancy;
          data.fancy.map((fancyItem, fancyKey) => {
            if (fancyItem.session_id == socketData.updateFancyOdds.market_id) {
              fancyItem.lay_price1 = socketData.updateFancyOdds.lay_price1;
              fancyItem.lay_size1 = socketData.updateFancyOdds.lay_size1;
              fancyItem.back_price1 = socketData.updateFancyOdds.back_price1;
              fancyItem.back_size1 = socketData.updateFancyOdds.back_size1;

              finalData[fancyKey] = fancyItem;
            }
          });
          data.fancy = finalData;
          setLiveData(data);
        }
      } catch (error) {}
    } else if (socketData && socketData.addUpdateFancy) {
      if (event_id == socketData.addUpdateFancy.event_id) {
        fetchLiveReport(event_id);
      }
    } else if (socketData && socketData.updateScore) {
      let matchData = liveData;
      if (
        matchData &&
        matchData.event &&
        matchData.event.event_id == socketData.updateScore.event_id
      ) {
        const lData = socketData.updateScore.score
          ? JSON.parse(socketData.updateScore.score)
          : "";
        const parseData = parseLiveScore(lData, matchData);
        matchData.live_msg = parseData.liveScoreMsg;
        matchData.live_score1 = parseData.live_score1;
        matchData.live_score2 = parseData.live_score2;
        matchData.sixBall = parseData.sixBall;
        matchData.players = parseData.players;
        matchData.updateScore = lData?.data;
        setLiveData(matchData);
      }
    } else if (socketData && socketData.bet_placed) {
      let matchData = liveData;
      if (
        matchData &&
        matchData.event &&
        matchData.event.event_id == socketData.bet_placed.event_id
      ) {
        if (socketData.bet_placed.bookmaker_type == 2) {
          // getSingleMarketPositionData(socketData.bet_placed.market_id);
        }
        if (socketData.bet_placed.bookmaker_type == 3) {
          // getSingleFancyPositionData(socketData.bet_placed.market_id);
        }
      }
    } else if (
      liveData &&
      liveData.event &&
      socketData &&
      socketData.updateCommentary &&
      socketData.updateCommentary.event_id == liveData.event.event_id
    ) {
      liveData.event.button_label = socketData.updateCommentary.button_label;
      liveData.event.commentary_button_id =
        socketData.updateCommentary.commentary_button_id;
      liveData.event.btn_color = socketData.updateCommentary.btn_color;
      setLiveData(liveData);
    } else if (socketData && socketData.reloadPage) {
      updateCloseOpenRoom(!closeOpenRoom);
    } else if (socketData && socketData.settlementDone) {
      updateSettleMent(socketData);
    } else if (socketData && socketData?.loggedCount) {
      let matchData = liveData;
      if (matchData?.event?.event_id == socketData.loggedCount.event_id) {
        let obj = {
          ...matchData,
          event: {
            ...matchData?.event,
            view_count: +matchData?.event?.view_count + 1,
          },
        };
        setLiveData(obj);
      }
    } else if (socketData && socketData?.betsCount) {
      let matchData = liveData;
      if (matchData?.event?.event_id == socketData.betsCount.event_id) {
        let obj = {
          ...matchData,
          event: {
            ...matchData?.event,
            bet_count: socketData?.betsCount?.bet_count?.bet_count,
          },
        };
        setLiveData(obj);
      }
    } else if (socketData && socketData.rollback) {
      if (userRole?.role == 1 || userRole?.role == 8) {
        setConfirmMessage({
          isOpen: true,
          dataForRoll: socketData.rollback,
        });
      }
    }
  }, [socketData]);

  useEffect(() => {
    if (event_id) {
      if (showDeclared === "session") {
        setLoading(true);
        fetchDeclaredFancy(event_id);
      }
      if (showDeclared === "market") {
        setLoading(true);
        fetchDeclaredMarket(event_id);
      }
    }
  }, [event_id, showDeclared]);

  const updateSettleMent = (data) => {
    let matchData = liveData;

    if (
      matchData &&
      matchData.event &&
      matchData.event.event_id == data.settlementDone.event_id
    ) {
      if (data.settlementDone.bookmaker_type == 3) {
        let allFancy = matchData.fancy;

        let iIndex = -1;
        for (let i = 0; i < allFancy.length; i++) {
          if (allFancy[i].session_id == data.settlementDone.session_id) {
            iIndex = i;
            break;
          }
        }
        if (iIndex > -1) {
          allFancy.splice(iIndex, 1);
          matchData.fancy = allFancy;
          setLiveData(matchData);

          //  this.setState({ matchDetail: matchData });
        }
      }
      if (data.settlementDone.bookmaker_type == 2) {
        let allMarket = matchData.market;
        let iIndex = -1;
        for (let i = 0; i < allMarket.length; i++) {
          if (allMarket[i].market_id == data.settlementDone.market_id) {
            iIndex = i;
            break;
          }
        }
        if (iIndex > -1) {
          allMarket.splice(iIndex, 1);
          matchData.market = allMarket;
          setLiveData(matchData);

          // this.setState({ matchDetail: matchData });
        }
      }
    }
  };

  useEffect(() => {
    if (liveData) {
      if (liveData.market && liveData.market.length) {
        APIcall?.market && getMarketPositionData(liveData.market);
        setAPICall((s) => ({ ...s, market: false }));
      }

      if (liveData.fancy && liveData.fancy.length) {
        APIcall?.fancy && getFancyPositionData(liveData.fancy);
        setAPICall((s) => ({ ...s, fancy: false }));
      }
    }
  }, [liveData]);

  const getSingleFancyPositionData = async (sessionId) => {
    if (sessionId) {
      let fancyDataSet = null;
      let fancyIndex = -1;
      for (let i = 0; i < liveData.fancy.length; i++) {
        if (liveData.fancy[i].session_id == sessionId) {
          fancyDataSet = liveData.fancy[i];
          fancyIndex = i;
        }
      }
      if (fancyDataSet) {
        const result = await getFancySCData(fancyDataSet);
        let runnersData = JSON.parse(JSON.stringify(result));

        for (let i = 0; i < fancyData.length; i++) {
          if (
            fancyData[i].session_id == sessionId &&
            fancyData[i].event_id == runnersData.event_id
          ) {
            fancyData[i] = runnersData;
          }
        }
        //    setRenderView(false);
        setFancyData(fancyData);
        // setRenderView(true);
      }
    }
  };

  const getFancySCData = (fancyItem) => {
    let sendData = {};
    sendData.event_id = fancyItem.event_id;
    sendData.session_id = fancyItem.session_id;
    return getSessionPosition(sendData).then((response) => {
      if (response && response.data && response.data.data) {
        return response.data.data;
      } else {
        return null;
      }
    });
  };

  const getSingleMarketPositionData = async (marketId) => {
    if (marketId) {
      let marketData = null;
      let marketIndex = -1;
      for (let i = 0; i < liveData.market.length; i++) {
        if (liveData.market[i].market_id == marketId) {
          marketData = liveData.market[i];
          marketIndex = i;
        }
      }
      if (marketData) {
        const result = await fetchMarketData(marketData);

        let runnersData = JSON.parse(JSON.stringify(result.runners));

        runnersData.forEach((val) => (val.market_id = marketId));
        for (let i = 0; i < positionData.length; i++) {
          for (let j = 0; j < runnersData.length; j++) {
            if (
              positionData[i].market_id == marketId &&
              positionData[i].selectionId == runnersData[j].selectionId
            ) {
              positionData[i].winloss = runnersData[j].winloss;
            }
          }
        }
        // setRenderView(false);
        setPositionData(positionData);
        // setRenderView(true);
      }
    }
  };

  function fetchMarketData(market) {
    let sendData = {};
    sendData.event_id = market.event_id;
    sendData.market_id = market.market_id;
    sendData.sport_id = market.sport_id;
    return getMarketPosition(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {
          return response.data.data;
        } else {
          return null;
        }
      })
      .catch((error) => {});
  }

  const getMarketPositionData = async (liveDataMarket1) => {
    liveDataMarket = liveDataMarket1;
    try {
      let arrObj = [];
      awaitApiCallFuncation(liveDataMarket, (market, ind) => {
        let sendData = {};
        sendData.event_id = market.event_id;
        sendData.market_id = market.market_id;
        sendData.sport_id = market.sport_id;
        getMarketPosition(sendData)
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.data &&
              response.data.data.runners &&
              response.data.data.runners.length
            ) {
              let runnersData = JSON.parse(
                JSON.stringify(response.data.data.runners)
              );
              runnersData.forEach((val) => (val.market_id = market.market_id));
              arrObj = [...arrObj, ...runnersData];
              setPositionData(arrObj);
            } else {
              arrObj = [];
            }
          })
          .catch((error) => {});
        let timerId = null;
        if (liveDataMarket.length === ind + 1) {
          timerId = setTimeout(() => {
            getMarketPositionData(liveDataMarket);
          }, [1000 * marketInterval]);
        }

        if (!liveDataMarket) {
          clearTimeout(timerId);
        }
      });
    } catch (error) {}
  };

  const fetchMarketPosition = (market_id, selectionId) => {
    try {
      let position = positionData.find(
        (pos) => pos.selectionId === selectionId && pos.market_id === market_id
      );

      if (position && position.winloss) {
        return position.winloss;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const getFancyPositionData = async (liveDataFancy1) => {
    liveDataFancy = liveDataFancy1;
    try {
      let arrObj = [];
      awaitApiCallFuncation(
        liveData.fancy,
        (market, ind) => {
          let sendData = {};
          sendData.event_id = market.event_id;
          sendData.session_id = market.session_id;
          getSessionPosition(sendData)
            .then((response) => {
              if (response && response.data && response.data.data) {
                let runnersData = JSON.parse(
                  JSON.stringify(response.data.data)
                );
                arrObj = [...arrObj, runnersData];
                setFancyData(arrObj);
              } else {
                arrObj = [];
              }
            })
            .catch((error) => {});
          let timerId = null;
          if (liveDataFancy.length === ind + 1) {
            timerId = setTimeout(() => {
              getFancyPositionData(liveDataFancy);
            }, [1000 * fancyInterval]);
          }

          if (!liveDataFancy) {
            clearTimeout(timerId);
          }
        },
        !liveDataFancy
      );
    } catch (error) {}
  };

  const fetchFancyPosition = (session_id, type) => {
    try {
      let position = fancyData.find((pos) => pos.session_id === session_id);
      if (position && position.fexposure) {
        return position.fexposure[type];
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const fetchLiveReport = (event_id) => {
    let sendData = {};
    sendData.event_id = event_id;
    getMatchLiveReport(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {
          let matchData = response.data.data;

          const liveData = matchData.event.score
            ? JSON.parse(matchData.event.score)
            : "";
          const parseData = parseLiveScore(liveData, matchData);
          matchData.live_msg = parseData.liveScoreMsg;
          matchData.live_score1 = parseData.live_score1;
          matchData.live_score2 = parseData.live_score2;
          matchData.sixBall = parseData.sixBall;
          matchData.players = parseData.players;
          matchData.market.map((market, markey) => {
            market.runners.map((run, key) => {
              market.runner_odds.find((odd) => {
                if (run.selectionId === odd.selectionId) {
                  run["runner_odd"] = odd;
                  matchData.market[markey].runners[key] = run;
                }
              });
            });
          });
          setLiveData(matchData);
          setActiveMarket(matchData?.market[0]?.market_name);
          setEventName(response.data.data.event.event_name);
          setMatchToggleButton(!!response?.data?.data?.event?.hide_event);
        } else {
          setLiveData("");
        }
      })
      .catch((error) => {
        setLiveData("");
      });
  };

  const parseLiveScore = (liveData) => {
    let finalData = { live_score1: null, live_score2: null, liveScoreMsg: "" };
    if (liveData) {
      const liveScoreMsg = liveData?.data?.result
        ? liveData?.data?.result
        : liveData?.data?.trail_lead
        ? liveData?.data?.trail_lead
        : liveData?.data?.need_run_ball
        ? // ? liveData?.data?.need_run_ball
          liveData.data?.match_type == "Test"
          ? liveData.data?.trail_lead
          : // ? liveData.data?.team_b + " Need " + liveData.data?.run_need + " Runs In " + liveData.data?.ball_rem + " Balls To Win"
            (liveData.data?.batting_team == liveData?.data?.team_a_id
              ? liveData.data?.team_a
              : liveData.data?.team_b) +
            " Need " +
            liveData.data?.run_need +
            " Runs In " +
            liveData.data?.ball_rem +
            " Balls RRR " +
            liveData.data?.rr_rate
        : // : liveData?.data?.toss;
          null;
      const liveScore1 = `${liveData.data?.team_a} - ${
        liveData.data?.team_a_scores ? liveData.data?.team_a_scores : ""
      } ( ${liveData.data?.team_a_over ? liveData.data?.team_a_over : 0} Ovs )`;
      const liveScore2 = `${liveData.data?.team_b} - ${
        liveData.data?.team_b_scores ? liveData.data?.team_b_scores : ""
      }  ( ${
        liveData.data?.team_b_over ? liveData.data?.team_b_over : 0
      }  Ovs )`;
      finalData = {
        live_score1:
          // liveData?.data?.current_inning % 2 === 0 ? liveScore2 : liveScore1,
          liveData?.data?.batting_team == liveData?.data?.team_a_id
            ? liveScore1
            : liveScore2,
        live_score2:
          liveData?.data?.batting_team == liveData?.data?.team_a_id
            ? liveScore2
            : liveScore1,
        // liveData?.data?.current_inning % 2 === 0 ? liveScore1 : liveScore2,
        sixBall: liveData?.data?.last36ball,
        // liveData?.data?.last4overs?.length
        //   ? liveData?.data?.last4overs
        //     ?.slice(-2)
        //     .reduce((itemArray, item) => {
        //       itemArray.push(...item?.balls);
        //       return itemArray;
        //     }, [])
        //     .slice(-6)
        //   : ["."],
        players: {
          Batsman: liveData?.data?.batsman,
          bolwer: liveData?.data?.bolwer,
        },
        liveScoreMsg: liveScoreMsg,
        liveCrrScore: liveScoreMsg,
      };
    }

    return finalData;
  };

  const fetchDeclaredFancy = (event_id) => {
    let sendData = {};
    sendData.event_id = event_id;
    getDeclaredFancyLive(sendData)
      .then((response) => {
        if (response?.data?.data && response?.data?.data?.length) {
          setDeclaredFancyData(response.data.data);
        } else {
          setDeclaredFancyData([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        setDeclaredFancyData([]);
        setLoading(false);
      });
  };

  const fetchDeclaredMarket = (event_id) => {
    let sendData = {};
    sendData.event_id = event_id;
    getDeclaredMarketLive(sendData)
      .then((response) => {
        if (response?.data?.data && response?.data?.data?.length) {
          updateDeclaredMarketData(response.data.data);
        } else {
          updateDeclaredMarketData([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        updateDeclaredMarketData([]);
        setLoading(false);
      });
  };

  const fetchLiveMatches = (event_id, sport_id) => {
    let sendData = {};
    sendData.event_id = event_id;
    sendData.current_page = 1;
    sendData.items_perpage = 100;
    sendData.sport_id = sport_id;
    getLiveMatches(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.records &&
          response.data.data.records.length
        ) {
          let filterdData = response.data.data.records.filter(
            (el) => el.event_id != event_id
          );
          setLiveMatches(filterdData);

          // response.data.data.filter((el) => {
          //   if (el.event_id == event_id) {
          //     setEventName(el.event_name)
          //   }
          // })
        } else {
          setLiveMatches([]);
        }
      })
      .catch((error) => {
        setLiveMatches([]);
      });
  };

  const getDeclaredSatatusSum = (data) => {
    try {
      let val = data.reduce((total, value) => {
        return parseFloat(total) + parseFloat(value.pl);
      }, 0);
      return Math.round(val * 100) / 100;
    } catch (error) {
      return 0;
    }
  };

  const getWinner = (data) => {
    const settlement_result = data.settlement_result;
    const runnerData = JSON.parse(data.runners);
    const winnerData = runnerData.filter((item) => {
      return item.selectionId == settlement_result;
    });
    const winnerName =
      winnerData && winnerData.length > 0 ? winnerData[0].runnerName : "";
    return winnerName;
  };

  const marketStatusChange = async (
    e,
    market_id,
    selectionId = "",
    lock_betting = 1
  ) => {
    e.preventDefault();
    const result = await confirm(
      "Are you sure you want to lock this team market price?"
    );
    if (result) {
      let sendData = {};
      sendData.market_id = market_id;
      sendData.event_id = event_id;
      if (lock_betting !== "") {
        sendData.lock_status = +lock_betting == 0 ? 1 : 0;
      }
      if (selectionId) {
        sendData.selection_id = selectionId;
      }
      changeLocalMarketing(sendData)
        .then((response) => {
          fetchLiveReport(event_id);
        })
        .catch((error) => {});
      return;
    }
  };

  const fancyStatusChange = async (e, selectionId, lock_betting) => {
    e.preventDefault();
    const result = await confirm(
      "Are you sure you want to lock this team fancy price?"
    );
    if (result) {
      let sendData = {
        event_id: +event_id,
        bet_lock: lock_betting == 0 ? 1 : 0,
      };
      if (typeof selectionId === "object") {
        sendData.session_type = selectionId;
        var res = await fancySectionLock(sendData);
      } else {
        sendData.session_id = selectionId;
        var res = await changeFancyBetLock(sendData);
      }
      if (res) {
        fetchLiveReport(event_id);
      }
    }

    // let sendData = {};
    // sendData.event_id = event_id;
    // sendData.bet_lock = lock_betting == 0 ? 1 : 0;
    // sendData.session_id = selectionId;
    // changeLocalMarketing(sendData)
    //   .then((response) => {
    //     fetchLiveReport(event_id);
    //   })
    //   .catch((error) => { });
    // return;
  };

  function onClickPositionModal(session) {
    setSelectedSession(session);
    updateShowModalFlag(true);
  }

  function hidePositionModal() {
    setSelectedSession(null);
    updateShowModalFlag(false);
  }

  function getValueForLagai(market, run, index, matchDetail) {
    let bhav = "";
    if (matchDetail?.event?.sport_id == 1) {
      bhav = getDisplayValue(
        run?.runner_odd?.ex?.availableToBack[0]?.price,
        market.market_type,
        true
      );
      return bhav;
    } else if (
      matchDetail?.event?.sport_id == 4 &&
      market.runner_odds.length == 3
    ) {
      bhav = getDisplayValue(
        run?.runner_odd?.ex?.availableToBack[0]?.price,
        market.market_type,
        true
      );
      return bhav;
    } else {
      if (market.showLagaiOnly && index == 0) {
        bhav = market.lagaiBhav1;
      } else if (market.showLagaiOnly && index == 1) {
        bhav = market.lagaiBhav2;
      } else if (market.market_type == 5 && index == 0) {
        bhav = market.lagaiBhav1;
      } else if (market.market_type == 5 && index == 1) {
        bhav = market.lagaiBhav2;
      } else if (market.market_type == 6 && index == 0) {
        bhav = market.lagaiBhav1;
      } else if (market.market_type == 6 && index == 1) {
        bhav = market.lagaiBhav2;
      } else if (market.market_type == 7) {
        let val = 0;
        if (market.runner_odds && market.runner_odds.length > index) {
          val = getDisplayValue(
            market.runner_odds[index]?.ex?.availableToBack[0]?.price,
            market.market_type,
            true
          );
        }
        bhav = val;
      } else if (run.isFav) {
        bhav = getDisplayValue(
          run?.runner_odd?.ex?.availableToBack[0]?.price,
          market.market_type,
          true
        );
      }

      if (
        bhav != "" &&
        matchDetail &&
        matchDetail.event &&
        matchDetail.event.btn_color &&
        ((matchDetail.event.btn_color == "Red" &&
          matchDetail.event.commentary_button_id != 5) ||
          matchDetail.event.btn_color == "Yellow")
      ) {
        bhav = 0;
        if (matchDetail.event.btn_color == "Yellow") {
          // reloadView();
        }
      }
      return bhav;
    }
  }

  function getValueForKhai(market, run, matchDetail) {
    let bhav = "";
    if (matchDetail?.event?.sport_id == 1) {
      if (!market.showLagaiOnly) {
        bhav = getDisplayValue(
          run?.runner_odd?.ex?.availableToLay[0]?.price,
          market.market_type,
          false
        );
      }
      return bhav;
    } else if (
      matchDetail?.event?.sport_id == 4 &&
      market.runner_odds.length == 3
    ) {
      if (!market.showLagaiOnly) {
        bhav = getDisplayValue(
          run?.runner_odd?.ex?.availableToLay[0]?.price,
          market.market_type,
          false
        );
      }
      return bhav;
    } else {
      if (run.isFav && !market.showLagaiOnly) {
        bhav = getDisplayValue(
          run?.runner_odd?.ex?.availableToLay[0]?.price,
          market.market_type,
          false
        );
      }
      // if (!run.isFav && !market.showLagaiOnly) {
      //   bhav = getDisplayValue(
      //     run?.runner_odd?.ex?.availableToLay[0]?.price,
      //     market.market_type,
      //     false
      //   );
      // }
      if (
        bhav != "" &&
        matchDetail &&
        matchDetail.event &&
        matchDetail.event.btn_color &&
        ((matchDetail.event.btn_color == "Red" &&
          matchDetail.event.commentary_button_id != 5) ||
          matchDetail.event.btn_color == "Yellow")
      ) {
        bhav = 0;
      }

      return bhav;
    }
  }

  function getDisplayValue(value, market_type, isLagai) {
    try {
      if (value) {
        if (market_type) {
          if (
            market_type === 1 ||
            market_type === 4 ||
            market_type === 5 ||
            market_type === 6 ||
            market_type === 7
          ) {
            if (market_type == 1 && isLagai && value > 1.2) {
              value = value - 0.01;
            }

            let val = value * 100;
            let calValue = ((parseFloat(val) - 1 * 100) / 100).toFixed(2);

            return calValue;
          } else if (market_type === 2) {
            let val = value * 100;
            return parseFloat(val.toFixed(2)) - 1 * 100;
          } else {
            return value;
          }
        } else {
          return value;
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

  function isFavTeam(market, runners, index) {
    const minBhavLimit = 0.96;
    const maxBhavLimit = 1.0;
    let showLagaiOnly = false;
    let lagaiBhav1 = 0;
    let lagaiBhav2 = 0;
    let lagai1 = getDisplayValue(
      runners[0]?.runner_odd?.ex?.availableToBack[0]?.price,
      market.market_type,
      true
    );
    let lagai2 = getDisplayValue(
      runners[1]?.runner_odd?.ex?.availableToBack[0]?.price,
      market.market_type,
      true
    );

    let isFav = false;
    if (runners.length == 3) {
      //TEST MATCH

      let lagai3 = getDisplayValue(
        runners[2]?.runner_odd?.ex?.availableToBack[0]?.price,
        market.market_type,
        true
      );
      let khai3 = getDisplayValue(
        runners[2]?.runner_odd?.ex?.availableToLay[0]?.price,
        market.market_type,
        false
      );
      if (index == 0) {
        if (lagai1 < lagai2) {
          isFav = true;
        }

        if (lagai1 < maxBhavLimit && lagai1 > minBhavLimit) {
          // showLagaiOnly = true;
          if (lagai1 < lagai2) {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai2;
          }
        }
      } else if (index == 1) {
        if (lagai2 < lagai1) {
          isFav = true;
        }
        if (lagai2 < maxBhavLimit && lagai2 > minBhavLimit) {
          //  showLagaiOnly = true;
          if (lagai2 < lagai1) {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai2;
          }
        }
      } else if (index == 2) {
        if (khai3 > 9.99 && lagai3 > 9.99) {
          isFav = false;
        } else {
          isFav = true;
        }
      }
    } else if (runners.length == 2) {
      //Other MATCH
      if (index == 0) {
        if (lagai1 < lagai2) {
          isFav = true;
        }

        if (lagai1 < maxBhavLimit && lagai1 > minBhavLimit) {
          if (lagai1 < lagai2) {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai2;
          }
        }
      } else if (index == 1) {
        if (lagai2 < lagai1) {
          isFav = true;
        }
        if (lagai2 < maxBhavLimit && lagai2 > minBhavLimit) {
          if (lagai2 < lagai1) {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai2;
          }
        }
      }
    }
    if (market.market_type == 2) {
      // if (market.runner_odds[index]?.status == 'ACTIVE') {
      if (
        runners[index]?.selectionId == market.runner_odds[0]?.selectionId &&
        market.runner_odds[0]?.status == "ACTIVE"
      ) {
        showLagaiOnly = false;
        lagaiBhav1 = lagai1;
        lagaiBhav2 = lagai2;
        isFav = true;
      } else {
        isFav = false;
      }
    }

    if (market.market_type == 2 && lagai1 >= 94) {
      showLagaiOnly = true;
      lagaiBhav1 = lagai1;
      lagaiBhav2 = lagai2;
    } else {
      showLagaiOnly = false;
      lagaiBhav1 = lagai1;
      lagaiBhav2 = lagai2;
    }

    if (market.market_type == 5 || market.market_type == 6) {
      showLagaiOnly = true;
      lagaiBhav1 = lagai1;
      lagaiBhav2 = lagai2;
    }
    // if (market.market_type == 6) {
    //   showLagaiOnly = false;
    //   lagaiBhav1 = lagai1;
    //   lagaiBhav2 = lagai2;
    // }

    if (+liveData?.event?.cup_event === 1) {
      isFav = true;
    }

    let resultData = {
      isFav: isFav,
      showLagaiOnly: showLagaiOnly,
      lagaiBhav1: lagaiBhav1,
      lagaiBhav2: lagaiBhav2,
    };
    return resultData;
  }

  function showConfirmPoppup(type, data) {
    updateRollbackData(data);
    updateRollbackType(type);
    updateConfirmPopup(true);
  }

  function hideConfirmModal() {
    updateRollbackData(null);
    updateRollbackType(null);
    updateConfirmPopup(false);
    updateShowLoader(false);
    setRollbackCode("");
  }

  function onConfirmClick() {
    updateShowLoader(true);
    if (rollbackType == "market") {
      onRollbackMarket(rollbackData);
    }
    if (rollbackType == "session") {
      onRollbackSession(rollbackData);
    }
  }

  function onRollbackSession(data) {
    let sendData = {};
    sendData.event_id = event_id;
    sendData.session_id = data.session_id;
    sendData.code = rollbackCode;
    rollbackSession(sendData)
      .then((response) => {
        hideConfirmModal();
        fetchDeclaredFancy(event_id);
      })
      .catch((error) => {
        if (error?.response?.data) {
          toast.error(error?.response?.data?.error);
        }
        updateShowLoader(false);
      });
  }

  const getAllMarketIds = (market = [], sessions = []) => {
    const ids = [];
    for (let i = 0; i < market.length; i++) {
      ids.push(market[i].market_id);
    }
    for (let i = 0; i < sessions.length; i++) {
      ids.push("" + sessions[i].session_id);
    }
    return ids;
  };

  function onRollbackMarket(data) {
    let sendData = {};
    sendData.event_id = event_id;
    sendData.market_id = data.market_id;
    sendData.code = rollbackCode;
    rollbackMarket(sendData)
      .then((response) => {
        hideConfirmModal();
        fetchDeclaredMarket(event_id);
      })
      .catch((error) => {
        if (error?.response?.data) {
          toast.error(error?.response?.data?.error);
        }
        updateShowLoader(false);
      });
  }

  function getMarketList(list) {
    for (let i = 0; i < list.length; i++) {
      let runners = list[i].runners;
      let showLagaiOnly = false;
      let lagaiBhav1 = 0;
      let lagaiBhav2 = 0;
      for (let j = 0; j < runners.length; j++) {
        let resData = isFavTeam(list[i], runners, j);
        runners[j].isFav = resData.isFav;
        if (!showLagaiOnly) {
          showLagaiOnly = resData.showLagaiOnly;
          lagaiBhav1 = resData.lagaiBhav1;
          lagaiBhav2 = resData.lagaiBhav2;
        }
      }
      // if (showLagaiOnly) {
      list[i].showLagaiOnly = showLagaiOnly;
      list[i].lagaiBhav1 = lagaiBhav1;
      list[i].lagaiBhav2 = lagaiBhav2;
      // }
      list[i].runners = runners;
      list[i].betLockStatus = runners.every(
        (ele) => ele.lock_team_betting == 0
      );
    }
    return list;
  }

  const redirectToFixture = (match) => {
    history.replace(`/live_report/${match.event_id}`);
    window.location.reload();
  };

  const handleShowHideEvent = async (e) => {
    const postData = {
      hide_event: +e.target.checked,
      event_id: event_id,
    };
    setMatchToggleButton(e.target.checked);
    let res = await showHideEvent(postData);
    try {
      toast(res?.message);
    } catch (error) {
      toast.error("Request failed");
    }
  };

  const setState = (obj, callback) => {
    setAnnouncement((s) => ({ ...s, ...obj }));
    if (callback) callback();
  };

  const showAnnouncementRow = (item, type) => {
    setState({ announcement: "" }, () => {
      if (type) {
        if (
          announcement.showAnnouncementRowId != null &&
          Number(item.child_session_type_id) ==
            Number(announcement.showAnnouncementRowId.child_session_type_id) &&
          Number(item.session_type_id) ==
            Number(announcement.showAnnouncementRowId.session_type_id)
        ) {
          setState({ showAnnouncementRowId: null, announcement: null });
        } else {
          setState({
            showAnnouncementRowId: {
              child_session_type_id: item.child_session_type_id,
              session_type_id: item.session_type_id,
            },
            announcement: item.announcement,
          });
        }
      } else {
        if (item.market_id == announcement.showAnnouncementRowId) {
          setState({ showAnnouncementRowId: null, announcement: null });
        } else {
          setState({
            showAnnouncementRowId: item.market_id,
            announcement: item.announcement,
          });
        }
      }
    });
  };

  const handleChange = (e, type) => {
    const announcement = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    setState({ announcement: value });
  };
  const updateMarketAnnouncement = (item, type) => {
    setState({ MarketAnnouncementPosting: true });
    let param = {};
    if (type) {
      param = {
        session_id: item.session_id,
        event_id: item.event_id,
        child_session_type_id: item.child_session_type_id,
        session_type_id: item.session_type_id,
        announcement: announcement.announcement,
      };
    } else {
      param = {
        market_id: item.market_id,
        event_id: item.event_id,
        announcement: announcement.announcement,
      };
    }

    updateAnnouncement(param)
      .then((response) => {
        setState({ MarketAnnouncementPosting: false, announcement: "" });
        fetchLiveReport(event_id);
      })
      .catch((error) => {
        setState({ MarketAnnouncementPosting: true });
      });
  };

  const clearMarketAnnouncement = (item, type) => {
    setState({ MarketAnnouncementPosting: true, announcement: null });
    let param = {};
    if (type) {
      param = {
        session_id: item.session_id,
        event_id: item.event_id,
        child_session_type_id: item.child_session_type_id,
        session_type_id: item.session_type_id,
        announcement: null,
      };
    } else {
      param = {
        market_id: item.market_id,
        event_id: item.event_id,
        announcement: null,
      };
    }

    updateAnnouncement(param)
      .then((response) => {
        setState({ MarketAnnouncementPosting: false, announcement: "" });
        fetchLiveReport(event_id);
      })
      .catch((error) => {
        setState({ MarketAnnouncementPosting: true });
      });
  };

  const showSubCompanyButton = (
    <button
      type="button"
      className="btn btn-primary"
      onClick={() => history.replace(`/subcompany_live_report/${event_id}`)}
    >
      Show SubCompany
    </button>
  );

  let marketList =
    liveData && liveData.market && liveData.market.length > 0
      ? getMarketList(liveData.market)
      : [];
  const memorizeMarketList = useMemo(
    () => getAllMarketIds(liveData.market, liveData.fancy),
    [liveData, closeOpenRoom]
  );
  const memorizeCloseOpenRoom = useMemo(() => closeOpenRoom, [closeOpenRoom]);
  console.log("liveData", liveData?.event?.sport_id);
  const isMatkaLottery =
    +location?.state?.matchData?.sport_id === 999 ||
    +location?.state?.matchData?.sport_id === 333 ||
    +location?.state?.matchData?.sport_id === 777 ||
    +liveData?.event?.sport_id === 999 ||
    +liveData?.event?.sport_id === 333 ||
    +liveData?.event?.sport_id === 777;
  return (
    <>
      <InnerPageHeader
        pageTitle="Live Report Matches"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Matches", url: "/matches_list" },
          {
            title: eventName,
            url: {
              pathname: `/match_dashboard/${event_id}`,
              state: { matchData: liveData?.event },
            },
          },
        ]}
        currenPageTitle={"Live Report"}
      />
      {memorizeMarketList && memorizeMarketList.length > 0 && (
        <SocketHelper
          event_id={event_id}
          marketList={memorizeMarketList}
          closeAllRoom={memorizeCloseOpenRoom}
        />
      )}
      <div id="render-live-report12">
        {showPositionModalFlag && (
          <PositionModal
            sessionData={selectedSesstion}
            hideModal={() => hidePositionModal()}
          />
        )}
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="d-flex justify-between flex-center p-1 pt-0 live_report_toggle">
              {!detectMobView() && (
                <div className="pr-1 pl-1 flex-center hide_div"></div>
              )}
              {userRole?.role == 1 && (
                <div className="flex-center d-flex flex-center1">
                  <h3 className="pr-1 pl-1">
                    <strong>View : {liveData?.event?.view_count}</strong>
                  </h3>
                  <h3 className="pr-1 pl-1">
                    <strong>Bet : {liveData?.event?.bet_count}</strong>
                  </h3>
                </div>
              )}
              {liveData?.event?.sport_id && !isMatkaLottery && (
                <div className="d-flex flex-center">
                  <p className="pr-1">
                    <strong>on/off match for all my clients</strong>
                  </p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={matchToggleButton}
                      onChange={(e) => handleShowHideEvent(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              )}
              {!detectMobView() &&
                userRole?.role == 1 &&
                liveData?.event?.sport_id &&
                !isMatkaLottery &&
                showSubCompanyButton}
            </div>
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                {!isMatkaLottery && (
                  <>
                    {liveData &&
                    liveData.event &&
                    liveData.event.sport_id == 1 &&
                    liveData.event.score_widget &&
                    liveData.event.score_widget != "" ? (
                      <iframe
                        id="frame"
                        src={
                          liveData.event.score_widget
                            ? liveData.event.score_widget
                            : ""
                        }
                        width="100%"
                        height="222"
                        frameBorder="0"
                      ></iframe>
                    ) : (
                      <>
                        {toggleTv ? (
                          <div className="row mob_view">
                            <div
                              style={{
                                textAlign: "center",
                                width: "100%",
                                margin: "0",
                                padding: "0",
                              }}
                            >
                              <VideoModal
                                handleClose={() => setToggleTv(!toggleTv)}
                                url={liveData?.event?.live_tv_url}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="dragOuter-toggler jello-horizontal showToggler"
                            id="tvDiv"
                            onClick={() => {
                              setToggleTv(!toggleTv);
                              if (detectMobView()) {
                                window.scrollTo({
                                  top: 0,
                                  behavior: "smooth",
                                });
                              }
                            }}
                            data-id=" 31697188"
                          >
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="512.000000pt"
                              height="512.000000pt"
                              viewBox="0 0 512.000000 512.000000"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <g
                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                fill="#000000"
                                stroke="none"
                              >
                                <path
                                  d="M1535 5096 c-27 -13 -62 -36 -77 -52 -92 -98 -72 -269 40 -347 41
                              -28 57 -32 123 -35 l76 -4 234 -234 234 -234 -865 0 c-713 0 -877 -3 -933 -15
                              -182 -38 -330 -193 -358 -377 -6 -39 -8 -603 -7 -1513 3 -1311 5 -1454 20
                              -1495 53 -148 156 -249 303 -300 53 -18 84 -20 331 -20 l272 0 4 -197 c3 -218
                              6 -230 66 -259 46 -22 98 -11 134 28 26 28 28 36 28 109 l0 79 1400 0 1400 0
                              0 -79 c0 -73 2 -81 28 -109 36 -39 88 -50 134 -28 60 29 63 41 66 259 l4 197
                              272 0 c247 0 278 2 331 20 147 51 250 152 303 300 15 41 17 184 20 1495 1 910
                              -1 1474 -7 1513 -21 135 -117 270 -238 335 -105 56 -110 57 -1053 57 l-865 0
                              234 234 234 234 76 4 c66 3 82 7 123 35 112 78 132 249 40 347 -38 40 -118 76
                              -172 76 -52 0 -134 -35 -167 -71 -38 -42 -65 -115 -62 -170 l3 -50 -320 -320
                              -319 -319 -65 0 -65 0 -319 319 -320 320 3 50 c3 55 -24 128 -62 170 -33 36
                              -115 71 -167 71 -27 0 -66 -10 -95 -24z m3210 -1159 c51 -23 91 -61 118 -112
                              l22 -40 3 -1425 c2 -975 -1 -1441 -8 -1476 -15 -69 -59 -127 -122 -158 l-52
                              -26 -2146 0 -2146 0 -52 26 c-63 31 -107 89 -122 158 -7 35 -10 501 -8 1476
                              l3 1425 22 41 c37 69 91 107 178 127 11 3 976 4 2145 3 2024 -1 2127 -2 2165
                              -19z"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        )}
                        <LiveReportHeader
                          setActiveMarket={setActiveMarket}
                          liveData={liveData}
                        />
                      </>
                    )}
                  </>
                )}
                {liveData?.event?.announcement ? (
                  <div className="row border-bottom">
                    <ul className="marquee_score_widget">
                      <marquee>{liveData?.event?.announcement}</marquee>
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
                <div className=" d-flex flex-center1 pb-05 pt-1">
                  {detectMobView() &&
                    userRole?.role == 1 &&
                    liveData?.event?.sport_id &&
                    !isMatkaLottery &&
                    showSubCompanyButton}
                </div>
                {getFilterTypeMarketList(1, marketList).length > 0
                  ? getFilterTypeMarketList(1, marketList).map(
                      (data, pindex) => (
                        <MarketView
                          key={pindex}
                          data={data}
                          pindex={pindex}
                          userRole={userRole}
                          marketStatusChange={marketStatusChange}
                          liveData={liveData}
                          getValueForKhai={getValueForKhai}
                          getValueForLagai={getValueForLagai}
                          fetchMarketPosition={fetchMarketPosition}
                          announcement={announcement}
                          showAnnouncementRow={showAnnouncementRow}
                          handleChange={handleChange}
                          updateMarketAnnouncement={updateMarketAnnouncement}
                          clearMarketAnnouncement={clearMarketAnnouncement}
                        />
                      )
                    )
                  : null}
                {liveData && liveData.fancy && liveData.fancy.length
                  ? differentFancyList(liveData?.fancy).map((fancy) => (
                      <SessionView
                        key={fancy?.session_type}
                        fancy={fancy}
                        userRole={userRole}
                        fancyStatusChange={fancyStatusChange}
                        fetchFancyPosition={fetchFancyPosition}
                        onClickPositionModal={onClickPositionModal}
                      />
                    ))
                  : null}
                {getFilterTypeMarketList(2, marketList).length > 0
                  ? getFilterTypeMarketList(2, marketList)
                      // .filter((item) =>
                      //   isMatkaLottery
                      //     ? true
                      //     : item?.market_name === marketType
                      // )
                      .map((data, pindex) =>
                        isMatkaLottery ? (
                          <MatkaMarketView
                            keyValue={
                              +location?.state?.matchData?.sport_id === 999 ||
                              +location?.state?.matchData?.sport_id === 777 ||
                              +liveData?.event?.sport_id === 999 ||
                              +liveData?.event?.sport_id === 777
                                ? "matka_market_id"
                                : "lottery_market_id"
                            }
                            key={pindex}
                            data={data}
                            pindex={pindex}
                            userRole={userRole}
                            marketStatusChange={marketStatusChange}
                            liveData={liveData}
                            getValueForKhai={getValueForKhai}
                            getValueForLagai={getValueForLagai}
                            fetchMarketPosition={fetchMarketPosition}
                          />
                        ) : (
                          <MarketView
                            key={pindex}
                            data={data}
                            pindex={pindex}
                            userRole={userRole}
                            marketStatusChange={marketStatusChange}
                            liveData={liveData}
                            getValueForKhai={getValueForKhai}
                            getValueForLagai={getValueForLagai}
                            fetchMarketPosition={fetchMarketPosition}
                            announcement={announcement}
                            showAnnouncementRow={showAnnouncementRow}
                            handleChange={handleChange}
                            updateMarketAnnouncement={updateMarketAnnouncement}
                          />
                        )
                      )
                  : null}
              </div>
            </div>
            {liveData?.event?.sport_id && !isMatkaLottery && (
              <div
                className="row " //table-responsive
              >
                <DeclaredOddsFancyView
                  setDeclaredColumn={setDeclaredColumn}
                  showDeclared={showDeclared}
                  declaredFancyData={declaredFancyData}
                  userRole={userRole}
                  showConfirmPoppup={showConfirmPoppup}
                  getDeclaredSatatusSum={getDeclaredSatatusSum}
                  isLoading={isLoading}
                  declaredMarketData={declaredMarketData}
                  getWinner={getWinner}
                />
              </div>
            )}
            {liveMatches && liveMatches.length
              ? liveMatches.map((match) => (
                  <LiveMatchesList
                    key={match.event_id}
                    match={match}
                    redirectToFixture={(e) => redirectToFixture(e)}
                  />
                ))
              : null}

            <div className="wrapper wrapper-content">
              <div className="row">
                <div className="col-lg-12"></div>
              </div>
            </div>
          </div>
          <Modal
            show={showConfirmPopup}
            onHide={() => !showLoader && hideConfirmModal()}
            style={{ opacity: 1 }}
          >
            <Modal.Header closeButton>Confrim</Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>Please enter 4 digit's varification code.</label>
                <input
                  placeholder="varification code"
                  className="form-control"
                  value={rollbackCode}
                  onChange={(e) => setRollbackCode(e.target.value)}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => !showLoader && hideConfirmModal()}>
                No
              </Button>
              <Button
                disabled={rollbackCode.length !== 4}
                type="button"
                onClick={() => !showLoader && onConfirmClick()}
              >
                {showLoader ? (
                  <ClipLoader color={"#FFFFFF"} loading={true} size={16} />
                ) : (
                  "Submit"
                )}
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={confirmMessage?.isOpen}
            onHide={() =>
              setConfirmMessage({ ...confirmMessage, isOpen: false })
            }
            style={{ opacity: 1 }}
          >
            <Modal.Header closeButton>Rollback Complete</Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <span className="rollback_text">{`RollBack of ${confirmMessage?.dataForRoll?.event_name} | ${confirmMessage?.dataForRoll?.market_name} is done`}</span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="button"
                onClick={() =>
                  setConfirmMessage({ ...confirmMessage, isOpen: false })
                }
              >
                ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default LiveReport;
