import { userProfileConstants } from "_constants";

let initialState = {
  isLoader: false,
  data:{}
};

export function userProfileReducer(state = initialState, action) {
  switch (action.type) {
    case userProfileConstants.USERPROFILE_REQUEST:
      return { ...state, isLoader: true }
    case userProfileConstants.USERPROFILE_SUCCESS:
      return { ...state, data: action.payload?.data, isLoader: false }
    default:
      return state
  }
}
