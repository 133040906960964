import React, { Component } from "react";
import Main from "../../components/layouts/Main";
import ReportList from "../../components/MatkaReport/List";
import { connect } from "react-redux";
import { reportData } from "./reportdata";
import SingleReportList from "../../components/MatkaReport/SingleReportList";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbTitle: ''
    };
  }
  componentDidMount() {
    switch (this.props.match?.params?.rp_type) {
      case 'single_patti':
        this.setState({ breadcrumbTitle: "Single Patti" })
        break;
      case 'double_patti':
        this.setState({ breadcrumbTitle: 'Double Patti' })
        break;
      case 'tripple_patti':
        this.setState({ breadcrumbTitle: 'Tripple Patti' })
        break;
      case 'jodi_patti':
        this.setState({ breadcrumbTitle: 'Jodi Patti' })
        break;
      case 'single_akda':
        this.setState({ breadcrumbTitle: 'Single Akda' })
        break;
      default:
        this.props.history.goBack()
        break;
    }
  }
  render() {
    return (
      <Main>
        {this.props.match?.params?.rp_type === 'single_akda' ?
          <SingleReportList
            dataList={reportData}
            {...this.props}
            breadcrumbTitle={this.state.breadcrumbTitle + ' Report'} />
          :
          <ReportList
            dataList={reportData}
            {...this.props}
            breadcrumbTitle={this.state.breadcrumbTitle + ' Report'} />
        }
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(List);
