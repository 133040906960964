export * from './store';

export const convertParseFloat = (num) => num != 0 ? parseFloat(num).toFixed(2).replace(/\.00$/,'') : num
export const isFloat = (value) => {
    // First, check if the value is a number
    if (isNaN(value)) {
      return false;
    }
  
    // Then, check if the value is a floating-point number
    var floatValue = parseFloat(value);
    return Number.isFinite(floatValue) && !Number.isInteger(floatValue);
  }
// Company report
// Clint report 
// Coin history 
// Live report
// Sub company live report
// Ledger
