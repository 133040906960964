import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import { getEventScore, eventScore } from "_services";
//import { useSelector } from "react-redux";
import "./style.css";
import { toast } from "react-toastify";

function Scoreboard(props) {
  const location = useLocation();

  const { matchData } = location && location.state ? location.state : {};

  const { event_id } = useParams();

  const [isLoading, updateLoading] = useState(false);
  
  const userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const [battingTeam, setBattingTeam] = useState('');
  const [firstTeam, setFirstTeam] = useState('');
  const [firstScore, setFirstScore] = useState('');
  const [firstWicket, setFirstWicket] = useState('');
  const [firstOver, setFirstOver] = useState('');
  const [secondTeam, setSecondTeam] = useState('');
  const [secondScore, setSecondScore] = useState('');
  const [secondWicket, setSecondWicket] = useState('');
  const [secondOver, setSecondOver] = useState('');
  const [curRate,setCurRate] = useState("");
  const [reqRate,setReqRate] = useState("");
  const [message,setMessage] = useState('');
  const [firstInn,setFirstInn] = useState("");
  const [secondInn,setSecondInn] = useState("");  
  const innRadio = useRef();
  const [isRadio, setIsRadio] = useState(2);

  useEffect(() =>{
    loadData();
    /*let r = {
      "type": 1,
      "data": {
        "spnnation1": "PAK",
        "spnnation2": "AFG",
        "spnballrunningstatus": "",
        "score1": "130-6 (20.0)",
        "score2": "133-3 (19.5)",
        "spnrunrate1": "",
        "spnrunrate2": "6.71",
        "spnmessage": "AFG won the match",
        "spnreqrate1": "",
        "spnreqrate2": "",
        "dayno": "",
        "isfinished": "0",
        "activenation1": "0",
        "activenation2": "1",
        "balls": [
          "6",
          "0",
          "1",
          "1",
          "1"
        ]
      }
    } */                                        
  },[]);

  function loadData(){
    if (event_id) {
      let sendData = {};
      sendData.event_id = event_id;

      getEventScore(sendData)
          .then((response) => {
            let r = response?.data?.data?.score;    
            SetResult(r);
          }).catch((error) => { })
    } 
  }

  function SetResult(r){        
    setMessage(r.data.spnmessage); 
      
    if(r.data.activenation1 === "1"){
        setBattingTeam(r.data.spnnation2);
        setFirstTeam(r.data.spnnation2);
        setSecondTeam(r.data.spnnation1);
        setCurRate(r.data.spnrunrate1);
        setReqRate(r.data.spnreqrate1);   
        setFirstInn("firstInn");   
        setSecondInn("");  

        let r_s = r.data.score1.split(" "); //130-6 (20.0)
        let run = r_s[0].split("-"); //130-6
        setSecondScore(Number(run[0]));  //130
        let r_over1 = r_s[1].substring(1).slice(0,-1); //(20.0)
        setSecondOver(Number(r_over1)); //20.0
        setSecondWicket(Number(run[1])); //6       
        
        let r_s2 = r.data.score2.split(" "); //133-3 (19.5)
        let run2 = r_s2[0].split("-"); //133-3
        setFirstScore(Number(run2[0]));  //133 score
        let r_over2 = r_s2[1].substring(1).slice(0,-1); //(19.5)     
        setFirstWicket(Number(run2[1])); //3 wicket
        setFirstOver(Number(r_over2));  //19.5 over   
        setMessage(r.data.spnmessage); 

    } else {
        setBattingTeam(r.data.spnnation1);
        setSecondTeam(r.data.spnnation1);
        setFirstTeam(r.data.spnnation2);
        setCurRate(r.data.spnrunrate2);
        setReqRate(r.data.spnreqrate2);        
        setSecondInn("secondInn");
        setFirstInn("");   

        let r_s = r.data.score1.split(" "); //130-6 (20.0)
        let run = r_s[0].split("-"); //130-6
        setFirstScore(Number(run[0]));  //130
        let r_over1 = r_s[1].substring(1).slice(0,-1); //(20.0)
        setFirstOver(Number(r_over1)); //20.0
        setFirstWicket(Number(run[1])); //6       

        let r_s2 = r.data.score2.split(" "); //133-3 (19.5)
        let run2 = r_s2[0].split("-"); //133-3
        setSecondScore(Number(run2[0]));  //133 score
        let r_over2 = r_s2[1].substring(1).slice(0,-1); //(19.5)     
        setSecondWicket(Number(run2[1])); //3 wicket
        setSecondOver(Number(r_over2));  //19.5 over   
        setMessage(r.data.spnmessage); 
    } 
  }  

  let [num, setNum]= useState(0);

  let incNumFirstScore =()=>{            
      setFirstScore(firstScore+1);          
  };
  let decNumFirstScore = () => {       
    if(firstScore > 0)  
      setFirstScore(firstScore-1); 
  };

  let incNumFirstWicket =()=>{      
    if(firstWicket < 10){          
      setFirstWicket(firstWicket+1);         
    }  
  };
  let decNumFirstWicket = () => {       
    if(firstWicket > 0)   
      setFirstWicket(firstWicket-1); 
  };

  let incNumFirstOver =()=>{               
      if(Math.round((firstOver%1) * 10) / 10 === 0.5)//for ex: 19.6 last ball to next over
        setFirstOver(Math.round((firstOver + 0.5 % 1) * 10) / 10);  
      else 
        setFirstOver(Math.round((firstOver + 0.1 % 1) * 10) / 10);                 
  };

  let decNumFirstOver = () => {           
    if(firstOver > 0 && Math.round((firstOver%1) * 10) / 10 === 0.0){        
      setFirstOver(Math.round((firstOver - 0.5 % 1) * 10) / 10);  
     } else  
       setFirstOver(Math.round((firstOver - 0.1 % 1) * 10) / 10);                     
  };

  let incNumSecondScore =()=>{      
     setSecondScore(secondScore+1);      
  };
  let decNumSecondScore = () => {       
    if(secondScore > 0)   
      setSecondScore(secondScore-1); 
  };

  let incNumSecondWicket =()=>{       
    if(secondWicket < 10){         
      setSecondWicket(secondWicket+1);          
    } 
  };
  let decNumSecondWicket = () => {       
    if(secondWicket > 0)     
      setSecondWicket(secondWicket-1); 
  };

  let incNumSecondOver =()=>{           
      if(Math.round((secondOver%1) * 10) / 10 === 0.5)//for ex: 19.6 last ball to next over
        setSecondOver(Math.round((secondOver + 0.5 % 1) * 10) / 10);  
      else 
        setSecondOver(Math.round((secondOver+ 0.1 % 1) * 10) / 10 );            
  };

  let decNumSecondOver = () => {       
    if(secondOver > 0 && Math.round((secondOver%1) * 10) / 10 === 0.0){        
      setSecondOver(Math.round((secondOver-0.5 % 1) * 10) / 10);  
    }else  
       setSecondOver(Math.round((secondOver-0.1 % 1) * 10) / 10);             
      
  };

  function handleChange(e){
     if(e.target.value == "firstInn"){
       setFirstInn(e.target.value);
       setSecondInn("");
     } else if(e.target.value == "secondInn"){
       setFirstInn("");
       setSecondInn(e.target.value);
     }
  }

  function onSubmit(e){
      e.preventDefault();     
      let spnrunrate1 = '';
      let spnrunrate2 = '';
      let spnreqrate1 = '';
      let spnreqrate2 = '';
      let activenation1 = '';
      let activenation2 = '';
      if(firstInn === "firstInn"){
        spnrunrate1 = curRate;
        spnrunrate2 = '';
        spnreqrate1 = reqRate;
        spnreqrate2 = '';
        activenation1 = '1';
        activenation2 = '0';
        setFirstInn("firstInn");
        setSecondInn("");
      } else if(secondInn === "secondInn"){
        spnrunrate1 = '';
        spnrunrate2 = curRate;
        spnreqrate1 = '';
        spnreqrate2 = reqRate;
        activenation1 = '0';
        activenation2 = '1';
        setFirstInn("");
        setSecondInn("secondInn");
      }      

      let sendData = {
        "event_id": event_id,
        "score": {
          "type": 1,
          "data":{"spnnation1": firstTeam,
          "spnnation2": secondTeam,
          //"spnballrunningstatus": "",          
          "score1": firstScore+"-"+firstWicket+" ("+firstOver+")",
          "score2": secondScore+"-"+secondWicket+" ("+secondOver+")",
          "spnrunrate1": spnrunrate1,
          "spnrunrate2": spnrunrate2,
          "spnmessage": message,
          "spnreqrate1": spnreqrate1,
          "spnreqrate2": spnreqrate2,
          //"dayno": "",
          //"isfinished": "0",
          "activenation1": activenation1,
          "activenation2": activenation2
          /*"balls": [
            "6",
            "0",
            "1",
            "1",
            "1"
          ]*/
        }}
      }     
      updateLoading(true)
      eventScore(sendData)
      .then((response) => {
        if (response && response.data) {
          toast(response.data.spnmessage?response.data.spnmessage:"Score updated");          
        } 
        else{
          updateLoading(false)

        }

      })
      .catch((error) => { 
        updateLoading(false)

      });
    //}
  }

  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>Scoreboard</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/">Dashboard</a>
            </li>
            <li>
              <a href="/matches_list">Matches</a>
            </li>
            <li className="breadcrumb-item active">
              <Link
                to={{
                  pathname: `/match_dashboard/${event_id}`,
                  state: { matchData: matchData },
                }}
              >
                {matchData?.event_name}
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <strong>Scoreboard</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2"></div>
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Scoreboard</h5>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <form>

                      <div className="form-group">
                       <div className="col-sm-8">
                        <div className="form-group">
                          <label className="">Batting Team Name</label>
                          <input autoComplete="off" type="text" className="form-control" value={battingTeam  || ''} onChange={(e) => setBattingTeam(e.target.value)}/>                          
                        </div>
                       </div>  
                      </div> 

                      <div className="form-group">
                       <div className="col-sm-12">
                        <div className="form-group">
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', marginTop: 5 }}>
                                <input
                                  type="radio"
                                  style={{ width: 20, height: 20, textAlign: 'left', marginRight: 10 }}
                                  className="form-control col-sm-1"
                                  name="fixsharing_on"
                                  id="fixsharing_on"
                                  placeholder="On"  
                                  value="firstInn"                                                       
                                  checked={ firstInn ? (true):(false) }
                                  /*onChange={(e) => setFirstInn(e.target.checked)}*/                                                        
                                  onChange={(e) => handleChange(e)} 
                                />
                                First Innings
                          </div>
                        </div>
                       </div>  
                      </div> 
                                           
                      <div className="form-group">
                       <div className="col-sm-2">
                        <div className="form-group">
                          <label className="">Team</label>
                          <input autoComplete="off" type="text" className="form-control" value={firstTeam  || ''} onChange={(e) => setFirstTeam(e.target.value)}/>
                        </div>
                       </div>

                       <div className="col-sm-2 ml_l_23">
                        <div className="col-sm-10">
                            <label className="">Score</label>
                            <div className="scoreboard_input active input-group">
                              <div className="input-group-prepend" onClick={decNumFirstScore}><span className="input-group-text">-</span></div>
                              <input maxLength="" type="text" className="form-control inc_dcr_t" value={firstScore  || ''} onChange={(e) => setFirstScore(e.target.value)}/>
                              <div className="input-group-append" onClick={incNumFirstScore}><span className="input-group-text">+</span></div>                          
                            </div>                        
                         </div>
                         <div className="col-sm-2 pt_15">                                                                           
                            <span className="scoreboard-backslash">/</span>                         
                         </div>
                       </div>                       
                       
                       <div className="col-sm-2 ml_23">
                         <div className="col-sm-10">
                            <label className="">Wicket</label>
                            <div className="scoreboard_input active input-group">
                              <div className="input-group-prepend" onClick={decNumFirstWicket}><span className="input-group-text">-</span></div>
                              <input maxLength="" type="text" className="form-control inc_dcr_t" value={firstWicket || ''} onChange={(e) => setFirstWicket(e.target.value)}/>
                              <div className="input-group-append" onClick={incNumFirstWicket}><span className="input-group-text">+</span></div>                          
                            </div>                        
                         </div>
                         <div className="col-sm-2 pt_15">                                                                           
                            <span className="scoreboard-backslash">/</span>                         
                         </div>
                       </div>

                       <div className="col-sm-2 ml_23">
                       <label className="">Over</label> 
                        <div className="scoreboard_input active input-group">
                          <div className="input-group-prepend" onClick={decNumFirstOver}><span className="input-group-text">-</span></div>
                          <input maxLength="" name="over" type="text" className="form-control inc_dcr_t_l" value={firstOver || ''} onChange={(e) => setFirstOver(e.target.value)}/>
                          <div className="input-group-append" onClick={incNumFirstOver}><span className="input-group-text">+</span></div>
                        </div>
                       </div>
                       
                       <div className="update-run-col col-lg-3 ml_23">
                        <div className="form-group">
                         <button type="submit" className="update-over-btn btn btn-primary btn-sm" onClick={(e)=>onSubmit(e)}>Update</button>
                         <button type="button" className="refresh-msg-btn-1 btn btn-primary btn-sm" onClick={(e)=>loadData(e)}>Refresh</button>
                        </div> 
                       </div>

                      </div>

                      <div className="form-group">
                       <div className="col-sm-12">
                        <div className="form-group">
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', marginTop: 5 }}>
                                <input
                                  type="radio"
                                  style={{ width: 20, height: 20, textAlign: 'left', marginRight: 10 }}
                                  className="form-control col-sm-1"
                                  name="fixsharing_on"                                 
                                  placeholder="On"   
                                  value="secondInn"                                                                
                                  checked={ secondInn ? (true):(false) }                                  
                                  /*onChange={(e) => setSecondInn(e.target.checked)} */
                                  /*checked={isRadio === 2}*/                                  
                                  onChange={(e) => handleChange(e)}  
                                />
                                Second Innings
                          </div>
                        </div>
                       </div>  
                      </div> 
                                           
                      <div className="form-group">
                       <div className="col-sm-2">
                        <div className="form-group">
                          <label className="">Team</label>
                          <input autoComplete="off" type="text" className="form-control" value={secondTeam || ''} onChange={(e) => setSecondTeam(e.target.value)}/>
                        </div>
                       </div>

                       <div className="col-sm-2 ml_l_23">
                        <div className="col-sm-10">
                            <label className="">Score</label>
                            <div className="scoreboard_input active input-group">
                              <div className="input-group-prepend" onClick={decNumSecondScore}><span className="input-group-text">-</span></div>
                              <input maxLength="" name="score" type="text" className="form-control inc_dcr_t" value={secondScore || ''} onChange={(e) => setSecondScore(e.target.value)}/>
                              <div className="input-group-append" onClick={incNumSecondScore}><span className="input-group-text">+</span></div>                          
                            </div>                        
                         </div>
                         <div className="col-sm-2 pt_15">                                                                           
                            <span className="scoreboard-backslash">/</span>                         
                         </div>
                       </div>                       
                       
                       <div className="col-sm-2 ml_23">
                         <div className="col-sm-10">
                            <label className="">Wicket</label>
                            <div className="scoreboard_input active input-group">
                              <div className="input-group-prepend" onClick={decNumSecondWicket}><span className="input-group-text">-</span></div>
                              <input maxLength="" type="text" className="form-control inc_dcr_t" value={secondWicket || ''} onChange={(e) => setSecondWicket(e.target.value)}/>
                              <div className="input-group-append" onClick={incNumSecondWicket}><span className="input-group-text">+</span></div>                          
                            </div>                        
                         </div>
                         <div className="col-sm-2 pt_15">                                                                           
                            <span className="scoreboard-backslash">/</span>                         
                         </div>
                       </div>

                       <div className="col-sm-2 ml_23">
                       <label className="">Over</label> 
                        <div className="scoreboard_input active input-group">
                          <div className="input-group-prepend" onClick={decNumSecondOver}><span className="input-group-text">-</span></div>
                          <input maxLength="" type="text" className="form-control inc_dcr_t_l" value={secondOver || ''} onChange={(e) => setSecondOver(e.target.value)}/>
                          <div className="input-group-append" onClick={incNumSecondOver}><span className="input-group-text">+</span></div>
                        </div>
                       </div>
                       
                       <div className="update-run-col col-lg-3 ml_23">
                        <div className="form-group">
                         <button type="submit" className="update-over-btn btn btn-primary btn-sm"  onClick={(e)=>onSubmit(e)}>Update</button>
                         <button type="button" className="refresh-msg-btn-1 btn btn-primary btn-sm" onClick={(e)=>loadData(e)}>Refresh</button>
                        </div> 
                       </div>
                       
                      </div>

                      <div className="form-group">
                       <div className="col-sm-2">
                        <div className="form-group">
                          <label className=""></label>                          
                        </div>
                       </div>
                       <div className="col-sm-2">
                        <div className="form-group">
                          <label className="">Current Run Rate</label>
                          <input  autoComplete="off" type="text" className="form-control" value={curRate || ''} onChange={(e)=>setCurRate(e.target.value)}/>
                        </div>
                       </div>
                       <div className="col-sm-2">
                        <div className="form-group">
                          <label className="">Require Run Rate</label>
                          <input autoComplete="off" type="text" className="form-control" value={reqRate || ''} onChange={(e)=>setReqRate(e.target.value)}/>
                        </div>
                       </div>                       
                       <div className="update-run-col col-lg-3">
                        <div className="form-group">
                         <button type="submit" className="update-over-btn btn btn-primary btn-sm"  onClick={(e)=>onSubmit(e)}>Update</button>
                         <button type="button" className="refresh-msg-btn-1 btn btn-primary btn-sm" onClick={(e)=>loadData(e)}>Refresh</button>
                        </div> 
                       </div>
                      </div>

                      <div className="form-group">
                       <div className="col-sm-2">
                        <div className="form-group">
                          <label className=""></label>                          
                        </div>
                       </div>
                       <div className="col-sm-6">
                        <div className="form-group">
                          <label className="">Message</label>
                          <input name="" autoComplete="off" type="text" className="form-control" value={message || ''} onChange={(e)=>setMessage(e.target.value)}/>
                        </div>
                       </div>
                                            
                       <div className="update-run-col col-lg-3">
                        <div className="form-group">
                         <button type="submit" className="update-over-btn btn btn-primary btn-sm"  onClick={(e)=>onSubmit(e)}>Update</button>
                         <button type="button" className="refresh-msg-btn-1 btn btn-primary btn-sm" onClick={(e)=>loadData(e)}>Refresh</button>
                        </div> 
                       </div>
                      </div>

                    </form>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="render-sub-company-live-report">
        <div className="wrapper wrapper-content">
          <div className="row" style={{ margin: "0 !important" }}>
            <div className="match-index-row header-top-spacing">
              <div
                className="row"
                style={{ paddingBottom: "30px", margin: "auto auto" }}
              ></div>
            </div>
          </div>

          <div className="wrapper wrapper-content">
            <div className="row">
              <div className="col-lg-12"></div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default Scoreboard;
