import React from "react";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../../_actions";
import { connect } from "react-redux";
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
const AuthVerify = (props) => {
  props.history.listen(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedJwt = parseJwt(token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        logOut();
      }
    }
  });

  const logOut = () => {
    props.dispatch(
      logoutUser((result) => {
        if (result) {
          props.history.push("/dashboard");
        } else {
            props.history.push("/dashboard");
        }
      })
    );
  };
  return <div></div>;
};

const mapStateToProps = (state) => ({
  ...state,
});

// export default connect(mapStateToProps,null,null,{forwardRef:true})(SignInModal);

export default connect(mapStateToProps)(withRouter(AuthVerify));
