import React, { useState } from "react";
import "./ScoreWidget.css";

export const ViewScoreWidget = (props) => {
  const { updateData } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className="container-fluid" style={{ width: "100%" }}>
      <div className="row content-top-padding">
        <div
          className="content_matches_wrapper mt-zero"
          id="content_matches_wrapper"
        >
          <div className="bet_status_box match-score content_in_play_wrapper match-status-table match-score-box-color anvWidget score_widget">
            <div
              className="inplay-show-tbl-td match-status-text match-score-box-color "
              id="score_table_383067"
            >
              <span className="match-score-span-2 font-sans-serif">
                {updateData?.live_score1 ? updateData?.live_score1 : ""}
              </span>
              <span className="match-score-span-2 font-sans-serif">
                {updateData?.live_score2 ? updateData?.live_score2 : ""}
              </span>
              {updateData?.live_msg && <span className="match-score-span-2 font-sans-serif">
                {updateData?.live_msg}{" "}
              </span>}
              <span className="match-score-span-2 font-sans-serif">
                <div className="scoreBall_list anvFlex">
                  <ul>
                    <li className="LastBall">CR Over</li>
                    {updateData ? (
                      updateData?.sixBall?.map((i) => (
                        <li>
                          <span
                            style={{
                              backgroundColor:
                                i === "6"
                                  ? "#FF69B4"
                                  : i === "4"
                                    ? "#87CEEB"
                                    : i === "Wb"
                                      ? "#808080"
                                      : i === "W"
                                        ? "#FF0000"
                                        : i === "Nb"
                                          ? "#008000"
                                          : "",
                            }}
                          >
                            {i}
                          </span>
                        </li>
                      ))
                    ) : (
                      <>
                        <li>
                          <span>0</span>
                        </li>
                        <li>
                          <span>0</span>
                        </li>
                        <li>
                          <span>0</span>
                        </li>
                        <li>
                          <span>0</span>
                        </li>
                        <li>
                          <span>0</span>
                        </li>
                        <li>
                          <span>0</span>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </span>
              <span className="match-score-span-2 font-sans-serif anvBottom_arrow">
                {open ? (
                  <div
                    className="refresh_img"
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    Close
                  </div>
                ) : (
                  <div
                    className="refresh_img"
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    More
                  </div>
                )}
              </span>
              {open && (
                <span className="match-score-span-2 font-sans-serif anvTab_score">
                  {/* <div className='row'> */}
                  <div
                    show={open}
                    onHide={() => setOpen(false)}
                    className="table_show"
                  >
                    <table className="score2_table">
                      <thead>
                        <tr className="tableHeadof_score">
                          <th>Batsman</th>
                          <th>R</th>
                          <th>B</th>
                          <th>4s</th>
                          <th>6s</th>
                          <th>SR</th>
                        </tr>
                      </thead>
                      {updateData?.players ? (
                        updateData?.players?.Batsman?.map((i, index) => (
                          <tbody key={index} style={{ backgroundColor: "#00669d" }}>
                            <tr>
                              <td>{i?.name ? i?.name : ""}</td>
                              <td>{i?.run ? i?.run : ""}</td>
                              <td>{i?.ball ? i?.ball : ""}</td>
                              <td>{i?.fours ? i?.fours : ""}</td>
                              <td>{i?.sixes ? i?.sixes : ""}</td>
                              <td>
                                {i?.strike_rate === "NaN" ? "" : i?.strike_rate}
                              </td>
                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </>
                      )}
                    </table>
                    {updateData?.players?.bolwer?.name && (
                      <table className="score2_table">
                        <thead>
                          <tr className="tableHeadof_score">
                            <th>Boller</th>
                            <th>O</th>
                            <th>R</th>
                            <th>W</th>
                            <th>E</th>
                          </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#00669d" }}>
                          <tr>
                            <td>
                              {updateData?.players?.bolwer?.name
                                ? updateData?.players?.bolwer?.name
                                : ""}
                            </td>
                            <td>
                              {updateData?.players?.bolwer?.over
                                ? updateData?.players?.bolwer?.over
                                : ""}
                            </td>
                            <td>
                              {updateData?.players?.bolwer?.run
                                ? updateData?.players?.bolwer?.run
                                : ""}
                            </td>
                            <td>
                              {updateData?.players?.bolwer?.wicket
                                ? updateData?.players?.bolwer?.wicket
                                : 0}
                            </td>
                            <td>
                              {updateData?.players?.bolwer?.economy
                                ? updateData?.players?.bolwer?.economy
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
