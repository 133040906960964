import _ from "lodash";

const DevHost = [
  "localhost",
  "betlagao.com",
  "www.betlagao.com",
  "user.betlagao.com",
  "admin.betlagao.com",
];
const LiveHost = ["admin.7daymatka.com","agent.7daymatka.com", "www.agent.7daymatka.com"];
const StagHost = ["7day_admin.radhebet.com"];

const hostname = window.location.hostname;

const live = {
  api_baseurl: "https://adminapi.7daymatka.com/api/",
  baseurl: "https://adminapi.7daymatka.com/",
  socketurl: "https://node.7daymatka.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};

const dev = {
  api_baseurl: "https://7day_admin_api.radhebet.com/api/",
  baseurl: "https://7day_admin_api.radhebet.com/",
  socketurl: "https://7day-node.radhebet.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};

const stag = {
  api_baseurl: "https://7day_admin_api.radhebet.com/api/",
  baseurl: "https://7day_admin_api.radhebet.com/",
  socketurl: "https://7day-node.radhebet.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};

const AppConfig = _.includes(LiveHost, hostname)
  ? live
  : _.includes(StagHost, hostname)
  ? stag
  : dev;

// const AppConfig =  _.includes(DevHost, hostname)? dev: live;

export default AppConfig;
