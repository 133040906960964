import React, { Component } from 'react'
import Main from "../../components/layouts/Main";
import BlockedComponent from 'components/Client/Blocked';
export class Blocked extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Main>
                <BlockedComponent title="Clients Blocked List" role={[7]} />
            </Main>

        )
    }
}

export default Blocked