import _ from 'lodash';

const DevHost     = ["localhost","betlagao.com", "www.betlagao.com","user.betlagao.com","admin.betlagao.com"];
const LiveHost    = ["agent.7daymatka.com","admin.7daymatka.com", "www.admin.7daymatka.com"];
const StagHost     = ["top2020.pro","top2020.in","www.top2020.pro","www.top2020.in"];

const hostname = window.location.hostname;

const live = {
  baseURL: "https://adminapi.7daymatka.com",
  socketURL: "https://node.7daymatka.com"
};

const dev = {
  baseURL: "https://adminapi.betlagao.com/api",
  socketURL: "https://7day-node.radhebet.com" 
};

const stag = {
  baseURL: "https://adminapi.top2020.pro/api",
  socketURL: "https://7day-node.radhebet.com"
};


export const AppConfig =  _.includes(LiveHost, hostname)? live: (_.includes(StagHost, hostname)? stag: dev);

// export const AppConfig =  _.includes(LiveHost, hostname) ? dev: live;
