import { subcompanyConstants } from "../../_constants";
import { create, list } from "../../_services";
import { toast } from "react-toastify";

export const createRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: subcompanyConstants.SUBCOMPANY_CREATE_REQUEST, data: {} });
    create(data)
      .then((response) => {
        callback(response.data);
        toast.success("Recorded added successfully");
        dispatch({
          type: subcompanyConstants.SUBCOMPANY_CREATE_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: subcompanyConstants.FAILED_RESPONSE, data: error });
      });
  };
};

export const listRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: subcompanyConstants.SUBCOMPANY_LIST_REQUEST, data: {} });
    list(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: subcompanyConstants.SUBCOMPANY_LIST_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: subcompanyConstants.SUBCOMPANY_LIST_FAILED,
          data: error.response,
        });
      });
  };
};
