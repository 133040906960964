import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, Redirect, withRouter } from "react-router-dom";
import $ from "jquery";
import metismenu from "metismenu";
import { logoutUser } from "../../_actions";
// import metismenu from 'metismenu';
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import MetisMenu from "@metismenu/react";
import "metismenujs/dist/metismenujs.css";
import { detectBody, detectMobView } from "components/layouts/Helpers";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: "",
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }



  updateDimensions = () => {
    // window.location.reload();
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    detectBody();
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    const { menu } = this.refs;

    $(menu).metisMenu();
  }

  toggleNavigation(e) {
    if (this.state.width <= 768) {
      $("body").toggleClass("mini-navbar");
    }
  }

  activeRoute(routeName) {
    return this.props.location.pathname === routeName ? "current-menu" : "";

    // return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  secondLevelActive(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1
      ? "nav nav-second-level collapse in"
      : "nav nav-second-level collapse";
  }

  logOut = () => {
    this.props.dispatch(
      logoutUser((result) => {
        if (result) {
          this.setState({ redirect: "login" });
        } else {
          this.setState({ redirect: "login" });
        }
      })
    );
  };

  getRole = () => {
    var roleValue = "User";
    let role = localStorage.getItem("role") || null;
    switch (role) {
      case "1":
        roleValue = "Company";
        break;
      case "2":
        roleValue = "Sub Company";
        break;
      case "3":
        roleValue = "Master";
        break;
      case "4":
        roleValue = "Super Stockist";
        break;
      case "5":
        roleValue = "Stockist";
        break;
      case "6":
        roleValue = "Agent";
        break;
      case "7":
        roleValue = "Client";
        break;
      default:
        roleValue = "User";
    }

    return roleValue;
  };

  render() {
    const userLogin = JSON.parse(localStorage.getItem("userLogin"));

    let { redirect } = this.state;
    let role = localStorage.getItem("role")
    var menuList = this.props.auth?.userLogin.menu;

    if (redirect === "login") {
      return <Redirect to="/dashboard" />;
    }
    return (
      <>
        <nav
          className={" navbar-default navbar-static-side"}
          id={
            !detectMobView()
              ? "scrollable-container"
              : ""
          }
          role="navigation"
          style={{ backgroundColor: "#2f4050" }}
        >
          <ul className="nav metismenu" id="side-menu" ref="menu">
            <li className="nav-header">
              <div className="dropdown profile-element">
                {" "}
                <span></span>
                <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                  <span className="clear">
                    {" "}
                    <span className="block m-t-xs">
                      {" "}
                      <strong className="font-bold">
                        {" "}
                        {userLogin.username}({userLogin.first_name}){" "}
                      </strong>
                    </span>{" "}
                    <span className="text-muted text-xs block">
                      {this.getRole()}
                    </span>{" "}
                  </span>{" "}
                </a>
              </div>
              <div className="logo-element">7Day</div>
            </li>
            {menuList.map((menus, mainIndex) => (
              // className={this.activeRoute("/"+menus.link)}
              <li
                className={
                  this.activeRoute("/" + menus.link) +
                  (mainIndex === 0 ? " navbar-first-li" : "")
                }
                key={mainIndex}
              >
                {menus.submenu.length > 0 ? (
                  <>
                    <Link
                      to={`/${menus.link}`}
                      data-toggle="dropdown"
                    //   className="dropdown-toggle"
                    >
                      <i className={menus.icon.replace("icon", "fa fa")}></i>
                      <span className="nav-label">{menus.label}</span>
                      <span className="fa arrow"></span>
                    </Link>
                    <ul
                      //   className={"nav nav-second-level h-auto"}
                      className={
                        (detectMobView()
                          ? "sub_menu_scroll "
                          : "") +
                        (this.state.width <= 768
                          ? "nav nav-second-level h-auto dropdown-menu submenu-text"
                          : "nav nav-second-level h-auto menu-responsive")
                      }
                    >
                      {(role === "1" ? menus.submenu : menus.submenu.filter((item) => item.label !== "Maintenance Mode")).map((subM, index) => {

                        return <li key={index}>
                          <a
                            onClick={(e) => {
                              const mLink = "/" + subM.link;
                              const mPath = window.location.pathname;
                              if (mLink.includes(mPath)) {
                                this.props.history.push(`/${subM.link}`);
                                window.location.reload();
                              } else {
                                this.toggleNavigation();
                                this.props.history.push(`/${subM.link}`);
                              }
                            }}
                            className={
                              this.state.width <= 768 ? "submenu-text" : ""
                            }
                          >
                            <i
                              className={subM.icon.replace("icon", "fa fa")}
                            ></i>
                            {subM.label}
                          </a>
                        </li>
                      })}
                    </ul>
                  </>
                ) : (
                  <Link to={`/${menus.link}`} onClick={() => this.toggleNavigation()}>
                    <i className={menus.icon.replace("icon", "fa fa")}></i>
                    <span className="nav-label">{menus.label}</span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav >
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

// export default connect(mapStateToProps,null,null,{forwardRef:true})(SignInModal);

export default connect(mapStateToProps)(withRouter(Navigation));
