import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
export class PrivilegesGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Fragment>
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-lg-10">
                        <h2>PRIVILEGES GROUP</h2>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <strong>Privileges Group</strong>
                            </li>
                        </ol>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
                <div className="wrapper wrapper-content animated fadeInRight">
                    <div className="row">
                        <div className="col-12">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <h5>Privileges Group</h5>
                                    <div className="ibox-tools">
                                        <Link to="/create_privilage" className="btn btn-primary btn-xs">Create Privilege Group</Link>
                                    </div>
                                </div>
                                <div className="ibox-content">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-responsive  table-striped dataTables-example">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Group Name</th>
                                                    <th style={{ width: '65%' }}>Privileges</th>
                                                    <th style={{ width: '14%' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>123</td>
                                                    <td>group_name</td>
                                                    <td>Privileges</td>
                                                    <td>
                                                        <Link className="btn btn-white btn-sm"
                                                            to={location => ({ ...location, pathname: "/create_privilage", state: { edit: true } })}>

                                                            <i className="fa fa-pencil-square"></i> Edit
                                                        </Link>&nbsp;&nbsp;&nbsp;
                                                        <a
                                                            className="btn btn-white btn-sm"
                                                            href=""
                                                        >
                                                            <i className="fa fa-trash"></i> Delete
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >

        )
    }
}

export default PrivilegesGroup