import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const getOtherAppCharges = (data = {}) => {
    return performRequest(
      apiEndPoints.methodType.POST,
      `${config.api_baseurl}getOtherAppCharges`,
      data
    );
};

export const UpdateOtherAppCharges = (data = {}) => {
    return performRequest(
      apiEndPoints.methodType.POST,
      `${config.api_baseurl}updateOtherAppCharges`,
      data
    );
};