import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const changePassword = (data = {}) => {
    return performRequest(
      apiEndPoints.methodType.POST,
      `${config.api_baseurl}users/change_password`,
      data
    );
};

export const RoleWiseChangePassword = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/change_user_password`,
    data
  );
};