import React from 'react'
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { formatDateAndTime } from 'utils';
import { userAgentType } from '_constants';
import InnerPageHeader from "../common/InnerPageHeader";

export default function LoginReportComp(props) {
    const history = useHistory();
    const { data, meta, onPageSelect, onTypeSelect, onSearchText, keyword } = props
    const { loginInfo } = data
    const { totalRecords, limit, currentPage, type } = meta
    const pageCount = Math.ceil(totalRecords / limit)

    return (
        <>
            <InnerPageHeader
                pageTitle="Login Report"
                breadcrumbs={[
                    { title: "Dashboard", url: "/" }
                ]}
                currenPageTitle="Login Reprot"
            />

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div cl assName="ibox float-e-margins">
                            <div className="ibox-title col-container mx-0 px-2 row">
                                <div className='col-lg-6 col-md-6 col-sm-4 col-xs-12'>
                                    <h5 style={{ fontWeight: 700 }}>All Users</h5>
                                </div>
                                <div className='col-lg-2 col-md-2 col-sm-2 col-xs-12 pt-1'>
                                    <Select
                                        options={userAgentType}
                                        value={userAgentType.filter((item) => item.value === type)[0]}
                                        onChange={(e) => { 
                                            if(type !== e.value){
                                                onTypeSelect(e.value); 
                                                onSearchText("")
                                            }
                                        }}
                                    />
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 pt-1'>
                                    <input
                                        style={{ height: "40px", borderColor: '#c7c7c7' }}
                                        type="search"
                                        className="form-control "
                                        placeholder="search..."
                                        aria-controls="DataTables_Table_0"
                                        value={keyword}
                                        onChange={onSearchText}
                                    />
                                </div>
                                
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <div
                                        id="DataTables_Table_0_wrapper"
                                        className="dataTables_wrapper form-inline dt-bootstrap"
                                    >
                                        <table className="table table-striped table-bordered table-hover dataTables-example">
                                            <thead>
                                                <tr>
                                                    <th>User Name</th>
                                                    <th>User Agent</th>
                                                    <th>Device Type</th>
                                                    <th>Login Time</th>
                                                    <th>IP Address</th>
                                                    {/* <th>ISP</th> */}
                                                    <th>Location</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loginInfo.map((item, ind) =>
                                                    <tr key={ind}>
                                                        <td>
                                                            <a
                                                                href="/"
                                                                onClick={(e) => {
                                                                    localStorage.setItem(
                                                                        "username",
                                                                        JSON.stringify(item.name)
                                                                    );
                                                                    e.preventDefault();
                                                                    history.push(
                                                                        `/userview/collection-report/${item.user_guid}/${item.username}`
                                                                    );
                                                                }}
                                                            >
                                                                {item.name} ( {item.username} )
                                                            </a>
                                                        </td>
                                                        <td> {item?.location?.user_agent ? item.location.user_agent : item.user_agent} </td>
                                                        <td> {item?.location?.platform ? item?.location?.platform : item.platform} </td>
                                                        <td>
                                                            {item.created_date
                                                                ? formatDateAndTime('MMM DD, hh:mm:ss A', item.created_date)
                                                                : "-"
                                                            }
                                                        </td>
                                                        <td> {item.ip_address} </td>
                                                        <td> {item?.location?.geo?.country ?
                                                            <>
                                                                <p>
                                                                    {item?.location?.geo?.country + " , " +
                                                                        item?.location?.geo?.regionName + " , " +
                                                                        item?.location?.geo?.city}
                                                                </p>
                                                                <p>{item?.location?.geo?.as}</p>
                                                            </>
                                                            : ''}</td>
                                                    </tr>)}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="paginate_class">
                                        <ReactPaginate
                                            initialPage={currentPage - 1}
                                            forcePage={currentPage - 1}
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={onPageSelect}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
