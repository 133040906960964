import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  staffUserCreate,
  updateStaffUser,
} from "../../_services/staff.services";
import { toast } from "react-toastify";
import Select from "react-select";
import { getFastMatkaCategory, getMatkaCategory } from "_services/matkaservice";

export default function Add(props) {
  const history = useHistory();
  const [formValue, setFormValue] = useState(null);
  const [matkaCategory, setMatkaCategory] = useState();
  const [fastMatkaCategory, setFastMatkaCategory] = useState();
  const isEdit = !!props?.stateData?.edit ? "Edit" : "Add";
  const onSubmit = (e) => {
    e.preventDefault();

    if (
      formValue?.username === "" ||
      !formValue?.password ||
      !(formValue?.matka_category || formValue?.fast_matka_category)
    ) {
      toast.error(
        <div>
          <i
            className="fa fa-times"
            style={{ fontWeight: "bold", marginRight: "1rem" }}
            aria-hidden="true"
          ></i>
          <span>All filds required</span>
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      // toast.error("All filds required");
    } else {
      let sendObj = { ...formValue };
      if (formValue?.matka_category) {
        sendObj["matka_category"] = formValue?.matka_category.map((item) => ({
          category_id: item?.category_id,
          title: item?.title,
        }));
      }
      if (formValue?.fast_matka_category) {
        sendObj["fast_matka_category"] = formValue?.fast_matka_category.map(
          (item) => ({
            category_id: item?.category_id,
            title: item?.title,
          })
        );
      }
      if (isEdit === "Edit") {
        updateStaffUser(sendObj)
          .then(() => {
            history.push("/staff_list");
          })
          .catch(() => {
            toast.error(
              <div>
                <i
                  className="fa fa-times"
                  style={{ fontWeight: "bold", marginRight: "1rem" }}
                  aria-hidden="true"
                ></i>
                <span>The username field must contain a unique value</span>
              </div>,
              {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
            // toast.error(" The username field must contain a unique value");
          });
      } else {
        staffUserCreate(sendObj)
          .then(() => {
            history.push("/staff_list");
          })
          .catch(() => {
            toast.error(
              <div>
                <i
                  className="fa fa-times"
                  style={{ fontWeight: "bold", marginRight: "1rem" }}
                  aria-hidden="true"
                ></i>
                <span>The username field must contain a unique value</span>
              </div>,
              {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
            // toast.error(" The username field must contain a unique value");
          });
      }
    }
  };

  useEffect(() => {
    setInitialData();
    fetchMatkaList();
    fetchFastMatkaList();
  }, []);

  const setInitialData = () => {
    if (props) {
      setFormValue({
        ...props?.stateData?.edit,
        matka_category: props?.stateData?.edit?.matka_category
          ? JSON.parse(props?.stateData?.edit?.matka_category).map((item) => ({
              ...item,
              label: item?.title,
              value: item?.category_id,
            }))
          : [],
        fast_matka_category: props?.stateData?.edit?.fast_matka_category
          ? JSON.parse(props?.stateData?.edit?.fast_matka_category).map(
              (item) => ({
                ...item,
                label: item?.title,
                value: item?.category_id,
              })
            )
          : [],
      });
    }
  };

  const fetchMatkaList = async () => {
    const res = await getMatkaCategory({
      current_page: 1,
    });

    if (res?.data?.records) {
      setMatkaCategory(
        res.data.records.map((item) => ({
          ...item,
          label: item?.title,
          value: item?.category_id,
        }))
      );
    } else {
      setMatkaCategory([]);
    }
  };

  const fetchFastMatkaList = async () => {
    const res = await getFastMatkaCategory({
      current_page: 1,
    });

    if (res?.data?.records) {
      setFastMatkaCategory(
        res.data.records.map((item) => ({
          ...item,
          label: item?.title,
          value: item?.category_id,
        }))
      );
    } else {
      setFastMatkaCategory([]);
    }
  };

  const onChangeHandle = (e) => {
    const { name, value } = e.target;
    setFormValue((s) => ({
      ...s,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2> {isEdit} Staff </h2>
          <ol className="breadcrumb">
            <Link to="/">Dashboard</Link>
            <li className="breadcrumb-item"></li>
            <li className="breadcrumb-item">
              <Link to="/staff_list">Staff List</Link>
            </li>
            <li className="breadcrumb-item active">
              <strong>{isEdit} Staff</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2"></div>
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <form autoComplete="off">
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          User Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            disabled={isEdit === "Edit"}
                            type="text"
                            name="username"
                            className="form-control"
                            placeholder="Enter User Name"
                            value={formValue?.username}
                            autoComplete="off"
                            onChange={(e) => onChangeHandle(e)}
                          />
                        </div>
                      </div>
                      <hr />

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          First Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="first_name"
                            className="form-control"
                            placeholder="Enter First Name"
                            value={formValue?.first_name}
                            autoComplete="off"
                            onChange={(e) => onChangeHandle(e)}
                          />
                        </div>
                      </div>
                      <hr />

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          {" "}
                          Last Name{" "}
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={formValue?.last_name}
                            autoComplete="off"
                            name="last_name"
                            placeholder="Enter Last Name"
                            onChange={(e) => onChangeHandle(e)}
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Matka{" "}
                        </label>
                        <div className="col-sm-10">
                          <Select
                            isMulti
                            options={matkaCategory}
                            value={formValue?.matka_category}
                            onChange={(data) =>
                              setFormValue((s) => ({
                                ...s,
                                matka_category: data,
                              }))
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          {" "}
                          Lottery{" "}
                        </label>
                        <div className="col-sm-10">
                          <Select
                            isMulti
                            options={fastMatkaCategory}
                            value={formValue?.fast_matka_category}
                            onChange={(data) =>
                              setFormValue((s) => ({
                                ...s,
                                fast_matka_category: data,
                              }))
                            }
                          />
                        </div>
                      </div>
                      <hr />

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Password
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="password"
                            className="form-control"
                            placeholder={
                              isEdit === "Add" ? "Enter password " : "*******"
                            }
                            value={formValue?.password}
                            autoComplete="off"
                            name="password"
                            onChange={(e) => onChangeHandle(e)}
                          />
                        </div>
                      </div>
                      <hr />
                    </form>
                    <div className="form-group row">
                      <div className="col-sm-4 col-sm-offset-2">
                        <Link
                          className="btn btn-white mr-3"
                          type="button"
                          style={{ marginRight: "1rem" }}
                          to="./staff_list"
                        >
                          Cancel
                        </Link>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={(e) => onSubmit(e)}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
