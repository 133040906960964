import { leagueConstants } from "../../_constants";
import { create, getLeagueList } from "../../_services";
import { toast } from "react-toastify";

export const listLeague = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: leagueConstants.LEAGUE_LIST_REQUEST, data: {} });
    getLeagueList(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: leagueConstants.LEAGUE_LIST_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: leagueConstants.LEAGUE_LIST_FAILED,
          data: error.response,
        });
      });
  };
};
