import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  casinoGamelist,
  pendingCasinolist,
  cancelCasinoGame,
} from "../../_services/casino.services";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { formatDateAndTime } from "../../utils/index";
import InnerPageHeader from "components/common/InnerPageHeader";
import { toast } from "react-toastify";
import ConfrimModal from "components/ConfirmModal";

export default function PendingGameComp() {
  const [offset, setOffset] = useState(1);
  const [allCasino, setAllCasino] = useState([]);
  const [perPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [showCancelModal, setShowCancelModal] = useState({});

  useEffect(() => {
    fatchCasinoList();
  }, [offset]);

  const fatchCasinoList = () => {
    let sendObj = {};
    sendObj.sport_id = 6;
    sendObj.current_page = offset;
    sendObj.items_perpage = perPage;

    pendingCasinolist(sendObj)
      .then((response) => {
        if (response && response?.data?.data?.records) {
          setAllCasino(response.data.data.records);

          setPageCount(Math.ceil(response.data.data.total_records / perPage));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };

  const submitCancel = () => {
    const sendData = {
      event_id: showCancelModal?.id,
    };
    setShowCancelModal((state) => ({ ...state, loading: true }));
    cancelCasinoGame(sendData)
      .then((response) => {
        if (response) {
          fatchCasinoList();
          setShowCancelModal(false);
        }
      })
      .catch((error) => {
        setShowCancelModal((state) => ({ ...state, loading: false }));
      });
  };

  return (
    <div>
      <InnerPageHeader
        pageTitle="Pending Casino Game List"
        breadcrumbs={[{ title: "Dashboard", url: "/" }]}
        currenPageTitle="Game List"
      />
      <div className="">
        <div className="px-2 mx-0 mt-3 row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-title">
                <div className="d-flex justify-between">
                  <div>
                    <h3>All Pending Casino Games</h3>
                  </div>
                </div>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>PID</th>
                            <th>Title</th>
                            <th>Sport</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allCasino?.map((element, index) => {
                            return (
                              <tr key={index}>
                                <td> {(offset - 1) * perPage + index + 1} </td>
                                <td> {element.casino_round_id} </td>

                                <td className="anchor">
                                  <Link
                                    to={{
                                      pathname: `/games_list/${element.event_id}`,
                                      state: { matchData: element },
                                    }}
                                  >
                                    <strong>
                                      {element.event_name} - {element.open_date}
                                    </strong>
                                  </Link>
                                </td>

                                <td> {element.game_code} </td>
                                <td>
                                  {element.open_date
                                    ? formatDateAndTime(
                                        "MMM DD, hh:mm:ss A",
                                        element.open_date
                                      )
                                    : "-"}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="action-btn mt-1 btn btn-danger"
                                    onClick={() =>
                                      setShowCancelModal({
                                        id: element?.event_id,
                                        title: element?.game_code
                                      })
                                    }
                                  >
                                    Cancel Game
                                  </button>{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="paginate_class">
                  <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
          {showCancelModal?.id && (
            <ConfrimModal
              isShow={Boolean(showCancelModal.id)}
              message={`Are you sure you want to Cancel ${showCancelModal?.title}` }
              onCancle={() => setShowCancelModal(false)}
              onSubmit={() => submitCancel()}
              loading={showCancelModal.loading}
            />
          )}
        </div>
      </div>
    </div>
  );
}
