import React from 'react';

const VideoModal = (props) => {
    const { url = "https://sqmr.xyz/nit.php?eventId=32106322", handleClose } = props
    return (
        <div className="video_modalNew" >
            <div className="container-fluid">
                <div className="row">
                    <div style={{ position: "relative" }} className="col-xs-12 col-md-12 col-lg-12 col-sm-12">
                        <iframe
                            src={url}
                            className='tvifram'
                        />
                        <div className='iframe_close' onClick={handleClose}>
                            <i className="fa fa-close" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default VideoModal
