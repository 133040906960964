import React, { Component } from "react";
import Main from "../../components/layouts/Main";
import NumberForm from "../../components/MatkaNumber/NumberForm";
import { connect } from "react-redux";

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addNumber = (data) => {};

  render() {
    return (
      <Main>
        <NumberForm
          {...this.props}
          type="fast"
          breadcrumbTitle={"Fast Matka Settlement"}
          addNumber={this.addNumber}
        />
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export const AddFastMatkaNumber = connect(mapstatetop)(Add);
