import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import  superApp from '../_reducers';
// const loggerMiddleware = createLogger();
export const store = createStore(
    superApp,
    applyMiddleware(
        thunkMiddleware,
        // loggerMiddleware
    )
);