import React, { Component } from "react";
import LoginComponent from '../../components/Login';
import { getStorage } from '../../utils';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
class Login extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var {user_id, role} = this.props.auth.userLogin;
        if(user_id && role >0 ){
            return <Redirect to={"/dashboard"} />
        }
        return (
            <>
                <LoginComponent />
            </>
        )
    }
}
const maptostateprops = state =>({
...state
})
export default connect(maptostateprops)(Login);