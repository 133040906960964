import React, { Component } from "react";
import { debounce } from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import ConfrimModal from "components/ConfirmModal"
import InnerPageHeader from "../common/InnerPageHeader";

class SingleReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 3,
      dataList: [],
      totalRecords: "",
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      selectedDate: new Date()
    };
  }

  debouncedLog = debounce((text) => this.fetchListData(text), 500);

  componentDidMount() {
    this.fetchListData();
  }

  fetchListData = (searchData = "") => {

  };

  getRecordDetails = () => {
    const { currentPage, totalRecords } = this.state;
    let startingCount =
      this.state.currentPage === 1
        ? 1
        : this.state.currentPage * this.state.perPage - 9;
    let endingCount =
      this.state.perPage < totalRecords
        ? this.state.currentPage === 1
          ? this.state.perPage
          : this.state.currentPage * 10
        : totalRecords;
    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      // this.fetchListData();
    });
  };

  submit = () => [
    this.setState({ isShowModal: false })
  ]


  render() {
    const {  isShowModal } = this.state;
    const { breadcrumbTitle } = this.props
    let listArra = Object.keys(this.props.dataList.sp)
    listArra.push((listArra.shift()))
    let listArra2 = ['Username', 'Name', 'Agent', 'Stockist']
    listArra = listArra2.concat(listArra)
    return (
      <>
        <InnerPageHeader
          pageTitle={breadcrumbTitle}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
          ]}
          currenPageTitle="List"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>{breadcrumbTitle}</h5>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <div className="d-flex justify-start">
                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <label>
                          Cutting Percentage (%): &nbsp;
                          <input
                            type="search"
                            className="form-control"
                            placeholder=""
                            aria-controls="DataTables_Table_0"
                            // value={this.state.keyword}
                            // onChange={this.handleChange}
                          />
                          </label>
                          <button className="btn btn-sm btn-white filter-button m-0"> Submit</button>
                        </div>
                      </div>
                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <tbody>
                          <tr className="heading-row firstRow" style={{ fontSize: '16px', color: '#ffffff' }} align="center">
                            <td className="fistcell" bgcolor="#1FABB5">Username</td>
                            <td bgcolor="#1FABB5">Name</td>
                            <td bgcolor="#1FABB5">Agent</td>
                            <td bgcolor="#1FABB5" className="agent-hide">Stockist</td>
                            <td bgcolor="#1FABB5">1</td>
                            <td bgcolor="#1FABB5">2</td>
                            <td bgcolor="#1FABB5">3</td>
                            <td bgcolor="#1FABB5">4</td>
                            <td bgcolor="#1FABB5">5</td>
                            <td bgcolor="#1FABB5">6</td>
                            <td bgcolor="#1FABB5">7</td>
                            <td bgcolor="#1FABB5">8</td>
                            <td bgcolor="#1FABB5">9</td>
                            <td bgcolor="#1FABB5">0</td>
                            <td bgcolor="#1FABB5">Amount</td>
                          </tr>
                          <tr>
                            <td className="fistcell bold-td" colSpan={4}>Total</td>
                            <td className=" ticket_amount" id="total_1" />
                            <td className=" ticket_amount" id="total_2" />
                            <td className=" ticket_amount" id="total_3" />
                            <td className=" ticket_amount" id="total_4" />
                            <td className=" ticket_amount" id="total_5" />
                            <td className=" ticket_amount" id="total_6" />
                            <td className=" ticket_amount" id="total_7" />
                            <td className=" ticket_amount" id="total_8" />
                            <td className=" ticket_amount" id="total_9" />
                            <td className=" ticket_amount" id="total_0" />
                            <td id="span-totalAmt" className="ticket_amount">0.00</td>
                          </tr>
                          <tr className="agent-hide">
                            <td className="fistcell bold-td" colSpan={4}>After Commission</td>
                            <td className=" ticket_amount" id="after_total_1" />
                            <td className=" ticket_amount" id="after_total_2" />
                            <td className=" ticket_amount" id="after_total_3" />
                            <td className=" ticket_amount" id="after_total_4" />
                            <td className=" ticket_amount" id="after_total_5" />
                            <td className=" ticket_amount" id="after_total_6" />
                            <td className=" ticket_amount" id="after_total_7" />
                            <td className=" ticket_amount" id="after_total_8" />
                            <td className=" ticket_amount" id="after_total_9" />
                            <td className=" ticket_amount" id="after_total_0" />
                            <td id="after-span-totalAmt" className=" ticket_amount">0.00</td>
                          </tr>
                          <tr className="heading-row firstRow" style={{ fontSize: '16px', color: '#ffffff' }} align="center">
                            <td className="fistcell" style={{ backgroundColor: '#1FABB5' }} />
                            <td style={{ backgroundColor: '#1FABB5' }} />
                            <td style={{ backgroundColor: '#1FABB5' }} className="agent-hide " />
                            <td style={{ backgroundColor: '#1FABB5' }} />
                            <td style={{ backgroundColor: '#1FABB5' }}>1</td>
                            <td style={{ backgroundColor: '#1FABB5' }}>2</td>
                            <td style={{ backgroundColor: '#1FABB5' }}>3</td>
                            <td style={{ backgroundColor: '#1FABB5' }}>4</td>
                            <td style={{ backgroundColor: '#1FABB5' }}>5</td>
                            <td style={{ backgroundColor: '#1FABB5' }}>6</td>
                            <td style={{ backgroundColor: '#1FABB5' }}>7</td>
                            <td style={{ backgroundColor: '#1FABB5' }}>8</td>
                            <td style={{ backgroundColor: '#1FABB5' }}>9</td>
                            <td style={{ backgroundColor: '#1FABB5' }}>0</td>
                            <td style={{ backgroundColor: '#1FABB5' }}>Amount</td>
                          </tr>
                          <tr className="agent-hide">
                            <td className="fistcell bold-td" colSpan={4}>Cutting Percentage</td>
                            <td className=" ticket_amount" id="extra_total_1" />
                            <td className=" ticket_amount" id="extra_total_2" />
                            <td className=" ticket_amount" id="extra_total_3" />
                            <td className=" ticket_amount" id="extra_total_4" />
                            <td className=" ticket_amount" id="extra_total_5" />
                            <td className=" ticket_amount" id="extra_total_6" />
                            <td className=" ticket_amount" id="extra_total_7" />
                            <td className=" ticket_amount" id="extra_total_8" />
                            <td className=" ticket_amount" id="extra_total_9" />
                            <td className=" ticket_amount" id="extra_total_0" />
                            <td id="extra-span-totalAmt" className=" ticket_amount">0.00</td>
                          </tr>
                          <tr className="agent-hide">
                            <td className="fistcell bold-td" colSpan={4}>After Total - / +</td>
                            <td className=" lowest-first  ticket_amount" id="win_total_1">0.00</td>
                            <td className=" lowest-first  ticket_amount" id="win_total_2">0.00</td>
                            <td className=" lowest-first  ticket_amount" id="win_total_3">0.00</td>
                            <td className=" lowest-first  ticket_amount" id="win_total_4">0.00</td>
                            <td className=" lowest-first  ticket_amount" id="win_total_5">0.00</td>
                            <td className=" lowest-first  ticket_amount" id="win_total_6">0.00</td>
                            <td className=" lowest-first  ticket_amount" id="win_total_7">0.00</td>
                            <td className=" lowest-first  ticket_amount" id="win_total_8">0.00</td>
                            <td className=" lowest-first  ticket_amount" id="win_total_9">0.00</td>
                            <td className=" lowest-first  ticket_amount" id="win_total_0">0.00</td>
                            <td id="win-span-totalAmt" className=" ticket_amount" />
                          </tr>
                          <tr className="agent-hide">
                            <td className="fistcell bold-td" colSpan={4}>Total - / +</td>
                            <td className=" ticket_amount" id="win_final_total_1" />
                            <td className=" ticket_amount" id="win_final_total_2" />
                            <td className=" ticket_amount" id="win_final_total_3" />
                            <td className=" ticket_amount" id="win_final_total_4" />
                            <td className=" ticket_amount" id="win_final_total_5" />
                            <td className=" ticket_amount" id="win_final_total_6" />
                            <td className=" ticket_amount" id="win_final_total_7" />
                            <td className=" ticket_amount" id="win_final_total_8" />
                            <td className=" ticket_amount" id="win_final_total_9" />
                            <td className=" lowest-first  ticket_amount" id="win_final_total_0">0.00</td>
                            <td id="win-span-totalAmt" className="ticket_amount" />
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isShowModal &&
              <ConfrimModal
                isShow={isShowModal}
                message={
                  <label>
                    Are you sure you want to delete{" "}
                  </label>
                }
                onCancle={() => this.setState({ isShowModal: false })}
                onSubmit={() => this.submit()}
              />}
          </div>
        </div>
      </>
    );
  }
}
export default SingleReportList;
