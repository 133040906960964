import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const activeDeactiveLeague = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}active_deactive_event`,
    data
  );
};

///// active_deactive_market ////////

export const editMarket = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}active_deactive_market`,
    data
  );
};

export const fancyType = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}fancy_type_list`,
    data
  );
};

export const deleteFancy = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}marketsarket/delete_fancy`,
    data
  );
};