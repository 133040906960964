import React, { useEffect, useState } from "react";
import "./Password.css";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { RoleWiseChangePassword } from "../../_services/password.services";

import InnerPageHeader from "components/common/InnerPageHeader";

export default function Password() {
  const history = useHistory();

  const location = useLocation();

  const user_guid = useParams();

  const [masterPassword, setMasterPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const handleInput = (e) => {
    let label = e.target.name;
    let value = e.target.value;
    if (label === "masterPassword") setMasterPassword(value);
    else if (label === "password") setPassword(value);
    else setconfirmPassword(value);
  };

  // console.log(password,"password")
  // console.log(masterPassword, "masterPassword")
  // console.log(confirmPassword, "confirmPassword")
  // console.log(user_guid, "user_guid")

  // useEffect( () => {
  //     onSubmit();
  // }, [])

  const onSubmit = () => {
    if (
      password != "" &&
      confirmPassword != "" &&
      password === confirmPassword
    ) {
      let sendData = {};
      // sendData.master_password = masterPassword;
      sendData.password = password;
      sendData.confirm_password = confirmPassword;
      sendData.user_guid = user_guid;

      RoleWiseChangePassword(sendData)
        .then((response) => {
          // toast.success("Password Change Successfully");

          toast.success(
            <div>
              <i
                className="fa fa-check"
                style={{ fontWeight: "bold", marginRight: "1rem" }}
                aria-hidden="true"
              ></i>
              <span>Password Change Successfully</span>
            </div>,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );

          setTimeout(() => {
            history.goBack();
          }, 2000);
        })
        .catch((error) => {
          toast.error("Failed , Password Not Change");
        });
    } else {
      toast.error("Please Enter Same New Password");

      setTimeout(() => {
        setMasterPassword("");
        setPassword("");
        setconfirmPassword("");
      }, 500);
    }
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="Change_Password"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          // { title: "Agent", url: "/agent"},
        ]}
        currenPageTitle={"Change_Password"}
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Change Password</h5>
              </div>
              <div
                className="ibox-content"
                style={{ padding: "15px 20px 56px" }}
              >
                <div className="row">
                  <div className="col-lg-12">
                    <form>
                      
                      <div className="form-group row">
                        <label
                          htmlFor="inputpassword"
                          className="col-sm-2 col-form-label"
                        >
                          {" "}
                          PASSWORD &nbsp;
                          <span
                            className="d-inline-block"
                            data-toggle="tooltip"
                            title="New password should contain - atleast one number, one lowercase letter, one uppercase letter, length should be atleast 8 characters"
                          >
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="inputpassword"
                            placeholder="NEW PASSWORD"
                            value={password}
                            onChange={(e) => handleInput(e)}
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="form-group row">
                        <label
                          htmlFor="inputconfirmPassword"
                          className="col-sm-2 col-form-label"
                        >
                          CONFIRM PASSWORD
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="password"
                            className="form-control"
                            id="inputconfirmPassword"
                            placeholder="CONFIRM PASSWORD"
                            value={confirmPassword}
                            // inValid={password !== confirmPassword}
                            onChange={(e) => handleInput(e)}
                          />
                        </div>
                      </div>
                    </form>
                    <div className="form-group row">
                      <div className="col-sm-4 col-sm-offset-2">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => onSubmit(e)}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
