import React, { Component, Fragment } from "react";
import Select from "react-select";
import "./ManageLedger.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import Pagination from "../common/Pagination";
import { Link } from "react-router-dom";
export class SuperNowaPL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // from_date: moment().subtract(6, 'days'),
      // to_date: moment(),
      from_date: null,
      to_date: null,
      selectedSport: "",
      selectedSportOptions: [
        { label: "cricket", value: 0 },
        { label: "football", value: 1 },
        { label: "soccer", value: 2 },
      ],
      totalRecords: 5,
    };
  }
  handleCallback = (from_date, to_date) => {
    this.setState({ from_date, to_date });
  };

  handleChange = (selectedSport) => {
    this.setState({ selectedSport });
  };

  render() {
    const {
      selectedSport,
      selectedSportOptions,
      to_date,
      from_date,
      totalRecords,
    } = this.state;
    return (
      <Fragment>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Super Nowa Profit Loss</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <strong>Super Nowa Profit Loss</strong>
              </li>
            </ol>
          </div>
          <div className="col-lg-2"></div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12">
            <Select
              options={selectedSportOptions}
              onChange={this.handleChange}
              value={selectedSport ? selectedSport : ""}
              className="reactSelect"
              placeholder=""
            />
          </div>
          <DateRangePicker
            initialSettings={{
              // startDate: from_date.toDate(),
              // endDate: to_date.toDate(),
              startDate: from_date
                ? from_date.toDate()
                : moment().subtract(6, "days"),
              endDate: to_date ? to_date.toDate() : moment(),
              ranges: {
                Today: [moment().toDate(), moment().toDate()],
                Yesterday: [
                  moment().subtract(1, "days").toDate(),
                  moment().subtract(1, "days").toDate(),
                ],
                "Last 7 Days": [
                  moment().subtract(6, "days").toDate(),
                  moment().toDate(),
                ],
                "This Week": [
                  moment().startOf("isoWeek").toDate(),
                  moment().toDate(),
                ],
                "Last Week": [
                  moment().subtract(1, "week").startOf("isoWeek").toDate(),
                  moment().subtract(1, "week").endOf("isoWeek").toDate(),
                ],
                "Last 30 Days": [
                  moment().subtract(29, "days").toDate(),
                  moment().toDate(),
                ],
                "This Month": [
                  moment().startOf("month").toDate(),
                  moment().toDate(),
                ],
                "Last Month": [
                  moment().subtract(1, "month").startOf("month").toDate(),
                  moment().subtract(1, "month").endOf("month").toDate(),
                ],
              },
            }}
            onCallback={this.handleCallback}
          >
            <div
              id="reportrange"
              className="col-lg-3 col-md-3 col-sm-9 mlDatePicker"
            >
              <span style={{ float: "right" }}>
                <i
                  className={"glyphicon glyphicon-calendar  fa fa-calendar"}
                ></i>
                &nbsp;&nbsp;
                <span className="text-right">
                  {" "}
                  {from_date && to_date
                    ? `${from_date.format("DD MMM, YYYY")} - ${to_date.format(
                        "DD MMM, YYYY"
                      )}`
                    : "-"}
                </span>
                &nbsp;&nbsp;
                <i class="fa fa-caret-down" aria-hidden="true"></i>
              </span>
            </div>
          </DateRangePicker>
          <div
            className="col-lg-3 col-md-4 col-sm-6 col-sm-3"
            style={{ margin: "11px 0px" }}
          >
            <a className="btn btn-primary btn-xs search_date_btn">Search</a>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>Super Nowa Profit Loss</h5>
                  <div className="ibox-tools">
                    <a
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      aria-expanded="true"
                    >
                      <i className="fa fa-wrench"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                      <li>
                        <a
                          target="_blank"
                          href="/agent/agents/3116/ledger_show_pdf.pdf"
                        >
                          Export PDF
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered table-hover dataTables-example">
                          <thead>
                            <tr>
                              <th>DATE/TIME</th>
                              <th>Round Id</th>
                              <th>Game Title</th>
                              <th>Super Nowa Share</th>
                              <th>Company Share</th>
                              <th>Total Earnings</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>11-04-2022</td>
                              <td>123</td>
                              <td>Durham vs Leicestershire</td>
                              <td>23702.77</td>
                              <td>0.0</td>
                              <td>975.0</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <Pagination
                        totalRecords={totalRecords}
                        postion={"left"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SuperNowaPL;
