import React from "react";
import { Button, Modal } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";

const ConfrimModal = (props) => {
  const {
    isShow = false,
    title = "Confrim Message",
    message,
    onCancle,
    onSubmit,
    loading = false,
    footerButton,
  } = props;

  return (
    <Modal
      show={isShow}
      onHide={() => !loading && onCancle()}
      style={{ opacity: 1 }}
    >
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body>
        <div className="form-group">{message}</div>
      </Modal.Body>
      <Modal.Footer>
        {footerButton ? (
          footerButton
        ) : (
          <>
            <Button onClick={(e) => !loading && onCancle(e)}>No</Button>
            <Button onClick={() => !loading && onSubmit()}>
              {loading ? (
                <ClipLoader color={"#FFFFFF"} loading={loading} size={16} />
              ) : (
                "Yes"
              )}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfrimModal;
