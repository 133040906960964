import React, { Component } from 'react'
import { connect } from 'react-redux';
import Main from "../../components/layouts/Main";
import Agents from "../../components/Agent/List";

export class BlockedSubAgent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Main>
                <Agents {...this.props} isBlocked={true} title="Agents Blocked List" role={[3, 4, 5, 6]} />
            </Main>

        )
    }
}

const mapstatetop = (state) => ({
    ...state,
});

export default connect(mapstatetop)(BlockedSubAgent);