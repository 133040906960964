import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ExpandableTr from "./ExpandableTr";

function Table(props) {
  const [Show, setShow] = useState(false);
  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              {props.Headers && props.Headers.map((header, Unic) => {
                return(
                  <th key={Unic}>{header}</th>
                )
              } 
              )}
            </tr>
          </thead>
          <tbody>
            {props.Data &&
              props.Data.map((data, idx) => {
                return(
                    <ExpandableTr
                      data={data}
                      key={idx}
                      idx={idx}
                      handleSubTable={props.handleSubTable}
                      subTableMarketUrl={props.subTableMarketUrl}
                      ActiveTabName={props.ActiveTabName}
                    />
                    // <>
                    //     <tr className="gradeC">
                    //         <td>{idx+1}</td>
                    //         <td>{data.league_id}</td>
                    //         <td>{data.league_name}</td>
                    //         <td>
                    //             <Button onClick={() => setShow(prev => !prev)} ><i className='fa-plus-square-o'/></Button>
                    //         </td>
                    //     </tr>
                    //     {
                    //     Show &&
    
                    //     <div>
                    //         this is the collapsable div
                    //     </div>
    
                    //     }
    
                    // </>
                )
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Table;
