import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const getAllSports = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_all_sports`,
    data
  );
};

export const getActiveEventList = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}active_event_list`,
    data
  );
};

export const getMatchRollBack = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}settled_match_list`,
    data
  );
};

export const getMatchResult = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}market_settlement_list`,
    data
  );
};

export const getManageFancy = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}fancy_settlement_list`,
    data
  );
};

export const updateSettleFancy = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}settlement/settle_fancy`,
    data
  );
};

export const rollbackMatch = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}rollback_settlement`,
    data
  );
};

export const blockedUser = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/blocked_user`,
    data
  );
};
