export const reportData = {
    "result": [],
    "sp":
    {
        "1": ["128", "290", "579", "137", "380", "678", "146", "470", "236", "489", "245", "560"],
        "2": ["129", "246", "589", "138", "345", "679", "147", "390", "156", "480", "237", "570"],
        "3": ["120", "247", "670", "139", "256", "689", "148", "346", "157", "490", "238", "580"],
        "4": ["130", "248", "680", "149", "257", "789", "158", "347", "167", "356", "239", "590"],
        "5": ["140", "258", "690", "159", "267", "780", "168", "348", "230", "357", "249", "456"],
        "6": ["123", "259", "457", "150", "268", "790", "169", "349", "178", "358", "240", "367"],
        "7": ["124", "278", "467", "160", "340", "890", "179", "359", "250", "368", "269", "458"],
        "8": ["125", "279", "468", "134", "350", "567", "170", "369", "189", "378", "260", "459"],
        "9": ["126", "289", "478", "135", "360", "568", "180", "379", "270", "450", "234", "469"],
        "0": ["127", "235", "569", "136", "370", "578", "145", "389", "190", "460", "280", "479"]
    },
    "lowestQtyArray": []
}