import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../_actions";
import SimpleReactValidator from "simple-react-validator";
import { toast, ToastContainer } from "react-toastify";
// import { Callbacks } from 'jquery';
import { Redirect } from "react-router-dom";
import { getUserLocation } from "_services";

class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      formObj: {
        username: "",
        password: "",
        location: {}
      },
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.getUserLocationDetails()
  };

  setSignInModal = (state) => {
    this.setState({ showModel: state });
  };

  handleChange = (e) => {
    var { formObj } = this.state;
    formObj[e.target.name] = e.target.value;
    this.setState({ formObj });
  };

  onSubmit = () => {
    if (this.validator.allValid()) {
      let { redirect, formObj } = this.state;
      this.setState({ isLoader: true });
      this.props.dispatch(
        loginUser(formObj, (result) => {
          if (result) {
            this.setState({ redirect: true , role:result?.role});
          } else {
            this.setState({ redirect: false });
            // toast("Authentication failed!");
          }
        })
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };


  getUserLocationDetails() {
    getUserLocation().then((response) => {
      if (response) {
        let { formObj } = this.state;
        formObj['location'] = response;
        this.setState({ formObj });
      }
    })
  }
  render() {
    let { redirect, role } = this.state;
    if (redirect) {
      return <Redirect to={"/dashboard"} />;
    }
    return (
      <div className="middle-box text-center loginscreen animated fadeInDown" onKeyPress={(e) => e.key === 'Enter' && this.onSubmit()}>
        <div>
          <div>
            <h1 className="logo-name"><img src="/img/7daysmatka-logo.png" alt="logo" width={"335px"}/></h1>
          </div>
          {/* <h3>Welcome to 7Day Matka</h3> */}
          <p>Sign In</p>
          <form className="m-t">
            <div className="form-group">
              <input
                type="text"
                name="username"
                id="username"
                className="form-control"
                placeholder="Username"
                required
                onChange={(e) => this.handleChange(e)}
              />
              <em>
                {this.validator.message(
                  "username",
                  this.state.formObj.username,
                  "required"
                )}
              </em>
            </div>
            <div className="form-group">
              <input
                type="password"
                name="password"
                id="password"
                className="form-control"
                placeholder="Password"
                required
                onChange={(e) => this.handleChange(e)}
              />
              <em>
                {this.validator.message(
                  "password",
                  this.state.formObj.password,
                  "required"
                )}
              </em>
            </div>
            <button
              type="button"
              onClick={() => this.onSubmit()}
              className="btn btn-primary block full-width m-b"
            >
              Login
            </button>
          </form>
          <p className="m-t">
            {" "}
            <small> 7DayMatka © 2022</small>{" "}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(LoginComponent);
