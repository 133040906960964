import React, { Component } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import { Link, useHistory } from "react-router-dom";
import Pagination from "../common/Pagination";
import { listRequest } from "../../_actions/master/master.actions";
import { betLockUnLock, lockedUserList, casinolockUnlock, activeInactiveUsers } from "../../_services/master.services"
import { debounce } from "lodash";
import MatchesList from "pages/Sports/MatchesList/MatchesList";
import locked from "../../images/locked.png"
import unlocked from "../../images/unlocked.png"

import ReactPaginate from 'react-paginate';

class List extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    lockUserList: [],
    keyword: "",

    currentPage: 1,
    perPage: 100,
    pageCount: 0,
    totalRecords: 0,
  };

  componentDidMount() {
    this.fatchLockUserList(this.state.keyword);
  }

  fatchLockUserList = () => {
    let send = {
      user_guid: "",
      current_page: this.state.currentPage,
      items_perpage: this.state.perPage,
      keyword: this.state.keyword,
      role: 0,
      option: 0,
    }

    lockedUserList(send)
      .then((response) => {

        if (response && response?.data?.data?.userdownlist) {
          this.setState({ lockUserList: response.data.data.userdownlist })

          this.setState({ totalRecords: response.data.data.total_records })

        }

        this.setState({ pageCount: Math.ceil(response.data.data.total_records / this.state.perPage) })

      }).catch((error) => {
        console.log(error)
      })
  }

  getRecordDetails = () => {
    const { currentPage, totalRecords } = this.state;
    let startingCount = this.state.currentPage === 1 ? 1 : this.state.currentPage * this.state.perPage - 9;
    let endingCount = (this.state.perPage < totalRecords) ? (this.state.currentPage === 1 ? this.state.perPage : this.state.currentPage * 10) : totalRecords;
    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState({ currentPage: (selectedPage + 1) }, () => {
      this.fatchLockUserList(this.state.keyword);
    })
  };

  statusChange = (confirmEvent, user, ind) => {

    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.status = (user.status == '0' ? 1 : 0);

    activeInactiveUsers(sendData)
      .then((response) => {

        if (response) {
          let send = {
            user_guid: "",
            current_page: this.state.currentPage,
            items_perpage: this.state.perPage,
            keyword: this.state.keyword,
            role: 0,
            option: 0,
          }

          lockedUserList(send)
            .then((response) => {

              if (response && response?.data?.data?.userdownlist) {
                this.setState({ lockUserList: response.data.data.userdownlist })
              }
            }).catch((error) => {
              console.log(error)
            })
        }

      }).catch((error) => {
        console.log(error)
      })
  }

  lockUnlockBet = (event, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.lock_betting = (user.lock_betting == '0' ? 1 : 0);

    betLockUnLock(sendData)
      .then((response) => {

        if (response) {
          let send = {
            user_guid: "",
            current_page: this.state.currentPage,
            items_perpage: this.state.perPage,
            keyword: this.state.keyword,
            role: 3,
            option: 0,
          }

          lockedUserList(send)
            .then((response) => {

              if (response && response?.data?.data?.userdownlist) {
                this.setState({ lockUserList: response.data.data.userdownlist })
              }
            }).catch((error) => {
              console.log(error)
            })
        }

      }).catch((error) => {
        console.log(error)
      })
  }

  lockUnlockCasino(event, user, ind) {

    if (event) {
      let sendData = {};
      sendData.user_guid = user.user_guid;
      sendData.lock_casino_bet = user.lock_casino_bet == '0' ? 1 : 0;
      casinolockUnlock(sendData)
        .then((response) => {

          if (response) {
            let send = {
              user_guid: "",
              current_page: this.state.currentPage,
              items_perpage: this.state.perPage,
              keyword: this.state.keyword,
              role: 3,
              option: 0,
            }

            lockedUserList(send)
              .then((response) => {

                if (response && response?.data?.data?.userdownlist) {
                  this.setState({ lockUserList: response.data.data.userdownlist })
                }
              }).catch((error) => {
                console.log(error)
              })
          }

        }).catch((error) => {
          console.log(error)
        })

    }
  }

  render() {
    const { masterList, totalRecords, lockUserList } = this.state;

    return (
      <>
        <InnerPageHeader
          pageTitle="Locked User"
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Locked User", url: "/locked_users" },
          ]}
          currenPageTitle="List"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>All Locked User</h5>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <div className="dt-buttons btn-group">
                        <a
                          className="dt-button buttons-csv buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                          style={{ marginRight: "0.5rem" }}
                        >
                          <span>CSV</span>
                        </a>
                        <a
                          className="dt-button buttons-pdf buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                        >
                          <span>PDF</span>
                        </a>
                      </div>

                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          Search:
                          <input
                            type="search"
                            className="form-control input-sm"
                            placeholder=""
                            aria-controls="DataTables_Table_0"
                            value={this.state.keyword}
                          // onChange={this.handleChange}
                          />
                        </label>
                      </div>

                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>User Name</th>
                            <th>Name</th>
                            <th>Fix Limit</th>
                            <th>My Share</th>
                            <th>Max Share</th>
                            <th>User Active/Inactive</th>
                            <th>Bet Lock/Unlock</th>
                            <th>Casino Lock/UnLock</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lockUserList && lockUserList.length ? (
                            lockUserList.map((user, index) => (
                              <tr className="gradeX" key={index}>
                                <td>{user.agent_id}</td>
                                <td>
                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.history.push(
                                        `/userview/master/${user.user_guid}/${user.username}`
                                      );
                                    }}
                                  >
                                    {user.username}
                                  </a>
                                </td>
                                <td>
                                  {user.first_name} {user.last_name}
                                </td>
                                <td className="center">{user.fixed_limit}</td>
                                <td className="center">{user.my_share}</td>
                                <td className="center">
                                  {user.my_agent_share}
                                </td>

                                <td className="text-center">
                                  <a onClick={(e) => this.statusChange(e, user, index)}>
                                    <img src={user.status == "1" ? unlocked : locked} />
                                  </a>
                                </td>

                                <td className="text-center">
                                  <a onClick={(e) => this.lockUnlockBet(e, user, index)}>
                                    <img src={user.lock_betting == "0" ? unlocked : locked} />
                                  </a>
                                </td>

                                <td className="text-center">
                                  <a onClick={(e) => this.lockUnlockCasino(e, user, index)}>
                                    <img src={user.lock_casino_bet == "0" ? unlocked : locked} />
                                  </a>
                                </td>

                              </tr>
                            ))
                          ) : (
                            <tr className="gradeX">
                              <td colSpan={7}>No data available in table</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="col-xs-12 col-sm-6">
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            {totalRecords
                              ? this.getRecordDetails()
                              : "Showing 0 to 0 of 0 entries"}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="paginate_class">
                            <ReactPaginate
                              previousLabel={"prev"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"} />
                          </div>
                        </div>
                        <div className="clearfix visible-xs"></div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default List;

