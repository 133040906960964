import React, { Component, Fragment } from 'react'
import Pagination from "../common/Pagination";
import './Client.css';
import { Link, withRouter } from "react-router-dom";
import { blockedUser } from '_services/settlements.services';
import ReactPaginate from "react-paginate";

import { debounce } from "lodash";

export class Blocked extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blockedUsers: [],
            totalRecords: 0,
            currentPage: 1,
            perPage: 10
        }
    }
    debouncedLog = debounce((text) => this.fetchListData(text), 500); 

    componentDidMount() {
        this.fetchListData()
    }

    fetchListData = () => {
        let sendData = {};
        sendData.user_guid = '';
        sendData.current_page = this.state.currentPage;
        sendData.items_perpage = this.state.perPage;
        sendData.keyword = this.state.keyword
        sendData.role = this.props.role


        blockedUser(sendData)
            .then((response) => {
                this.setState({ 
                    blockedUsers: response?.data?.data?.userdownlist, 
                    totalRecords: response?.data?.data?.total_records
                })

            })
            .catch((error) => {
                this.setState({ blockedUsers: [] })
            });
    }

    handleChange = (e) => {
        this.setState({ keyword: e.target.value });
        this.debouncedLog(e.target.value);
    };

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        this.setState({ currentPage: selectedPage + 1 }, () => {
          this.fetchListData(this.state.keyword);
        });
    };

    render() {
        const { blockedUsers, totalRecords, perPage, currentPage} = this.state;
        return (
            <Fragment>
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-lg-10">
                        <h2>{this.props.title}</h2>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <strong>{this.props.title}</strong>
                            </li>
                        </ol>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
                <div className="wrapper wrapper-content animated fadeInRight">
                    <div className="row">
                        <div className="col-12">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <h5>Blocked Users</h5>
                                </div>
                                <div className="ibox-content">
                                    <div className="table-responsive">
                                        <div
                                            id="DataTables_Table_0_wrapper"
                                            className="dataTables_wrapper form-inline dt-bootstrap"
                                        >
                                            <div className="dt-buttons btn-group">
                                                <a
                                                    className="dt-button buttons-csv buttons-html5"
                                                    tabIndex="0"
                                                    aria-controls="DataTables_Table_0"
                                                    href="#"
                                                    style={{ marginRight: "0.5rem" }}
                                                >
                                                    <span>CSV</span>
                                                </a>
                                                <a
                                                    className="dt-button buttons-pdf buttons-html5"
                                                    tabIndex="0"
                                                    aria-controls="DataTables_Table_0"
                                                    href="#"
                                                >
                                                    <span>PDF</span>
                                                </a>
                                            </div>

                                            <div
                                                id="DataTables_Table_0_filter"
                                                className="dataTables_filter"
                                            >
                                                <label>
                                                    Search:
                                                    <input
                                                        type="search"
                                                        className="form-control input-sm"
                                                        placeholder=""
                                                        aria-controls="DataTables_Table_0"
                                                        value={this.state.keyword}
                                                        onChange={this.handleChange}
                                                    />
                                                </label>
                                            </div>

                                            <table className="table table-striped table-bordered table-hover dataTables-example">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>User Name</th>
                                                        <th>Name</th>
                                                        <th>Match Commission</th>
                                                        <th>Session Commission</th>
                                                        <th>Share</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {blockedUsers && blockedUsers.length ? (
                                                        blockedUsers.map((user, index) => (
                                                            <tr className="gradeX" key={index}>
                                                                <td>{(index + 1) + ((currentPage - 1) * perPage)}</td>
                                                                <td>
                                                                    <a href={"/userview/client/" + user.user_guid + '/' + user.username}>
                                                                        {user.username}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    {user.first_name} {user.last_name}
                                                                </td>
                                                                <td className="center">
                                                                    {user.match_commission}
                                                                </td>
                                                                <td className="center">
                                                                    {user.session_commission}
                                                                </td>
                                                                <td className="center">{user.my_share}</td>
                                                                <td className="">
                                                                    <Link
                                                                        className="btn btn-white btn-sm"
                                                                        to={{ pathname: "/my-clients/edit/" + user.user_guid, state: { selectedData: user, lastPath: this.props.location?.pathname } }}
                                                                    >
                                                                        <i className="fa fa-pencil-square"></i> Edit
                                                                    </Link>
                                                                    &nbsp;&nbsp; &nbsp;&nbsp;
                                                                    {/* <a href={"/my-clients/edit_password/"+user.user_guid}>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-w-m btn-primary"
                                                                        >
                                                                            Change Password
                                                                        </button>
                                                                    </a> */}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr className="gradeX">
                                                            <td colSpan={7}>No data available in table</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>

                                            <div className="row" style={{ margin: "15px 0px" }}>
                                                <div className="col-xs-12 col-sm-6">
                                                    <div
                                                        className="dataTables_info"
                                                        id="DataTables_Table_0_info"
                                                        role="status"
                                                        aria-live="polite"
                                                    >
                                                        {/* {totalRecords
                                                            ? this.getRecordDetails()
                                                            : "Showing 0 to 0 of 0 entries"} */}
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-6">
                                                    <div className="paginate_class">
                                                        <ReactPaginate
                                                            previousLabel={"prev"}
                                                            nextLabel={"next"}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={Math.ceil(totalRecords / this.state.perPage)}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={this.handlePageClick}
                                                            containerClassName={"pagination"}
                                                            subContainerClassName={"pages pagination"}
                                                            activeClassName={"active"}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="clearfix visible-xs"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(Blocked)