import React, { useState, useEffect } from 'react'
import InnerPageHeader from "../common/InnerPageHeader";
import { getOtherAppCharges } from "_services/othercharges.services"
import { UpdateOtherAppCharges } from "_services/othercharges.services"
import { toast } from "react-toastify";

function OtherChargesCmp() {

    const [otherChargesData, setotherChargesData] = useState({
        id: '',
        other_charges: '',
    })

    useEffect(() => {
        getOtherChargesFunction();
    }, [])

    const getOtherChargesFunction = () => {
        let sendData = {}
        getOtherAppCharges(sendData)
            .then((res) => {
                setotherChargesData(res?.data?.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    const handleInput = (e) => {
        setotherChargesData({
            ...otherChargesData,
            [e.target.name]: e.target.value
        })
    }

    var onFormSubmit = (e) => {
        e.preventDefault();

        let sendData = {
            id : otherChargesData.id,
            other_charges: otherChargesData.other_charges
        }
        UpdateOtherAppCharges(sendData)
            .then((res) => {
                // toast("Other Charges Data Updated");
                toast.success(
                    <div>
                      <i
                        className="fa fa-check"
                        style={{ fontWeight: "bold", marginRight: "1rem" }}
                        aria-hidden="true"
                      ></i>
                      <span>Other Charges Data Updated</span>
                    </div>,
                    {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: false,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    }
                  );
            }).catch((err) => {
                toast.error("Not Working , Other charges Data Not Updated");
            })
    }

    return (
        <>
            <InnerPageHeader
                pageTitle="Other Charges"
                breadcrumbs={[
                    { title: "Dashboard", url: "/" },
                ]}
                currenPageTitle="Other Charges"
            />
            
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-content">
                                <form className="form-horizontal">
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label"> Update </label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="other_charges"
                                                value={otherChargesData.other_charges}
                                                onChange={(e) => handleInput(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="hr-line-dashed"></div>

                                    <div className="form-group">
                                        <div className="col-sm-4 col-sm-offset-2" style={{ marginLeft: "391px" }}>
                                            <button className="btn btn-primary" onClick={(e) => onFormSubmit(e)}>
                                                Update Other Charges
                                        </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OtherChargesCmp


// getOtherAppCharges

// updateOtherAppCharges