import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import config from "../../lib/app-config";
import { useDispatch } from "react-redux";
import { authConstants } from "_constants";

const WebSocketContext = React.createContext();

const WebSocketProvider = WebSocketContext.Provider;
const WebSocketConsumer = WebSocketContext.Consumer;

const WebSocketContextComponent = ({ children }) => {
  const [socketData, setSocketData] = useState("");
  const dispatch = useDispatch();
  let socket = null;
  useEffect(() => {
    // socket = io(config.socketurl, {
    //   reconnectionDelayMax: 100000,
    //   extraHeaders: {},
    // });

    // const handleConnected = (...args) => {
    //   if (args && args.length) {
    //     const dt = args[0];
    //     dispatch({ type: "SOCKET_DATA", dt });
    //     setSocketData(args[0]);
    //   } else {
    //     setSocketData("");
    //   }
    // };
    // socket.on("connect", () => {
    //   console.log("socket connected", socket.connected); // true
    // });
    // socket.on("updateMarketOdds", handleupdateMarketOdds);
    // socket.on("updateFancyOdds", handleupdateFancyOdds);
    // socket.on("addUpdateFancy", handleaddUpdateFancy);
    // socket.on("updateScore", handleupdateScore);
    // socket.on("bet_placed", handlebet_placed);
    // socket.on("updateCommentary", handleUpdateCommentary);
    // socket.on("updateUserLockStatus", handleUpdateUserLockStatus);

    // return () => {
      

    //   socket.off("updateMarketOdds", handleupdateMarketOdds);
    //   socket.off("updateFancyOdds", handleupdateFancyOdds);
    //   socket.off("addUpdateFancy", handleaddUpdateFancy);
    //   socket.off("updateScore", handleupdateScore);
    //   socket.off("bet_placed", handlebet_placed);
    //   socket.off("updateCommentary", handleUpdateCommentary);
    //   socket.off("updateUserLockStatus", handleUpdateUserLockStatus);
    // };
  }, []);

  // const handleupdateMarketOdds = (payload) => {
  //   if (payload) {
  //     dispatch({
  //       type: "SOCKET_DATA",
  //       payload: { updateMarketOdds: payload, socket },
  //     });
  //   }
  // };

  // const handleupdateFancyOdds = (payload) => {
  //   if (payload) {
  //     dispatch({
  //       type: "SOCKET_DATA",
  //       payload: { updateFancyOdds: payload, socket },
  //     });
  //   }
  // };

  // const handleaddUpdateFancy = (payload) => {
  //   if (payload) {
  //     dispatch({
  //       type: "SOCKET_DATA",
  //       payload: { addUpdateFancy: payload, socket },
  //     });
  //   }
  // };

  // const handleupdateScore = (payload) => {
  //   if (payload) {
  //     dispatch({
  //       type: "SOCKET_DATA",
  //       payload: { updateScore: payload, socket },
  //     });
  //   }
  // };

  // const handlebet_placed = (payload) => {
  //   if (payload) {
  //     dispatch({
  //       type: "SOCKET_DATA",
  //       payload: { bet_placed: payload, socket },
  //     });
  //   }
  // };

  // const handleUpdateCommentary = (payload) => {
  //   if (payload) {
  //     dispatch({
  //       type: "SOCKET_DATA",
  //       payload: { updateCommentary: payload, socket },
  //     });
  //   }
  // };

  // const handleUpdateUserLockStatus = (payload) => {
  //   if (payload) {
  //     dispatch({
  //       type: "SOCKET_DATA",
  //       payload: { updateUserLockStatus: payload, socket },
  //     });
  //   }
  // };

  // const clearSocketData = () => {
  //   setSocketData("");
  // };

  return (
    <></>
    // <WebSocketProvider
    //   value={{ socketData, clearSocketData }}
    // >
    //   {children}
    // </WebSocketProvider>
  );
};

export { WebSocketProvider, WebSocketConsumer, WebSocketContextComponent };
