import moment from 'moment-timezone';

export function formatDateAndTime(dateFormat,date) {
  const date_time = moment.utc(date).local().format(dateFormat);
  return date_time;
}

export function getStorage(key, type, defaultValue) {
  let data = localStorage.getItem(key);
  if (data) {
    switch (type) {
      case 'array':
      case 'object':
        try { data = JSON.parse(data) }
        catch (e) { data = defaultValue }
        break;
      case 'string':
        break;
      default:
        data = defaultValue;
        break;
    }
  } else {
    data = defaultValue;
  }
  return data;
}

/**
 * Sorts an array of objects by column/property.
 * @param {Array} array - The array of objects.
 * @param {object} sortObject - The object that contains the sort order keys with directions (asc/desc). e.g. { age: 'desc', name: 'asc' }
 * @returns {Array} The sorted array.
 */
export function multiSort(array, sortObject = {}) {
  const sortKeys = Object.keys(sortObject);
  if(!array){
    return [];
  }
  // Return array if no sort object is supplied.
  if (!sortKeys.length) {
      return array;
  }

  // Change the values of the sortObject keys to -1, 0, or 1.
  for (let key in sortObject) {
      sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1 : (sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1);
  }

  const keySort = (a, b, direction) => {
      direction = direction !== null ? direction : 1;

      if (a === b) { // If the values are the same, do not switch positions.
          return 0;
      }

      // If b > a, multiply by -1 to get the reverse direction.
      return a > b ? direction : -1 * direction;
  };

  return array.sort((a, b) => {
      let sorted = 0;
      let index = 0;

      // Loop until sorted (-1 or 1) or until the sort keys have been processed.
      while (sorted === 0 && index < sortKeys.length) {
          const key = sortKeys[index];

          if (key) {
              const direction = sortObject[key];

              sorted = keySort(a[key], b[key], direction);
              index++;
          }
      }

      return sorted;
  });
}
export function getDateAndTime(dateFormat,date) {
  let digiConfig = getStorage("configuration","object",{});  
  let date_time = moment(date).tz(digiConfig.timezone).format(dateFormat)
  return date_time;
}

export const t = textKey => {
  const langCode = (localStorage.getItem('language'))?localStorage.getItem('language'):"en";
  var translationData = [];
  try { translationData = JSON.parse(localStorage.getItem('translation')) }
  catch (e) { translationData = [] }
  const langTrans = (translationData && translationData[langCode]) ? translationData[langCode] : {};
  return (langTrans && langTrans[textKey]) ? langTrans[textKey] : textKey;
}

export const t_with_params = (textKey,dataObj) => {
  const langCode = (localStorage.getItem('language'))?localStorage.getItem('language'):"en";
  var translationData = [];
  try { translationData = JSON.parse(localStorage.getItem('translation')) }
  catch (e) { translationData = [] }
  const langTrans = (translationData && translationData[langCode]) ? translationData[langCode] : {};
  const enlangTrans = (translationData && translationData['en']) ? translationData['en'] : {};

  let entranText = (enlangTrans && enlangTrans[textKey]) ? enlangTrans[textKey] : textKey;

  let tranText = (langTrans && langTrans[textKey]) ? langTrans[textKey] : entranText;

  if(tranText){
    Object.keys(dataObj).map((key) => {
      tranText = tranText.replace(key,dataObj[key]);
      return null
    })
  }
  return tranText;
}

export const changeLanguage = langCode => {
  let language = langCode;
  localStorage.setItem('language', language);
  // this.setState({ language: language });
}

export const getTranslatedData = data => {
  if(typeof(data)=="object"){
    const langCode = (localStorage.getItem('language'))?localStorage.getItem('language'):"en";
    return (data[langCode]) ? data[langCode] : (data["en"])?data["en"]:"";
  }else {
    return "--";
  }
  
}

export const firstLetterCapital = (text="") => text ? text.charAt(0).toUpperCase() + text.slice(1, text.length) : ""