import React from "react";

function SessionView(props) {
  const { fancyData, fancy } = props;
  return (
    <div className="table-responsive hello">
      <table className="table table-bordered hello">
        <thead>
          <tr>
            <th
              colSpan={7 + fancyData?.sub_comp?.length * 2}
              // colSpan="10"
              className="bet-place-tbl-th market_type_row session-th-strip align-left"
              fancy
            >
              PENDING SESSIONS
            </th>
          </tr>
          <tr>
            <th
              className="bet-place-tbl-th"
              style={{ backgroundColor: "#49494A" }}
            >
              {fancy?.session?.name ? fancy?.session?.name.toUpperCase() : ""}
            </th>
            <th className="bet-odds-type bet-place-tbl-th">No</th>
            <th
              className="bet-place-tbl-th"
              style={{ backgroundColor: "#49494A" }}
            >
              RATE
            </th>
            <th className="bet-odds-type bet-place-tbl-th">Yes</th>
            <th
              className="bet-place-tbl-th"
              style={{ backgroundColor: "#49494A" }}
            >
              RATE
            </th>
            <th
              className="bet-place-tbl-th"
              style={{ backgroundColor: "#49494A" }}
            >
              Position No
            </th>
            <th
              className="bet-place-tbl-th"
              style={{ backgroundColor: "#49494A" }}
            >
              Position YES
            </th>
            {fancyData &&
              fancyData?.sub_comp.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <th
                      key={index + "_no"}
                      className="bet-place-tbl-th"
                      style={{ backgroundColor: "#49494A" }}
                    >
                      {item.username}
                      {" NO"}
                    </th>
                    <th
                      key={index + "_yes"}
                      className="bet-place-tbl-th"
                      style={{ backgroundColor: "#49494A" }}
                    >
                      {item.username}
                      {" YES"}
                    </th>
                  </React.Fragment>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {fancy?.data.map((session, index) => {
            return (
              <tr key={index}>
                <td
                  data-column="session_title"
                  className="bet-place-tbl-td"
                  style={{ textAlign: "left" }}
                >
                  <span>
                    {session?.runner_name
                      ? session.runner_name.slice(0, 30)
                      : ""}
                  </span>
                </td>
                <td
                  data-column="no_runs"
                  className="SessionTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer close-status"
                  data-id="NO"
                  match-id="405125"
                  session-run-id="2302722"
                  session-id="63411"
                  id="63411_no"
                >
                  {session.lay_price1}

                  {/* {session.back_price1} */}
                </td>
                <td
                  data-column="no_rate"
                  id="63411_no_rate"
                  className="no_rate bet-place-tbl-td bet-val-cent close-status"
                >
                  {session.back_size1}
                </td>
                <td
                  data-column="yes_runs"
                  className="SessionTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer close-status"
                  data-id="YES"
                  match-id="405125"
                  session-run-id="2302722"
                  session-id="63411"
                  id="63411_yes"
                >
                  {/* {session.lay_price1} */}

                  {session.back_price1}
                </td>
                <td
                  data-column="yes_rate"
                  className="yes_rate bet-place-tbl-td bet-val-cent close-status"
                  id="63411_yes_rate"
                >
                  {session.lay_size1}
                </td>
                <th className="bet-place-tbl-td bet-val-cent">
                  {session.scdata &&
                    session.scdata.fexposure &&
                    session.scdata.fexposure.no}
                </th>
                <th className="bet-place-tbl-td bet-val-cent">
                  {session.scdata &&
                    session.scdata.fexposure &&
                    session.scdata.fexposure.yes}
                </th>
                {fancyData &&
                  fancyData?.sub_comp.map((sc, keyIndex) => {
                    let temp;
                    session.scdata &&
                      session.scdata.sub_comp &&
                      session.scdata.sub_comp.filter((element) => {
                        if (element.username == sc.username) {
                          return (temp = element.fexposure);
                        }
                      });
                    return (
                      <React.Fragment key={index + keyIndex + "_no"}>
                        <th
                          key={index + keyIndex + "_no"}
                          className="bet-place-tbl-td bet-val-cent"
                          style={{
                            color: temp?.no >= 0 ? "#228C22" : "#FF0000",
                          }}
                        >
                          {temp ? temp.no : "-"}
                        </th>
                        <th
                          key={index + keyIndex + "_yes"}
                          className="bet-place-tbl-td bet-val-cent"
                          style={{
                            color: temp?.yes >= 0 ? "#228C22" : "#FF0000",
                          }}
                        >
                          {temp ? temp.yes : "-"}
                        </th>
                      </React.Fragment>
                    );
                  })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default SessionView;
