import React, { Component } from 'react'
import Main from "../../components/layouts/Main";
import MyLedgerCmp from '../../components/ManageLedger/MyLedgerCmp';
export class MyLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Main>
                <MyLedgerCmp />
            </Main>
        )
    }
}

export default MyLedger