import React, { Component } from 'react'
import Main from "../../components/layouts/Main";
import AnnouncementComponent from 'components/Announcement/Announcement';
export class Announcement extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Main>
                <AnnouncementComponent />
            </Main>

        )
    }
}

export default Announcement