import React, { Fragment, Component } from "react";
import { location, withRouter } from "react-router-dom";
import Main from "../../components/layouts/Main";
import GameComp from "../../components/GameComponent/GameComp";
import { connect } from "react-redux";

class Games extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Main>
        <GameComp {...this.props} />
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Games);
