import React, { Component } from "react";
import Main from "../../components/layouts/Main";
import LockedUser from "../../components/LockedUser/List";
import { connect } from "react-redux";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Main>
        <LockedUser {...this.props} />
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(List);
