import Main from "../../components/layouts/Main";
import ListUI from "../../components/MatkaCategory/List";
import React from "react";

export function MatkaCategory(props) {
  return (
    <Main>
      <ListUI {...props} />
    </Main>
  );
}
