import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const commissionLimitUpdate = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/update_limit`,
    data
  );
};

export const commissionLimitClientUpdate = (data) => {
    return performRequest(
      apiEndPoints.methodType.POST,
      `${config.api_baseurl}users/update_current_limit`,
      data
    );
  };