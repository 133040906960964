import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const getMatkaCategory = () => {
  return performRequest(
    apiEndPoints.methodType.GET,
    `${config.baseurl}matka/categoryList`
  );
};

export const createMatkaCategory = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}matka/createEditCategory`,
    data
  );
};

export const deleteMatkaCategory = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}matka/deleteCategory`,
    data
  );
};

export const activateMatkaCategoryReq = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}matka/activeInactiveCategory`,
    data
  );
};

export const matkaActiveGameRequest = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}matka/matka_active_games`,
    data
  );
};

export const createMatkaNumber = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}matka/settle_matka_event`,
    data
  );
};

export const getMatkaConfigList = () => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}matka/getMatkaConfig`
  );
};

export const matkaConfig = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}matka/updateMatkaConfig`,
    data
  );
};
export const getMarketWinning = () => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}matka/getMarketWinning`
  );
};
export const updateMarketWinning = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}matka/updateMarketWinning`,
    data
  );
};
export const getFastMarketWinning = () => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}fast_matka/getMarketWinning`
  );
};
export const updateFastMarketWinning = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}fast_matka/updateMarketWinning`,
    data
  );
};

export const matkaResult = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}matka/matka_result`,
    data
  );
};


// --------------------------------------Fast Matka --------------------------------------------

export const getFastMatkaCategory = () => {
  return performRequest(
    apiEndPoints.methodType.GET,
    `${config.baseurl}fast_matka/categoryList`
  );
};

export const createFastMatkaCategory = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}fast_matka/createEditCategory`,
    data
  );
};

export const deleteFastMatkaCategory = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}fast_matka/deleteCategory`,
    data
  );
};

export const activateFastMatkaCategoryReq = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}fast_matka/activeInactiveCategory`,
    data
  );
};

export const getFastMatkaConfigList = () => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}fast_matka/getFastMatkaConfig`
  );
};

export const fastMatkaConfig = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}fast_matka/updateFastMatkaConfig`,
    data
  );
};

export const fastMatkaResult = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}fast_matka/matka_result`,
    data
  );
};

export const fastMatkaActiveGameRequest = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}fast_matka/matka_active_games`,
    data
  );
};

export const createFastMatkaNumber = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.baseurl}fast_matka/settle_matka_event`,
    data
  );
};