import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import InnerPageHeader from "../common/InnerPageHeader";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { Redirect } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { createRequest } from "../../_actions/manualmarkets/manualmarkets.actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObj: {
        event_type: "",
        team_1: "",
        team_2: "",
        team_3: "",
        event_name: "",
        datetime: new Date(),
      },
      showDraw: "none",
    };
    //this.state.date = "";
    //this.state.time = "";
    this.handleChangedate = this.handleChangedate.bind(this);
    this.validator = new SimpleReactValidator();
  }

  onSubmit = (e) => {
    e.preventDefault();
    //this.state.formObj.datetime = '${this.state.date} ${this.state.time}';
    if (this.validator.allValid()) {
      this.state.formObj.event_name =
        this.state.formObj.team_1 + " VS " + this.state.formObj.team_2;
      let { formObj } = this.state;
      this.props.dispatch(
        createRequest(formObj, (res) => {
          if (res) this.setState({ redirect: "/manual-markets" });
        })
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInput = (e) => {
    const { name, value } = e.target; // <-- moved outside asynchronous context
    this.setState((state) => ({
      formObj: {
        ...state.formObj,
        [name]: value,
      },
    }));

    if (name == "event_type") {
      if (value == 1) {
        this.state.showDraw = "block";
      } else {
        this.state.showDraw = "none";
      }
    }

    if (this.state.showDraw == "block" && name == "team_3") {
      this.state.formObj.team_3 = value;
    } else if (this.state.showDraw == "none") {
      this.state.formObj.team_3 = "";
    }

    /* if(name == "team_1" || name == "team_2"){
       
        if(value.length>15){   
          this.validator.message({message:'Number of characters should not be greater than 15.'});                 
        }
     }*/
  };

  handleChangedate(date) {
    this.setState({
      formObj: {
        datetime: date,
      },
    });
  }

  render() {
    let {
      redirect,
      formObj: { event_type, team_1, team_2, team_3, event_name, datetime },
      showDraw,
    } = this.state;

    const {
      heading,
      breadcrumbTitle,
      breadcrumb,
      navigationUrl,
      action,
      parentType,
    } = this.props;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <>
        <InnerPageHeader
          pageTitle="Create Manual Match"
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Manual Markets", url: "/manual-markets" },
          ]}
          currenPageTitle="Add"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-content">
                  <form onSubmit={this.onSubmit} className="form-horizontal">
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Match Type
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="event_type"
                          id="event_type"
                          onChange={(e) => this.handleInput(e)}
                        >
                          <option value="">Select Match Type</option>
                          <option value="1">Test Match</option>
                          <option value="2">ODI</option>
                          <option value="3">T20</option>
                          <option value="4">T10</option>
                        </select>
                        <em>
                          {this.validator.message(
                            "Match type",
                            event_type,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">Team 1</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="team_1"
                          id="team_1"
                          //value = {this.state.formObj.team_1}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message("team_1", team_1, "required")}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">Team 2</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="team_2"
                          id="team_2"
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message("team_2", team_2, "required")}
                        </em>
                      </div>
                    </div>

                    <div id="div_team_3" style={{ display: showDraw }}>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">Team 3</label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="team_3"
                            id="team_3"
                            value="Draw"
                            onChange={(e) => this.handleInput(e)}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Date Time
                      </label>
                      <div className="col-sm-9">
                        <DatePicker
                          selected={datetime}
                          //onChange={handleChange}
                          //minDate={today}
                          //maxDate={in3Days}
                          showTimeSelect
                          dateFormat="yyyy-MM-dd hh:mm:ss"
                          className="form-control"
                          id="datetime"
                          name="datetime"
                          onChange={this.handleChangedate}
                        />

                        <em>
                          {this.validator.message(
                            "datetime",
                            datetime,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>

                    <div className="hr-line-dashed"></div>

                    <div className="form-group">
                      <div className="col-sm-4 col-sm-offset-2">
                        <button className="btn btn-primary" type="submit">
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
