import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link, useHistory } from "react-router-dom";
import { getCompanyReport } from "_services";
import InnerPageHeader from "components/common/InnerPageHeader";
import { convertParseFloat } from "_helpers";
import ReactPaginate from "react-paginate";

function CompanyReportComponent() {
  const history = useHistory();
  const location = useLocation();
  const { matchData } = location.state;

  const { event_id } = useParams();

  const [companyReportList, setCompanyReportList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [limit,] = useState(100);

  useEffect(() => {
    if (event_id) {
      fetchCompanyReport();
    }
  }, [currentPage]);

  const fetchCompanyReport = () => {
    let sendData = {};
    sendData.current_page = currentPage;
    sendData.items_perpage = limit;
    sendData.event_id = event_id;
    getCompanyReport(sendData)
      .then((response) => {
        if (
          response?.data?.data?.company_report &&
          response?.data?.data?.company_report?.length
        ) {
          setCompanyReportList(response.data.data.company_report);
          setPageCount(Math.ceil(response.data.data.total / limit));
        } else {
          setCompanyReportList([]);
        }
      })
      .catch((error) => {
        setCompanyReportList([]);
      });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage + 1);
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="Company Report"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: matchData.sports_id == "6" ? "Games list" : "Matches", url: matchData.sports_id == "6" ? "/game" : "/matches_list" },
          {
            title: matchData.event_name, url: {
              pathname: `/${matchData.sports_id == "6" ? "games_list" : "match_dashboard"}/${event_id}`,
              state: { matchData: matchData },
            }
          },
        ]}
        currenPageTitle={"Company Report"}
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Match Report</h5>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr className="tbl-header-middle">
                      <th colSpan="1"></th>
                      <th colSpan="5">Plus / Minus</th>
                      <th colSpan="4">COMMISSION</th>
                      <th colSpan="6">OTHERS</th>
                    </tr>
                    <tr>
                      <th>Client Name</th>
                      <th>MatchOdds Plus Minus</th>
                      <th>Other Market Plus Minus</th>
                      <th>Bookmaker Plus Minus</th>
                      <th>Session Plus Minus</th>
                      <th>Total</th>
                      <th>Match Commission</th>
                      <th>Session Commission</th>
                      <th>Other Commission</th>
                      <th>Total Commission</th>
                      <th>System Plus/Minus</th>
                      <th>OTHERS</th>
                      <th>Final</th>
                      <th>% Share</th>
                      <th>My Share</th>
                      <th>Company Share</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                  <tfoot>
                    {
                      // companyReportList && companyReportList.length ? (
                      companyReportList?.map((report, index) => (
                        <tr key={index}>

                          <th> {report.username} ({(report.first_name)}) </th>
                          <th>{convertParseFloat(report.match_odds_pm)}</th>
                          <th>{convertParseFloat(report.other_pm)}</th>
                          <th>{convertParseFloat(report.bookmaker_pm)}</th>
                          <th>{convertParseFloat(report.session_pm)}</th>
                          <th>{convertParseFloat(Math.round((report.match_pm + report.session_pm) * 100) / 100)}</th>
                          <th>{convertParseFloat(Math.round((report.match_commission) * 100) / 100)}</th>
                          <th>{convertParseFloat(report.session_commission)}</th>
                          <th>{convertParseFloat(report.other_commission)}</th>
                          <th>{convertParseFloat(Math.round((report.other_commission +
                            report.session_commission + report.match_commission) * 100) / 100)}</th>
                          <th>{convertParseFloat(Math.round((report.system_pm) * 100) / 100)}</th>
                          <th>{convertParseFloat(Math.round((report.other_charges) * 100) / 100)}</th>
                          <th> {convertParseFloat(Math.round((report.final_total) * 100) / 100)}</th>
                          <th>{report.my_share_percent}</th>
                          <th>{convertParseFloat(Math.round((report.my_share) * 100) / 100)}</th>
                          <th>{convertParseFloat(Math.round((report.company_share) * 100) / 100)} </th>
                        </tr>
                      ))
                      // ) : (
                      //   <tr>
                      //     <th>Total</th>
                      //     <th>0</th>
                      //     <th>0</th>
                      //     <th>0</th>
                      //     <th>0</th>
                      //     <th>0</th>
                      //     <th>0</th>
                      //     <th>0</th>
                      //     <th>0</th>
                      //     <th>0</th>
                      //     <th>0</th>
                      //     <th>0</th>
                      //     <th>0</th>
                      //   </tr>
                      // )
                    }

                    <tr>
                      <th>Total</th>
                      <th>{convertParseFloat(companyReportList.reduce((a, v) => a = a + v.match_odds_pm, 0))}</th>
                      <th>{convertParseFloat(companyReportList.reduce((a, v) => a = a + v.other_pm, 0))}</th>
                      <th>{convertParseFloat(companyReportList.reduce((a, v) => a = a + v.bookmaker_pm, 0))}</th>
                      <th>{convertParseFloat(companyReportList.reduce((a, v) => a = a + v.session_pm, 0))}</th>
                      <th>{convertParseFloat((companyReportList.reduce((a, v) => a = a + v.match_odds_pm, 0)) + (companyReportList.reduce((a, v) => a = a + v.other_pm, 0)) + (companyReportList.reduce((a, v) => a = a + v.session_pm, 0)))}
                      </th>
                      <th>{convertParseFloat(Math.round(((companyReportList.reduce((a, v) => a = a + v.match_commission, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((companyReportList.reduce((a, v) => a = a + v.session_commission, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((companyReportList.reduce((a, v) => a = a + v.other_commission, 0))) * 100) / 100)}</th>
                      <th>
                        {convertParseFloat(Math.round(((companyReportList.reduce((a, v) => a = a + v.match_commission, 0)) + (companyReportList.reduce((a, v) => a = a + v.session_commission, 0)) + (companyReportList.reduce((a, v) => a = a + v.other_commission, 0))) * 100) / 100)}
                      </th>
                      <th>{convertParseFloat(Math.round(((companyReportList.reduce((a, v) => a = a + v.system_pm, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((companyReportList.reduce((a, v) => a = a + v.other_charges, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((companyReportList.reduce((a, v) => a = a + v.final_total, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((companyReportList.reduce((a, v) => a = a + v.my_share_percent, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((companyReportList.reduce((a, v) => a = a + v.my_share, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((companyReportList.reduce((a, v) => a = a + v.company_share, 0))) * 100) / 100)}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="row">
                  <div className="float-right"></div>
                </div>

                <div className="paginate_class">
                  <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyReportComponent;
