import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const loginReport = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}/login_report`,
    data
  ).then((response) => {
    const { data = {} } = response?.data
    return data
  })
    .catch((error) => {
      return error.response
    });
};
