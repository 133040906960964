import { manualmarketsConstants } from "../../_constants";
import { createManualMarkets, listManualMarkets } from "../../_services";
import { toast } from "react-toastify";

export const createRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: manualmarketsConstants.MANUALMARKETS_CREATE_REQUEST, data: {} });
    createManualMarkets(data)
      .then((response) => {
        callback(response.data);
        toast.success("Recorded added successfully");
        dispatch({
          type: manualmarketsConstants.MANUALMARKETS_CREATE_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: manualmarketsConstants.MANUALMARKETS_CREATE_FAILED, data: error });
      });
  };
};

export const listRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: manualmarketsConstants.MANUALMARKETS_LIST_REQUEST, data: {} });
    listManualMarkets(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: manualmarketsConstants.MANUALMARKETS_LIST_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: manualmarketsConstants.MANUALMARKETS_LIST_FAILED,
          data: error.response,
        });
      });
  };
};
