import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";
import { updateLiveTVUrl } from "_services";
import { useSelector } from "react-redux";
import "./style.css";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";

function LiveTVUrl(props) {
  const location = useLocation();
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const { matchData } = location && location.state ? location.state : {};

  const { event_id } = useParams();

  const [isLoading, updateLoading] = useState(false);
  const [liveTVUrl, setLiveTVUrl] = useState("");
  const userLogin = JSON.parse(localStorage.getItem("userLogin"));

  function onSubmit(e) {
    e && e.preventDefault();
    const sendData = {
      event_id: event_id,
      url: e ? liveTVUrl : e,
    };
    if (!!e ? simpleValidator.current.allValid() : true) {
      updateLoading(true);
      updateLiveTVUrl(sendData)
        .then((response) => {
          if (response && response.data) {
            toast(
              response.data.message
                ? response.data.message
                : "LiveTV URL Updated"
            );
            setTimeout(() => {
              updateLoading(false);
              props.history.goBack();
            }, 2000);
          } else {
            updateLoading(false);
          }
        })
        .catch((error) => {
          updateLoading(false);
          toast(error ? error : "LiveTV URL Update fail");
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  }

  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10" style={{ paddingLeft: 20 }}>
          <h2>LiveTV</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/">Dashboard</a>
            </li>
            <li>
              <a href="/matches_list">Matches</a>
            </li>
            <li className="breadcrumb-item active">
              <Link
                to={{
                  pathname: `/match_dashboard/${event_id}`,
                  state: { matchData: matchData },
                }}
              >
                {matchData?.event_name}
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <strong>LiveTV</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2"></div>
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>LiveTV</h5>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <form>
                      <div className="form-group row">
                        <label
                          htmlFor="inputAgentAnnouncement"
                          className="col-sm-2 col-form-label"
                        >
                          Live TV URL
                        </label>
                        <div className="col-sm-10">
                          <textarea
                            type="textarea"
                            name="clientAnnouncement"
                            className="form-control"
                            id="inputAgentAnnouncement"
                            placeholder="Live TV URL"
                            value={liveTVUrl}
                            onChange={(e) => setLiveTVUrl(e.target.value)}
                          />
                          {simpleValidator.current.message(
                            "Live TV URL",
                            liveTVUrl,
                            "required|url"
                          )}
                        </div>
                      </div>
                    </form>
                    <div className="form-group row">
                      <div className="col-sm-4 col-sm-offset-2">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => !isLoading && onSubmit(e)}
                        >
                          Save Changes
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ marginLeft: "5px" }}
                          onClick={() => !isLoading && onSubmit()}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="render-sub-company-live-report">
        <div className="wrapper wrapper-content">
          <div className="row" style={{ margin: "0 !important" }}>
            <div className="match-index-row header-top-spacing">
              <div
                className="row"
                style={{ paddingBottom: "30px", margin: "auto auto" }}
              ></div>
            </div>
          </div>

          <div className="wrapper wrapper-content">
            <div className="row">
              <div className="col-lg-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LiveTVUrl;
