import React, { Fragment, Component } from "react";
import { location, withRouter } from "react-router-dom";
import Main from "../../../components/layouts/Main";
import ManualMarketMatchDashboard from "../../../components/ManualMarketMatchDashboard/List";
import withAuth from "../../../_helpers/withAuth";
import { connect } from "react-redux";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Main>
        <ManualMarketMatchDashboard {...this.props} />
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(List);
