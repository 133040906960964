import React, { Component } from "react";

import CacheBuster from "react-cache-buster";
import { version } from "../package.json";

// import { Switch, Route, Link } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import Login from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./components/authHoc/PrivateRoute";
import AddMaster from "./pages/Master/Add";
import Master from "./pages/Master/List";
import Stockists from "./pages/Stockists/List";
import SubCompany from "./pages/SubCompany/List";
import SuperStockists from "./pages/SuperStockists/List";
import Agent from "./pages/Agent/List";

import StockistsAdd from "./pages/Stockists/Add";
import SubCompanyAdd from "./pages/SubCompany/Add";
import SuperStockistsAdd from "./pages/SuperStockists/Add";
import AgentAdd from "./pages/Agent/Add";

import Client from "./pages/Client/List";
import AddClient from "./pages/Client/Add";
import UserView from "pages/UserView/UserView";
import LedgesView from "pages/LedgesView/LedgesView";
import Tournament from "pages/Sports/Tournament/Tournament";

import ManualMarkets from "pages/Sports/ManualMarkets/ManualMarkets"; //KN
import AddManualMarkets from "pages/Sports/ManualMarkets/Add"; //KN
import ManualMarketMatchDashboard from "pages/Sports/ManualMarketMatchDashboard/List"; //KN
import AddNewManualMarket from "pages/Sports/ManualMarketMatchDashboard/Add"; //KN

import MatchesList from "pages/Sports/MatchesList/MatchesList";
import MatchesListId from "pages/Sports/MatchesList/MatchesListId";

// import Settlements from "pages/Settlements/List";

import FancySession from "pages/Settlements/FancySession";
import MatchExchange from "pages/Settlements/MatchExchange";
import MatchRollback from "pages/Settlements/MatchRollback";
import AuthVerify from "components/common/AuthVerify";

import Bet_Slips from "pages/bet_slips/Bet_Slips";
import Bet_Slips2 from "pages/bet_slips/Bet_Slips2";
import Session_Bet_Slips from "pages/bet_slips/Session_Bet_Slips";
import CompanyReport from "pages/CompanyReport/CompanyReport";
import ClientReport from "pages/ClientReport/ClientReport";
import SessionEndingReport from "pages/SessionEndingReport/SessionEndingReport";
import CollectionReportMD from "pages/CollectionReportMD/CollectionReportMD";
import LiveReport from "pages/LiveReport/index";

import AddFancy from "pages/Sports/ManualMarketMatchDashboard/AddFancy"; //KN
import AddExistManualSession from "pages/Sports/ManualMarketMatchDashboard/AddExistManualSession"; //KN
import AddExistManualMarket from "pages/Sports/ManualMarketMatchDashboard/AddExistManualMarket"; //KN

import SubCompanyLiveReport from "pages/SubCompanyLiveReport/SubCompanyLiveReport";

import CollectionReport from "pages/LedgesView/CollectionReport";
import EarningReport from "pages/LedgesView/EarningReport";
import AllSubCompReport from "pages/LedgesView/AllSubCompReport";

import GamesDailyReport from "pages/LedgesView/GamesDailyReport";
import CashBook from "pages/LedgesView/CashBook";
import SuperNowaPL from "pages/LedgesView/SuperNowaPL";
import OtherCharges from "pages/OtherCharges/OtherCharges";
import MyLedgerPage from "../src/pages/LedgesView/MyLedges";

import ManagePassword from "pages/ManagePassword/ManagePassword";

import RolePassword from "pages/RolePassword/RolePassword";

import Language from "pages/Language/Language";
import Announcement from "pages/Announcement/Announcement";

import BlockedClient from "pages/Client/Blocked";
import BlockedSubAgent from "pages/Agent/BlockedSubAgent";
import BlockedSubCompany from "pages/SubCompany/BlockedSubCompany";
import CommissionLimit from "pages/Client/CommissionLimit";

import StaffList from "pages/Staff/List";
import EditStaff from "pages/Staff/Edit";
import AddStaff from "pages/Staff/Add";

import PrivilegesGroup from "pages/Staff/PrivilegesGroup";
import CreatePrevilageGroup from "pages/Staff/CreateGroup";

import Search from "pages/Search/Search";
import CreditCoins from "pages/CreditCoins/CreditCoins";
import GlobalSearch from "pages/GlobalSearch/GlobalSearch";
import FixtureMarket from "pages/FixtureMarket/FixtureMarket";

import Games from "pages/Games/Games";
import PendingGame from "pages/PendingGame/Game";
import GamesList from "pages/Games/GameListPage";

import LockedUser from "pages/Locked_Users/List";
import { WebSocketContextComponent } from "./components/layouts/WebSocketContext";

import Commentary from "pages/Commentary/Commentary";
import NewsUpdates from "pages/Commentary/NewsUpdates";
import Delay from "pages/Commentary/Delay";
import StakeDelay from "pages/Commentary/StakeDelay";
import LiveTVUrl from "components/Commentary/LiveTVUrl";
import EventType from "components/Commentary/EventType";
import MatchId from "components/Commentary/MatchId";
import GlobalSettings from "components/GlobalSettings/GlobalSettings";

import EditSubCompany from "pages/SubCompany/Edit";
import EditClient from "pages/Client/Edit";
import LoginReport from "pages/Reports/LoginReport";
import ScoreWidget from "components/Commentary/ScoreWidget";
import ShowSettelment from "pages/LedgesView/ShowSettelment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouteScrollToTop from "RouteScrollToTop";
import { MatkaCategory, AddMatkaCategory, EditMatkaCategory } from "pages/MatkaCategory";
import { FastMatkaCategory, AddFastMatkaCategory, EditFastMatkaCategory } from "pages/FastMatkaCategory";
import { MatkaConfig } from "pages/MatkaConfig";
import { MatkaNumber, AddMatkaNumber, EditMatkaNumber } from "pages/MatkaNumber";
import MatkaReport from "pages/MatkaReport/List";
import Scoreboard from "pages/Commentary/Scoreboard";
import MaintanenceSetting from "components/MaintenanceSetting/MaintanenceSetting";
import RulesComponent from "pages/Rules/RulesComponent";
import DatabaseBack from "pages/DatabadeBakeUp/DatabaseBackUp";
import { FastMatkaConfig } from "pages/FastMatkaConfig";
import { AddFastMatkaNumber } from "pages/FastMatkaNumber/Add";



class App extends Component {
  render() {
    const isProduction = process.env.NODE_ENV === "production";
    return (
      <>
        <CacheBuster
          currentVersion={version}
          isEnabled={isProduction}
          isVerboseMode={false}
        // loadingComponent={<Loading />}
        >
          {/* <WebSocketContextComponent> */}
          <Router>
            <RouteScrollToTop />
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              {/* <PrivateRoute exact path={paths.fnb.dashboard.dietitian_dashboard.list} component={FnbDietitian} /> */}
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute
                exact
                path={["/master/add", "/master/edit/:id"]}
                component={AddMaster}
              />
              {/* <PrivateRoute exact path="/master/edit" component={AddMaster} /> */}
              <PrivateRoute exact path="/master" component={Master} />

              <PrivateRoute
                exact
                path="/locked_users"
                component={LockedUser}
              />

              <PrivateRoute exact path="/game" component={Games} />
              <PrivateRoute exact path="/pending-game" component={PendingGame} />

              <PrivateRoute
                exact
                path="/games_list/:id"
                component={GamesList}
              />

              <PrivateRoute
                exact
                path="/super-stockists"
                component={SuperStockists}
              />
              <PrivateRoute
                exact
                path={["/super-stockists/add", "/super-stockists/edit/:id"]}
                component={SuperStockistsAdd}
              />
              <PrivateRoute
                exact
                path="/super-stockists/stockists"
                component={Stockists}
              />
              <PrivateRoute exact path="/stockists" component={Stockists} />
              <PrivateRoute
                exact
                path={["/stockists/add", "/stockists/edit/:id"]}
                component={StockistsAdd}
              />
              <PrivateRoute
                exact
                path="/sub-company"
                component={SubCompany}
              />
              <PrivateRoute
                exact
                path="/sub-company/add"
                component={SubCompanyAdd}
              />
              <PrivateRoute exact path="/agent" component={Agent} />
              <PrivateRoute
                exact
                path={["/agent/add", "/agent/edit/:id"]}
                component={AgentAdd}
              />
              <PrivateRoute
                exact
                path="/manage-subcompany"
                component={SubCompany}
              />
              <PrivateRoute
                exact
                path="/blocked-subcompany"
                component={BlockedSubCompany}
              />
              <PrivateRoute
                exact
                path={["/manage-subcompany/add"]}
                component={SubCompanyAdd}
              />
              <PrivateRoute
                exact
                path={"/manage-subcompany/edit/:id"}
                component={EditSubCompany}
              />

              <PrivateRoute exact path="/tournament" component={Tournament} />

              <PrivateRoute
                exact
                path="/matches_list"
                component={MatchesList}
              />

              {/* <PrivateRoute
                exact
                path="/match_dashboard"
                component={MatchesListId}
              /> */}

              <PrivateRoute
                exact
                path="/match_dashboard/:id"
                component={MatchesListId}
              />

              <PrivateRoute
                exact
                path="/my-clients/add"
                component={AddClient}
              />
              <PrivateRoute
                exact
                path="/my-clients/edit/:id"
                component={EditClient}
              />
              <PrivateRoute
                exact
                path="/userview/:type/:id/:username"
                component={UserView}
              />
              <PrivateRoute
                exact
                path="/userdetails/:type/:subtype/:id/:username"
                component={LedgesView}
              />

              <PrivateRoute
                exact
                path="/match-result"
                component={MatchExchange}
              />
              <PrivateRoute
                exact
                path="/match-rollback"
                component={MatchRollback}
              />
              <PrivateRoute
                exact
                path="/manage-fancy"
                component={FancySession}
              />

              {/* start */}
              <PrivateRoute
                exact
                path="/bet_slips/:event_id"
                component={Bet_Slips}
              />
              <PrivateRoute
                exact
                path="/bet_slips2/:event_id"
                component={Bet_Slips2}
              />
              <PrivateRoute
                exact
                path="/session-bet-slips/:event_id"
                component={Session_Bet_Slips}
              />
              <PrivateRoute
                exact
                path="/company_report/:event_id"
                component={CompanyReport}
              />
              <PrivateRoute
                exact
                path="/session_ending_report"
                component={SessionEndingReport}
              />

              <PrivateRoute
                exact
                path="/collection_report/:event_id"
                component={CollectionReportMD}
              />
              <PrivateRoute
                exact
                path="/client_report/:event_id"
                component={ClientReport}
              />
              <PrivateRoute
                exact
                path="/manual-markets"
                component={ManualMarkets}
              />

              <PrivateRoute
                exact
                path="/manual-markets/add"
                component={AddManualMarkets}
              />

              {/* **********Ledger Components************ */}

              <PrivateRoute
                exact
                path="/collection-report"
                component={CollectionReport}
              />

              <PrivateRoute
                exact
                path="/profit-&-loss"
                component={EarningReport}
              />

              <PrivateRoute
                exact
                path="/sub_company_report"
                component={AllSubCompReport}
              />

              <PrivateRoute
                exact
                path="/games-daily-report"
                component={GamesDailyReport}
              />
              <PrivateRoute exact path="/cashbook" component={CashBook} />

              <PrivateRoute
                exact
                path="/my-ledgers"
                component={MyLedgerPage}
              />

              <PrivateRoute
                exact
                path="/show_settlement/:source_id/:user_id"
                component={ShowSettelment}
              />

              <PrivateRoute
                exact
                path="/super_nowa_profit_loss"
                component={SuperNowaPL}
              />

              <PrivateRoute
                exact
                path="/other_charges"
                component={OtherCharges}
              />

              <PrivateRoute
                exact
                path="/login_report"
                component={LoginReport}
              />

              {/* *****************Manage Password************* */}
              <PrivateRoute
                exact
                path="/manage-password"
                component={ManagePassword}
              />

              <PrivateRoute
                exact
                path="/edit_password/:user_guid"
                component={RolePassword}
              />

              {/* *****************Language************* */}
              <PrivateRoute exact path="/Language" component={Language} />

              {/* *****************Announcement************* */}
              <PrivateRoute
                exact
                path="/announcement"
                component={Announcement}
              />
              <PrivateRoute
                exact
                path="/db_backup"
                component={DatabaseBack}
              />
              {/* **************My Client*********** */}
              <PrivateRoute exact path="/my-clients" component={Client} />
              <PrivateRoute
                exact
                path="/blocked-clients"
                component={BlockedClient}
              />
              <PrivateRoute
                exact
                path="/blocked-agent"
                component={BlockedSubAgent}
              />
              <PrivateRoute
                exact
                path="/commission_limits"
                component={CommissionLimit}
              />
              {/* **************Staff*********** */}
              <PrivateRoute
                exact
                path="/privileges_group"
                component={PrivilegesGroup}
              />

              <PrivateRoute exact path="/staff_list" component={StaffList} />

              <PrivateRoute exact path="/edit_staff" component={EditStaff} />

              <PrivateRoute exact path="/add_staff" component={AddStaff} />

              <PrivateRoute
                exact
                path="/create_privilage"
                component={CreatePrevilageGroup}
              />

              {/* **************Search*********** */}

              <PrivateRoute exact path="/search" component={Search} />

              <PrivateRoute
                exact
                path="/live_report/:event_id"
                component={LiveReport}
              />

              <PrivateRoute
                exact
                path="/manual-market-match-dashboard/List/:event_id/:event_name"
                component={ManualMarketMatchDashboard}
              />

              <PrivateRoute
                exact
                path="/manual-market-match-dashboard/add/:event_id/:event_name"
                component={AddNewManualMarket}
              />

              <PrivateRoute
                exact
                path="/manual-market-match-dashboard/addFancy/:event_id/:event_name"
                component={AddFancy}
              />

              <PrivateRoute
                exact
                path="/manual-market-match-dashboard/addExistManualSession/:event_id/:event_name/:session_id/:session_name/:lay_price_1/:back_price_1"
                component={AddExistManualSession}
              />

              <PrivateRoute
                exact
                path="/manual-market-match-dashboard/addExistManualMarket/:event_id/:event_name/:market_id/:selectionId1/:runnerName1/:selectionId2/:runnerName2/:market_type"
                component={AddExistManualMarket}
              />

              <PrivateRoute
                exact
                path="/subcompany_live_report/:event_id"
                component={SubCompanyLiveReport}
              />

              <PrivateRoute
                exact
                path="/credit_coins"
                component={CreditCoins}
              />

              {/* /userdetails/collection-report/coin_history/3b3b04467b/SC92320 */}

              <PrivateRoute
                exact
                path="/global-search"
                component={GlobalSearch}
              />

              <PrivateRoute
                exact
                path="/fixture-market/:event_id/:type"
                component={FixtureMarket}
              />

              <PrivateRoute
                exact
                path="/commentary/:event_id"
                component={Commentary}
              />
              <PrivateRoute
                exact
                path="/newsupdates/:event_id"
                component={NewsUpdates}
              />
              <PrivateRoute
                exact
                path="/stake_delay/:event_id"
                component={StakeDelay}
              />

              {/* <PrivateRoute exact path="/delay/:event_id" component={Delay} /> */}

              <PrivateRoute
                exact
                path="/livetv/:event_id"
                component={LiveTVUrl}
              />

              <PrivateRoute
                exact
                path="/scorewidget/:event_id"
                component={ScoreWidget}
              />

              <PrivateRoute
                exact
                path="/eventtype/:event_id"
                component={EventType}
              />

              <PrivateRoute
                exact
                path="/match_id/:event_id"
                component={MatchId}
              />

              <PrivateRoute
                exact
                path="/matka-category"
                component={MatkaCategory}
              />
              <PrivateRoute
                exact
                path="/matka-category/add"
                component={AddMatkaCategory}
              />
              <PrivateRoute
                exact
                path="/matka-category/edit/:id"
                component={EditMatkaCategory}
              />
              <PrivateRoute
                exact
                path="/fast-matka-category"
                component={FastMatkaCategory}
              />
              <PrivateRoute
                exact
                path="/fast-matka-category/add"
                component={AddFastMatkaCategory}
              />
              <PrivateRoute
                exact
                path="/fast-matka-category/edit/:id"
                component={EditFastMatkaCategory}
              />

              <PrivateRoute
                exact
                path="/matka-number"
                component={MatkaNumber}
              />
              <PrivateRoute
                exact
                path="/add-matka-number"
                component={AddMatkaNumber}
              />
              <PrivateRoute
                exact
                path="/edit-matka-number/:id"
                component={EditMatkaNumber}
              />
              <PrivateRoute
                exact
                path="/add-fast-matka-number"
                component={AddFastMatkaNumber}
              />
              <PrivateRoute
                exact
                path="/matka-report/:rp_type"
                component={MatkaReport}
              />

              <PrivateRoute
                exact
                path="/match-setting"
                component={GlobalSettings}
              />

              <PrivateRoute
                exact
                path="/scoreboard/:event_id"
                component={Scoreboard}
              />
              <PrivateRoute
                exact
                path="/matka_config"
                component={MatkaConfig}
              />
              <PrivateRoute
                exact
                path="/fast_matka_config"
                component={FastMatkaConfig}
              />

              <PrivateRoute
                exact
                path="/maintenance_mode"
                component={MaintanenceSetting} />

              <PrivateRoute
                exact
                path="/rules"
                component={RulesComponent}

              />
            </Switch >
            <AuthVerify />
          </Router >
          {/* </WebSocketContextComponent> */}
        </CacheBuster >
        <ToastContainer />
      </>
    );
  }
}

export default App;
