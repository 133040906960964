import React, { Component } from 'react'
import Main from "../../components/layouts/Main";
import ListComponent from 'components/Staff/List';
export class List extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Main>
                <ListComponent

                />
            </Main>

        )
    }
}

export default List