import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";
import { ViewScoreWidget } from "./ViewScoreWidget";

export default function LiveReportHeader(props) {
  const { liveData, setActiveMarket } = props;
  return (
    <div className="container-fluid">
      <div className="row">
        {liveData?.event?.sport_id == 4 &&
          <div className="flex_div_class rowGaper">
              <ViewScoreWidget updateData={liveData} />
            <div
              className="ldg-tbl-col col-lg-3 col-xs-3 col-md-3 col-sm-3 current-event-box bet_status_box stausBx_ht"
              style={{
                paddingRight: "5px",
                paddingLeft: "3px !important",
              }}
            >
              <div
                className="inplay-show-place-bet"
                style={{
                  backgroundColor: "rgb(58, 97, 162)",
                  color: "rgb(255, 255, 255)",
                  fontSize: "30px",
                  minHeight: "100%",
                }}
                id="event_table_403636"
              >
                {(liveData?.event?.sport_id == 999 ||liveData?.event?.sport_id == 777)
                  ? liveData?.event?.matka_game_result &&
                    liveData?.event?.matka_game_result !== ""
                    ? liveData?.event?.matka_game_result
                    : liveData?.event && (
                      <>
                        BET
                        <br></br>
                        OPEN
                      </>
                    )
                  : liveData?.event?.button_label
                    ? liveData.event.button_label
                    : liveData?.event && (
                      <>
                        BET
                        <br></br>
                        OPEN
                      </>
                    )}
              </div>
            </div>
          </div>
        }
        {(liveData?.event?.sport_id == 999 || liveData?.event?.sport_id == 777) ? (
          <div className="bet_select_box">
            <Form.Label>BET TYPE</Form.Label>
            <Form.Control
              className="bet_select"
              as="select"
              onChange={(e) => setActiveMarket(e.target.value)}
            >
              {liveData?.market?.map((market) => (
                <option
                  className="pointer"
                  value={market?.market_name}
                  onClick={() => setActiveMarket(market?.market_name)}
                >
                  {market?.market_name}
                </option>
              ))}
            </Form.Control>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
