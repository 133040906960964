import React, { Component } from "react";
import Main from "../../components/layouts/Main";
import { connect } from "react-redux";
import BetSlipsComponent from "../../components/BetSlips/BetSlipsComponent";
import MatkaBetSlips from "components/BetSlips/MatkaBetSlips";
class Bet_Slips extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Main>
        {(this.props.history.location?.state?.matchData?.sport_id === 999 || this.props.history.location?.state?.matchData?.sport_id === 777) ? (
          <MatkaBetSlips {...this.props} />
        ) : (
          <BetSlipsComponent {...this.props} />
        )}
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Bet_Slips);
