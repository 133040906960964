import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const companyAnnouncement = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}company_announcement`,
    data
  );
};

export const createAnnouncement = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}create_announcement`,
    data
  );
};

export const userAnnouncement = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}user_announcement`,
    data
  );
};

export const userAnnouncementList = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}user_announcement_list`,
    data
  );
};

export const deleteUserAnnouncement = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}delete_user_announcement`,
    data
  );
};

export const matkaAnnouncement = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}matka_announcement`,
    data
  );
};

export const getMatkaAnnouncement = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_matka_announcement`,
    data
  );
};


export const getDataBaseList = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.GET,
    `${config.api_baseurl}get_db_backup`,
    data
  );
};


export const getBackUp = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.GET,
    `${config.baseurl}cron/db_backup`,
    data
  );
};

export const fastMatkaAnnouncement = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}fast_matka_announcement`,
    data
  );
};

export const getFastMatkaAnnouncement = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}get_fast_matka_announcement`,
    data
  );
};
