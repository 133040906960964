import React from "react";
import { toast, ToastContainer } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { getStorage } from '../utils';
import { connect } from 'react-redux';
export function withAuth(Component) {
    return class AuthenticatedComponent extends React.Component {

        isAuthenticated() {
            let { data } = getStorage("userLogin", "object", {});
            // console.log(data?.user_id, "authDataauthDataauthData")
            return (data?.user_id) ? true : false;
        }

        /**
         * Render
         */
        render() {
            const loginErrorMessage = () => {
                toast.error("Authentication Failed");
                return <Redirect to="/login" />
            }

            const isAuthenticated = this.isAuthenticated();
            // console.log(isAuthenticated, "isAuthenticatedisAuthenticated")
            return (

                <div>
                    

                    {isAuthenticated === true ? <Component {...this.props} /> : loginErrorMessage()}
                </div>
            );
        }
    };
}

const mapStateToProps = state => ({
});
export default connect(mapStateToProps)(withAuth);
