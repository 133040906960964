import axios from "axios";
import { toast } from "react-toastify";
import config from "../lib/app-config";
import { getStorage } from "../utils";
import { performRequest } from "_apiConfig/apiHandler";
import apiEndPoints from "_apiConfig/apiEndPoints";

export const create = (data) => {
  return axios
    .post(config.api_baseurl + "users/create", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message == "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};
export const list = (data) => {
  return axios
    .get(config.api_baseurl + "users", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message == "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getCommisionLimits = (data = {}) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/get_dl_users`,
    data
  );
};
