import React, { Component } from "react";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import MaintenanceComponent from "./MaintenanceComponent";

class MaintanenceSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Main>
                <MaintenanceComponent {...this.props} />
            </Main>
        );
    }
}
const mapstatetop = (state) => ({
    ...state,
});

export default connect(mapstatetop)(MaintanenceSetting);
