import { clientConstants } from "../../_constants";
import { create, list } from "../../_services";
import { toast } from "react-toastify";

export const createRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: clientConstants.CLIENT_CREATE_REQUEST, data: {} });
    create(data)
      .then((response) => {
        callback(true);
        toast.success("Recorded added successfully");
        dispatch({
          type: clientConstants.CLIENT_CREATE_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        // callback(false);
        toast.error(error?.response?.data?.error? error?.response?.data?.error : "server error");

        dispatch({ type: clientConstants.CLIENT_CREATE_FAILED, data: error });
      });
  };
};

export const listRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: clientConstants.CLIENT_LIST_REQUEST, data: {} });
    list(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: clientConstants.CLIENT_LIST_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: clientConstants.CLIENT_LIST_FAILED,
          data: error.response,
        });
      });
  };
};
