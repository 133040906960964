import React, { Component } from 'react'
import Main from "../../components/layouts/Main";
import AddComponent from 'components/Staff/Add';
export class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Main>
                <AddComponent
                    stateData={this?.props?.location?.state}
                />
            </Main>

        )
    }
}

export default Edit