import React, { Component } from 'react'
import Main from "../../components/layouts/Main";
import Password from "../../components/MasterPassword/Password"
// import PasswordComponent from '../../components/Password/Password';
export class RolePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Main>
                <Password />
            </Main>

        )
    }
}

export default RolePassword;