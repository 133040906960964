import { auth } from "./auth/auth.reducer";
import { master } from "./master/master.reducer";
import { sports } from "./sports/sport.reducer";
import { userProfileReducer } from "./userProfile/userProfile.reducer";
import { socketDataReducer } from "./socket/socketreducer";

import { combineReducers } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { league } from "./League/league.reducer";
const rootReducer = combineReducers({
  auth: persistReducer({ key: "userLogin", storage }, auth),
  master,
  sports,
  league,
  socketDataReducer,
  userProfile: userProfileReducer
  // auth
});
export default rootReducer;
