import React from "react";

function MarketView(props) {
  const {
    data,
    pindex,
    subCompData,
    subCompanyAllData,
    getValueForLagai,
    subCompanyliveData,
    getValueForKhai,
    fetchSubMarket,
    showAnnouncementRow,
    announcement,
    handleChange,
    updateMarketAnnouncement,
    clearMarketAnnouncement
  } = props;

  const userRole = JSON.parse(localStorage.getItem("userLogin"));
  return (
    <div className="row scroll-form" key={pindex}>
      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead>
            <tr>
              <th
                colSpan="12"
                // colSpan="5"
                className="bet-place-tbl-th align-left"
                style={{ backgroundColor: "#6E8FC9" }}
              >
                <div className="d-flex justify-between flex-center">
                  <p>Market : {data.market_name}</p>
                </div>
              </th>
            </tr>
            <tr>
              <th
                className="bet-place-tbl-th"
                style={{ backgroundColor: "#49494A" }}
              >
                RUNNER
              </th>
              {data.market_type !== 6 && data.market_type !== 7 ? (
                <>
                  <th className="bet-odds-type bet-place-tbl-th">LAGAI</th>
                  <th className="bet-odds-type bet-place-tbl-th">KHAI</th>
                </>

              ) : (
                <th className="bet-odds-type bet-place-tbl-th">RATE</th>
              )}

              <th
                className="bet-place-tbl-th"
                style={{ backgroundColor: "#49494A" }}
              >
                POSITION
              </th>

              {subCompData &&
                subCompData.map((el, ind) => {
                  let temp;
                  subCompanyAllData.filter((element) => {
                    if (element.market_id == data.market_id) {
                      return (temp = el.username);
                    }
                  });
                  return (
                    <th
                      key={ind}
                      className="bet-place-tbl-th"
                      style={{
                        backgroundColor: "#49494A",
                      }}
                    >
                      {temp}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {data.runners && data.runners.length
              ? data.runners.map((val, ind) => {
                return (
                  <tr key={ind}>
                    <td
                      data-runner-sid="Durham _405125"
                      data-column="runner_name"
                      className="bet-place-tbl-td"
                      style={{ textAlign: "left" }}
                    >
                      {val.runnerName}
                    </td>
                    {data.market_type !== 6 && data.market_type !== 7 ? (
                      <>
                        <td
                          data-runner-sid="Durham _405125"
                          data-column="back"
                          className="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer "
                          odd-val="1.95"
                          market-id="482169"
                          runner-id="949481"
                          match-id="405125"
                          data-id="LAGAI"
                          id="Match Winner_405125_back_Durham _405125"
                        >
                          {getValueForLagai(
                            data,
                            val,
                            ind,
                            subCompanyliveData
                          )}
                        </td>
                        <td
                          data-runner-sid="Durham _405125"
                          data-column="lay"
                          className="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer "
                          odd-val="1.97"
                          market-id="482169"
                          runner-id="949481"
                          match-id="405125"
                          data-id="KHAI"
                          id="Match Winner_405125_lay_Durham _405125"
                        >
                          {getValueForKhai(data, val, subCompanyliveData)}
                        </td>
                      </>
                    ) : (
                      <td
                        data-runner-sid="Durham _405125"
                        data-column="back"
                        className="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer "
                        odd-val="1.95"
                        market-id="482169"
                        runner-id="949481"
                        match-id="405125"
                        data-id="LAGAI"
                        id="Match Winner_405125_back_Durham _405125"
                      >
                        {getValueForLagai(data, val, ind, subCompanyliveData)}
                      </td>
                    )}
                    <td
                      data-runner-sid="Durham _405125"
                      data-column="position"
                      className="bet-place-tbl-td  bet-val-cent"
                      data-position="-290.429321"
                      style={{
                        color:
                          fetchSubMarket(data.market_id, val.selectionId) >= 0
                            ? "#228C22"
                            : "#FF0000",
                      }}
                    >
                      {fetchSubMarket(data.market_id, val.selectionId)}
                    </td>

                    {subCompanyAllData.map((item) => {
                      if (val.selectionId === item.selectionId) {
                        return item.sub_comp.map((i, k) => {
                          return (
                            <td
                              key={k}
                              data-runner-sid="Durham _405125"
                              data-column="position"
                              className="bet-place-tbl-td  bet-val-cent"
                              style={{
                                color: i.pl >= 0 ? "#228C22" : "#FF0000",
                              }}
                            >
                              {i.pl.toFixed(2)}
                            </td>
                          );
                        });
                      }
                    })}
                  </tr>
                );
              })
              : null}
          </tbody>
          <tfoot>
            <tr>
              {data.announcement &&
                <th colSpan={4} className="marquee">
                  <marquee behavior="" direction="">{data.announcement}</marquee>
                </th>
              }
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default MarketView;
