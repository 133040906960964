import { masterConstants } from "_constants";
import { getStorage } from "../../utils";

let initialState = {
  mastersList: {},
  isLoader: false,
};

export function master(state = initialState, action) {
  switch (action.type) {
    case masterConstants.MASTER_CREATE_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case masterConstants.MASTER_CREATE_SUCCESS:
      return {
        ...state,
        isLoader: false,
      };
    case masterConstants.MASTER_CREATE_FAILED:
      return {
        ...state,
        isLoader: false,
      };
    case masterConstants.MASTER_LIST_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case masterConstants.MASTER_LIST_SUCCESS:
      return {
        ...state,
        mastersList: action.data,
        isLoader: false,
      };
    case masterConstants.MASTER_LIST_FAILED:
      return {
        ...state,
        isLoader: false,
      };
    default:
      return state;
  }
}
