import {  } from "../../_constants";
import { sportConstants } from "../../_constants";
import { create, getSportList } from "../../_services";
import { toast } from "react-toastify";

export const listSport = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: sportConstants.SPORTS_LIST_REQUEST, data: {} });
    getSportList(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: sportConstants.SPORTS_LIST_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: sportConstants.SPORTS_LIST_FAILED,
          data: error.response,
        });
      });
  };
};
