import { leagueConstants } from '../../_constants';

let initialState = {
  leagueList: {},
  isLoader: false,
};

export function league(state = initialState, action) {
  switch (action.type) {
    case leagueConstants.LEAGUE_CREATE_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case leagueConstants.LEAGUE_CREATE_SUCCESS:
      return {
        ...state,
        isLoader: false,
      };
    case leagueConstants.LEAGUE_CREATE_FAILED:
      return {
        ...state,
        isLoader: false,
      };
    case leagueConstants.LEAGUE_LIST_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case leagueConstants.LEAGUE_LIST_SUCCESS:
      return {
        ...state,
        mastersList: action.data,
        isLoader: false,
      };
    case leagueConstants.LEAGUE_LIST_FAILED:
      return {
        ...state,
        isLoader: false,
      };
    default:
      return state;
  }
}
