import { getFastMarketWinning, getMarketWinning } from "_services/matkaservice";
import Main from "components/layouts/Main";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function RulesComponent() {
  const [marketWinnerList, setMarketWinnerList] = useState([]);
  const [fastMarketWinnerList, setFastMarketWinnerList] = useState([]);
  useEffect(() => {
    getMarketWinnerList();
    getFastWinnerList();
  }, []);
  const getMarketWinnerList = async () => {
    try {
      const res = await getMarketWinning();
      if (res?.data?.data) {
        setMarketWinnerList(res.data.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };
  const getFastWinnerList = async () => {
    try {
      const res = await getFastMarketWinning();
      if (res?.data?.data) {
        setFastMarketWinnerList(res.data.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };
  return (
    <Main>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins rules-main-container px-2 ">
              <div className="ibox-content">
                <div className="table-responsive">
                  <MatkaInfo marketWinnerList={marketWinnerList} />
                  <hr />
                  <FastMatkaInfo fastMarketWinnerList={fastMarketWinnerList} />
                  <hr />
                  <table className="table table-striped table-bordered table-hover dataTables-example">
                    <thead>
                      <tr>
                        <th colSpan={2}>
                          Game bazar me diye hua time se 10 minutes pehle bet
                          band hongi
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
}

const MatkaInfo = ({ marketWinnerList }) => {
  return (
    <table className="table table-striped table-bordered table-hover dataTables-example">
      <thead>
        <tr>
          <th colSpan={2}>Matka Information</th>
        </tr>
      </thead>
      <tbody>
        {marketWinnerList?.map((item) => (
          <tr key={item?.type}>
            <td>{item?.type}</td>
            <td>100-{(+item?.winning_multiple -1)*100 }</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const FastMatkaInfo = ({fastMarketWinnerList}) => {
  return (
    <table className="table table-striped table-bordered table-hover dataTables-example">
      <thead>
        <tr>
          <th colSpan={2}>Fast Matka Information</th>
        </tr>
      </thead>
      <tbody>
      {fastMarketWinnerList?.map((item) => (
          <tr key={item?.type}>
            <td>{item?.type}</td>
            <td>100-{(+item?.winning_multiple -1)*100 }</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
