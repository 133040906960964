import React from "react";

function DashboardCard({ title, text ,lastName}) {
  return (
    <div className="DashboardCard">
      <div className="card-title6"><h5>{title}</h5></div>
      <hr />
      <div className="card-text">
        <h1>{text}</h1>
        <small>{lastName}</small>
        {/* <h6></h6> */}
      </div>
    </div>
  );
}

export default DashboardCard;
