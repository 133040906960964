import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
import './Staff.css';
export class CreateGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateData: props.stateData,
            data: false
        }

    }
    handleChange = (e) => {
        let label = e.target.name;
        // let value = e.target.value;
        if (label === 'main') {
            this.setState({ data: this.state.data ? false : true })
        }
    }


    onSubmit(e) {
        e.preventDefault();
        alert('submit from');
        window.history.back()
    }

    render() {
        const { stateData } = this.state;
        return (
            <Fragment>
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-lg-10">
                        <h2>{`${stateData.edit ? 'EDIT' : 'CREATE'} GROUP`}</h2>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/privileges_group">Privileges Group</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <strong>{`${stateData.edit ? 'Edit' : 'Create'} Group`}</strong>
                            </li>
                        </ol>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
                <div className="wrapper wrapper-content animated fadeInRight">
                    <div className="row">
                        <div className="col-12">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <h5>{`${stateData.edit ? 'Edit' : 'Create'} Group`}</h5>
                                </div>
                                <div className="ibox-content">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-responsive  table-striped dataTables-example">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '5%' }}>Checkbox</th>
                                                            <th>Fields</th>
                                                            <th style={{ width: '10%' }} className="text-center">List</th>
                                                            <th style={{ width: '10%' }} className="text-center">Create</th>
                                                            <th style={{ width: '10%' }} className="text-center">Edit</th>
                                                            <th style={{ width: '10%' }} className="text-center">Delete</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th className="text-center">
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    className="form-control dataType "
                                                                    checked={this.state.data}
                                                                    name="main"
                                                                />
                                                            </th>
                                                            <td>Dashboard</td>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    className="form-control dataType "
                                                                    disabled={!this.state.data}
                                                                    name="list"
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    className="form-control dataType "
                                                                    disabled={!this.state.data}
                                                                    name="create"
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    className="form-control dataType "
                                                                    disabled={!this.state.data}
                                                                    name="edit"
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    className="form-control dataType "
                                                                    disabled={!this.state.data}
                                                                    name="delete"
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm"
                                                onChange={(e) => this.onSubmit(e)}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >

        )
    }
}

export default CreateGroup