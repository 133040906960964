export const authConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  FAILED_RESPONSE: 'FAILED_RESPONSE',
  FORGOT_REQUEST:'FORGOT_REQUEST',
  FORGOT_SUCCESS:'FORGOT_SUCCESS',
  FORGOT_FAILED:'FORGOT_FAILED',
  RESET_REQUEST:'RESET_REQUEST',
  RESET_SUCCESS:'RESET_SUCCESS',
  SET_KEY:'SET_KEY',
  CLEAR_STATE:'CLEAR_STATE',
  LOGOUT_REQUEST:'LOGOUT_REQUEST',
  LOGOUT_SUCCESS:'LOGOUT_SUCCESS',
  LOGOUT_FAILED:'LOGOUT_FAILED',
  CONFIGURATION_REQUEST:'CONFIGURATION_REQUEST',
  CONFIGURATION_SUCCESS:'CONFIGURATION_SUCCESS',
  CONFIGURATION_FAILED:'CONFIGURATION_FAILED',
  CREATE_PASSWORD_REQUEST:'CREATE_PASSWORD_REQUEST',
  CREATE_PASSWORD_SUCCESS:'CREATE_PASSWORD_SUCCESS'
}