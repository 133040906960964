import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";
import config from "../lib/app-config";

export const settleMarket = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}settlement/settle_market`,
    data
  );
};

export const cancelMarket = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}cancelMarket`,
    data
  );
};


export const cancelFancy = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}cancelFancy`,
    data
  );
};
