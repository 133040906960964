
let initialState = {
  socketData:''
};

export function socketDataReducer(state = initialState, action) {
  switch (action.type) {
    
      case 'SOCKET_DATA':
      return {
        ...state,
        socketData: action.payload,
      };
    default:
      return state;
  }
}
