import React, { Fragment, Component } from "react";
import { location, withRouter } from "react-router-dom";
import Main from "../../../components/layouts/Main";
import Master from "../../../components/ManualMarkets/List";
import withAuth from "../../../_helpers/withAuth";
import { connect } from "react-redux";

class ManualMarkets extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Main>
        <Master {...this.props} />
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
   ...state,
});

export default connect(mapstatetop)(ManualMarkets);
