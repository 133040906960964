import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";
import "./userview.css";
import { getEventMarket } from "_services";
import { Link } from "react-router-dom";

function MatchesListIdComponent(props) {
  const location = useLocation();
  const { matchData } = location.state;
  const { history } = props;
  const { id } = useParams()
  const userLogin = JSON.parse(localStorage.getItem("userLogin"));

  const [subCompanyLiveReportPage, setSubCompanyLiveReportPage] = useState(userLogin);
  const [eventMarketData, updateEventMarketData] = useState(null);
  
  useEffect(() => {
    let sendData = {};
    sendData.event_id = id;
    getEventMarket(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {
          updateEventMarketData(response.data.data);
        } else {
        }
      })
      .catch((error) => {
      });
  }, []);


  return (
    <div>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>Match Dashboard</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>

            <li className="breadcrumb-item">
              <Link to={"/matches_list?sport_id="+matchData?.sport_id}>Matches List</Link>
            </li>

            <li className="breadcrumb-item active">
              <strong> {matchData?.event_name} </strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2"></div>
      </div>

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h1>Agent Match Dashboard</h1>
              </div>
              <div className="ibox-content table-responsive">
                <div style={{ textAlign: "center" }}>
                  <a
                    href={`/bet_slips/${id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/bet_slips/${id}`,
                        state: { matchData },
                      });
                    }}
                    className="mr-25"
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Bet Slips
                    </button>
                  </a>


                  <a
                    href={`/session-bet-slips/${id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/session-bet-slips/${id}`,
                        state: { matchData },
                      });
                    }}
                    className="mr-25"
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Session Bet Slip
                    </button>
                  </a>

                  <a
                    href={`/live_report/${id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/live_report/${id}`,
                        state: { matchData },
                      });
                    }}
                    className="mr-25"
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Live Report
                    </button>
                  </a>

                  <a
                    href={`/company_report/${id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/company_report/${id}`,
                        state: { matchData },
                      });
                    }}
                    className="mr-25"
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Company Report
                    </button>
                  </a>

                  <a
                    href={`/client_report/${id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/client_report/${id}`,
                        state: { matchData },
                      });
                    }}
                    className="mr-25"
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Client Report
                    </button>
                  </a>

                  <a
                    href={`/collection_report/${id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/collection_report/${id}`,
                        state: { matchData },
                      });
                    }}
                    className="mr-25"
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Collection Report
                    </button>
                  </a>

                  {/* <a
                    href="/session_ending_report"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/session_ending_report`,
                        state: { matchData },
                      });
                    }}
                    className="mr-25"
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Session Earning Report
                    </button>
                  </a> */}

                  {(subCompanyLiveReportPage?.role == 1 ||
                    subCompanyLiveReportPage?.role == 2) && (matchData?.sport_id != 999 && matchData?.sport_id != 777) ? (
                    <a
                      href={`/subcompany_live_report/${id}`}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push({
                          pathname: `/subcompany_live_report/${id}`,
                          state: { matchData },
                        });
                      }}
                      className="mr-25"
                    >
                      <button type="button" className="btn btn-primary btn-lg">
                        Sub Company Live Report
                      </button>
                    </a>
                  ) : null}

                  {(subCompanyLiveReportPage?.role == 1 || subCompanyLiveReportPage?.role == 8) ? (
                    <label className="dropdown">
                      <div className="dd-button btn btn-primary btn-lg">
                        Settings
                      </div>

                      <input type="checkbox" className="dd-input" id="test" />

                      <ul className="dd-menu" style={{ paddingBottom: 20 }}>
                        <li
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: `/commentary/${id}`,
                              state: { matchData },
                            });
                          }}
                        >
                          Commentary
                        </li>
                        <li
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: `/newsupdates/${id}`,
                              state: { matchData },
                            });
                          }}
                        >
                          News/Updates
                        </li>
                        <li
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: `/stake_delay/${id}`,
                              state: { matchData },
                            });
                          }}
                        >
                          Stake Delay
                        </li>
                        <li
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: `/scorewidget/${id}`,
                              state: { matchData },
                            });
                          }}
                        >
                          Score Widget
                        </li>
                        <li
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: `/livetv/${id}`,
                              state: { matchData },
                            });
                          }}
                        >
                          Live TV
                        </li>

                        <li
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: `/eventtype/${id}`,
                              state: { matchData },
                            });
                          }}
                        >
                          Event type
                        </li>
                        <li
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: `/scoreboard/${id}`,
                              state: { matchData },
                            });
                          }}
                        >
                          Scoreboard
                        </li>
                        <li
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: `/match_id/${id}`,
                              state: { matchData },
                            });
                          }}
                        >
                          Match ID
                        </li>
                      </ul>
                    </label>
                  ) :

                    null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Match Markets</h5>
                <div className="ibox-tools"></div>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Betfair Market Id</th>
                      <th>Betfair Market Type</th>
                      <th>RUNNER</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventMarketData && eventMarketData.market &&
                      eventMarketData.market.map((evetMarketItem, index) => {
                        return <tr key={evetMarketItem.market_id}>
                          <td>{evetMarketItem.market_id}</td>
                          <td>
                            <a
                              href={`/bet_slips/${id}`}
                              onClick={(e) => {
                                e.preventDefault();
                                history.push({
                                  pathname: `/bet_slips/${id}`,
                                  state: { matchData },
                                });
                              }}
                            >
                              {evetMarketItem.betfair_mkt_id}
                            </a>
                          </td>
                          <td>{evetMarketItem.market_name}</td>
                          <td>{evetMarketItem.runners}</td>
                        </tr>
                      })
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Match Sessions</h5>
                <div className="ibox-tools"></div>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Session ID</th>
                      <th>Session Market</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventMarketData && eventMarketData.fancy &&
                      eventMarketData.fancy.map((evetMarketItem, index) => {
                        return <tr key={evetMarketItem.market_id + index}>
                          <td>{evetMarketItem.session_id}</td>
                          <td>
                            <a
                              href={`/session-bet-slips/${id}`}
                              onClick={(e) => {
                                e.preventDefault();
                                history.push({
                                  pathname: `/session-bet-slips/${id}`,
                                  state: { matchData },
                                });
                              }}
                            >
                              {evetMarketItem.runner_name}
                            </a>
                          </td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MatchesListIdComponent;
