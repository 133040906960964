import React, { Component } from "react";
import InnerPageHeader from "./InnerPageHeader";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import {
  usersDirectAgent,
  usersDirectClient,
} from "_services/userDetails.services";

class DirectAgentsClients extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    usersList: [],
    totalRecords: 0,
    current_page: 1,
    items_perpage: 100,
  };

  componentDidMount() {
    const { type, subtype, id } = this.props.match.params;
    if (subtype) {
      if (subtype === "directclients") {
        this.fetchDirectClients();
      }
      if (subtype === "directagents") {
        this.fetchDirectAgents();
      }
    }
  }

  fetchDirectAgents = () => {
    const { id } = this.props.match.params;
    let sendObj = {};
    sendObj.current_page = this.state.current_page;
    sendObj.items_perpage = this.state.items_perpage;
    sendObj.user_guid = id;
    usersDirectAgent(sendObj)
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.total_records) {
          this.setState({
            usersList: res.data.data.userdownlist,
            totalRecords: res.data.data.total_records,
          });
        }
      })
      .catch((error) => {});
  };

  fetchDirectClients = () => {
    const { id } = this.props.match.params;
    let sendObj = {};
    sendObj.current_page = this.state.current_page;
    sendObj.items_perpage = this.state.items_perpage;
    sendObj.user_guid = id;
    usersDirectClient(sendObj)
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.total_records) {
          this.setState({
            usersList: res.data.data.userdownlist,
            totalRecords: res.data.data.total_records,
          });
        }
      })
      .catch((error) => {});
  };

  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "User";
    }
  }

  render() {
    const { usersList, totalRecords } = this.state;
    const { type, subtype, id, username } = this.props.match.params;
   
    return (
      <>
        <InnerPageHeader
          pageTitle= {type}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: type, url: `/userview/${type}/${id}/${username}`},
            // { title: this.capitalizeFirstLetter(type), url: "/master" },
          ]}
          currenPageTitle={
            subtype
              ? subtype === "directagents"
                ? "Direct Agents"
                : "Direct Clients"
              : null
          }
        />

        <div className="row">
          <div
            id="reportrange"
            className="my_ledgers_dated_picker col-lg-3 col-md-4 col-sm-6 col-xs-11"
          >
            <input
              className="form-control"
              type="text"
              name="daterange"
              value="01/01/2015 - 01/31/2015"
            />
            {/* <i className="glyphicon glyphicon-calendar fa fa-calendar"></i>
            &nbsp;
            <span> - </span>
            <b className="caret"></b> */}
          </div>
          <div
            className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
            style={{ margin: "11px 0px" }}
          >
            <a className="btn btn-primary btn-xs search_date_btn">Search</a>
          </div>
        </div>

        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>All Agents</h5>
                  <div className="ibox-tools">
                    <a
                      href="/agent/agents/new"
                      className="btn btn-primary btn-xs"
                    >
                      Create new Agent
                    </a>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>User Name</th>
                          <th>Name</th>
                          <th>Agent Under</th>
                          {/* <th>Actions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {usersList && usersList.length ? (
                          usersList.map((user, index) => (
                            <tr key={index}>
                              <td id="getdate_2794093">{user.user_id}</td>
                              <td>
                                <a
                                  href={`/userview/collection-report/${user.user_guid}/${user.username}`}
                                >
                                {user.username}({user.first_name} {user.last_name})
                                </a>
                              </td>
                              <td>{`${user.first_name} ${user.last_name}`}</td>
                              <td>-</td>

                              {/* <td>
                                <a
                                  className="btn btn-white btn-sm"
                                  href="/agent/agents/4410/edit"
                                >
                                  <i className="fa fa-pencil-square"></i> Edit
                                </a>
                                &nbsp;&nbsp; &nbsp;&nbsp;
                                <a
                                  className="delete_3117 btn btn-danger btn-sm"
                                  data-confirm="Are you sure?"
                                  data-method="delete"
                                  data-remote="true"
                                  href="/agent/agents/3117"
                                  rel="nofollow"
                                >
                                  <i className="fa fa-trash-o"></i>
                                </a>
                              </td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5}>No data available in table</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default DirectAgentsClients;
