import React, { useState, useEffect } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import InnerPageHeader from "components/common/InnerPageHeader";
import { updateEventType, getEventText } from "_services";
import { useSelector } from "react-redux";
import "./style.css";
import { toast } from "react-toastify";

export default function EventType() {

    const [isLoading, setIsLoading] = useState(false);
    const [eventTextName, setEventTextName] = useState("")

    const location = useLocation();

    const { matchData } = location && location.state ? location.state : {};

    const { event_id } = useParams();

    const userLogin = JSON.parse(localStorage.getItem("userLogin"));

    useEffect(() => {
        if (event_id) {
            let sendData = {};
            sendData.event_id = event_id;

            getEventText(sendData)
                .then((response) => {

                    setEventTextName(response?.data?.data?.event?.event_text)

                }).catch((error) => { })
        }
    }, [])

    const onSubmit = (e) => {
        e.preventDefault();

        let sendData = {};
        sendData.event_id = event_id;
        sendData.event_text = eventTextName;

        setIsLoading(true);

        updateEventType(sendData)
            .then((response) => {

                // toast.success(response.data.message, "Event Message");
                toast.success(
                    <div>
                      <i
                        className="fa fa-check"
                        style={{ fontWeight: "bold", marginRight: "1rem" }}
                        aria-hidden="true"
                      ></i>
                      <span>{response.data.message}</span>
                    </div>,
                    {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: false,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    }
                  );

                setTimeout(() => {
                    setIsLoading(false)
                }, 1500);

            }).catch((error) => {
                toast.error("Error - Event Message");
                setIsLoading(false)
            })
    }

    return (
        <>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10">
                    <h2> Event Type </h2>
                    <ol className="breadcrumb">
                        <li>
                            <a href="/">Dashboard</a>
                        </li>
                        <li>
                            <a href="/matches_list">Matches</a>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link
                                to={{
                                    pathname: `/match_dashboard/${event_id}`,
                                    state: { matchData: matchData },
                                }}
                            >
                                {matchData?.event_name}
                            </Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong> EventType </strong>
                        </li>
                    </ol>
                </div>
                <div className="col-lg-2"></div>
            </div>
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-12">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5> Event Type </h5>
                            </div>
                            <div className="ibox-content">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <form>
                                            <div className="form-group row">
                                                <label
                                                    htmlFor="inputAgentAnnouncement"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Event type
                                                </label>
                                                <div className="col-sm-10">
                                                    <textarea
                                                        type="textarea"
                                                        name="clientAnnouncement"
                                                        className="form-control"
                                                        id="inputAgentAnnouncement"
                                                        placeholder="Event type"
                                                        value={eventTextName}
                                                        onChange={(e) => setEventTextName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                        <div className="form-group row">
                                            <div className="col-sm-4 col-sm-offset-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    onClick={(e) => !isLoading && onSubmit(e)}
                                                >
                                                    Save Changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="render-sub-company-live-report">
                <div className="wrapper wrapper-content">
                    <div className="row" style={{ margin: "0 !important" }}>
                        <div className="match-index-row header-top-spacing">
                            <div
                                className="row"
                                style={{ paddingBottom: "30px", margin: "auto auto" }}
                            ></div>
                        </div>
                    </div>

                    <div className="wrapper wrapper-content">
                        <div className="row">
                            <div className="col-lg-12"></div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

