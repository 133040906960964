import React, { Component } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import { Link } from "react-router-dom";
import Pagination from "../common/Pagination";
import { listRequest } from "../../_actions/manualmarkets/manualmarkets.actions";
import moment from "moment";
import {formatDateAndTime} from "../../utils/index"

class List extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    manualmarketList: [],
    role: 3,
    currentPage: 1,
    items_perpage: 10,

  };
  componentDidMount() {
    let sendObj = {
      user_guid: "",
      current_page: 1,
      items_perpage: 10,
      keyword: "",
      role: this.state.role,
    };
    this.props.dispatch(
      listRequest(sendObj, (res) => {
        //console.log("res.data"+res.data);
        if (res && res.data) {
          this.setState({
            manualmarketList: res.data,
            //totalRecords: res.data.total_records,
          });
        }
      })
    );
  }

  render() {
    const { manualmarketList, currentPage, items_perpage} = this.state;
    return (
      <>
        <InnerPageHeader
          pageTitle="Manual Markets"
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Manual Markets", url: "/manual-markets" },
          ]}
          currenPageTitle="List"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>Manual Matches</h5>
                  <div className="ibox-tools">
                    <a
                      href="/manual-markets/add"
                      className="btn btn-primary btn-xs"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/manual-markets/add");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Create Manual Match
                    </a>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Title</th>
                            <th>Sport</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {manualmarketList && manualmarketList.length ? (
                            manualmarketList.map((user, index) => (
                              <tr className="gradeX" key={index}>
                                <td>{(index + 1) + ((currentPage - 1) * items_perpage)}</td>
                                <td>
                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.history.push(
                                        `/manual-market-match-dashboard/list/${user.event_id}/${user.event_name}`
                                      );
                                    }}
                                  >
                                    {user.event_name}
                                  </a>
                                </td>
                                <td>{user.sports_name}</td>
                                <td className="center">
                                <td className="center">{formatDateAndTime('MMM DD, hh:mm:ss A' ,user.open_date)}</td>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="gradeX">
                              <td colSpan={4}>No data available in table</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default List;
