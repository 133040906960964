import React, { Component } from 'react'
import Main from "../../components/layouts/Main";
import SubCompany from "../../components/SubCompany/List";
import { connect } from 'react-redux';

export class BlockedSubCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Main>
                <SubCompany title="Subcompanies Blocked List" isBlocked={true} {...this.props} />
            </Main>

        )
    }
}

const mapstatetop = (state) => ({
    ...state,
});

export default connect(mapstatetop)(BlockedSubCompany);