import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import AddUser from "components/common/AddUser";
import { createRequest } from "_actions/agent/agent.actions";

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 7,
      updateData: "",
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    if (this.props.location?.state?.selectedData?.user_guid) {
      this.setState({
        updateData: this.props.location.state.selectedData,
      });
    }
  }

  addAgent = (sendObj) => {
    let clientData = {};
    clientData.username = sendObj.username;
    clientData.first_name = sendObj.first_name;
    clientData.last_name = sendObj.last_name;
    clientData.fixed_limit = sendObj.fixed_limit;
    clientData.my_share = sendObj.my_share;
    clientData.match_commission = sendObj.match_commission;
    clientData.session_commission = sendObj.session_commission;
    clientData.role = this.state.role;
    clientData.password = sendObj.password;
    clientData.confirm_password = sendObj.confirm_password;
    sendObj.role = this.state.role;
    this.props.dispatch(
      createRequest(sendObj, (res) => {
        this.props.history.push("/my-clients");
      })
    );
  };

  render() {
    let { redirect, updateData } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <>
        <AddUser
          heading={
            updateData && updateData.username ? updateData.username : "New User"
          }
          breadcrumbTitle={
            updateData && updateData.username ? updateData.username : "New User"
          }
          breadcrumb="User"
          navigationUrl="/my-clients"
          onSubmitFunc={this.addAgent}
          role={this.state.role}
          action={updateData ? "Edit Client" : "New Client"}
          history={this.props.history}
          parentType="client"
          disableMyShare={true}
          isEdit={Boolean(updateData)}
          updateData={updateData}
          {...this.props}
        />
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
