import React, { Component } from "react";
import Main from "../../components/layouts/Main";
import { connect } from "react-redux";
import GlobalSettingsComponent from "components/GlobalSettings/GlobalSettingsComponent";

class GlobalSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Main>
        <GlobalSettingsComponent {...this.props} />
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(GlobalSettings);
