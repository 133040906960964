import React, { useCallback, useEffect, useState } from "react";
import io from "socket.io-client";
import { useDispatch } from "react-redux";

import config from "../../lib/app-config";
function SocketHelper(props) {
  const { marketList, closeAllRoom, event_id } = props
  const dispatch = useDispatch();
  const [socketConnection, setSocketConnection] = useState(false)
  const [roomCreated, setRoomCreated] = useState(false)

  useEffect(() => {
    if (socketConnection.connected && marketList.length) {
      if (roomCreated) {
        createRoom(socketConnection, marketList);
      } else {
        setTimeout(() => {
          createRoom(socketConnection, marketList);
        }, 1000)
      }
    }
  }, [closeAllRoom, marketList, socketConnection])

  const createConnection = useCallback((socket) => {

    socket.on("connect", () => {
      console.log("socket connected", socket.connected); // true
      setSocketConnection(socket)
    });
    socket.on("updateMarketOdds", handleupdateMarketOdds);
    socket.on("updateFancyOdds", handleupdateFancyOdds);
    socket.on("addUpdateFancy", handleaddUpdateFancy);
    socket.on("updateScore", handleupdateScore);
    socket.on("bet_placed", handlebet_placed);
    socket.on("updateCommentary", handleUpdateCommentary);
    socket.on("updateUserLockStatus", handleUpdateUserLockStatus);
    socket.on("reloadPage", handleReloadPageStatus);
    socket.on("settlement_done", handleSettlementDone);
    socket.on("allActiveFancy", handleAllActiveFancy);
    socket.on("logged_count", handleLoggedCount);
    socket.on("bets_count", handlebetsCount)
    socket.on("rollback", handleRollbackCount)
    socket.on("marketAnnouncement", handleMarketAnnouncement)

  }, [])

  useEffect(() => {
    let socket = io(config.socketurl, {
      reconnectionDelayMax: 100000,
      extraHeaders: {},
    });
    createConnection(socket)
    return () => {
      closeRoom(socket, marketList);
      socket.off("updateMarketOdds", handleupdateMarketOdds);
      socket.off("updateFancyOdds", handleupdateFancyOdds);
      socket.off("addUpdateFancy", handleaddUpdateFancy);
      socket.off("updateScore", handleupdateScore);
      socket.off("bet_placed", handlebet_placed);
      socket.off("updateCommentary", handleUpdateCommentary);
      socket.off("updateUserLockStatus", handleUpdateUserLockStatus);
      socket.off("reloadPage", handleReloadPageStatus);
      socket.off("settlement_done", handleSettlementDone);
      socket.off("allActiveFancy", handleAllActiveFancy);
      socket.off("logged_count", handleLoggedCount);
      socket.off("rollback", handleRollbackCount)
      socket.off("marketAnnouncement", handleMarketAnnouncement)
      socket.off();
      socket.disconnect();
    };
  }, [createConnection]);

  const handleupdateMarketOdds = (payload) => {
    if (payload) {
      dispatch({ type: "SOCKET_DATA", payload: { updateMarketOdds: payload } });
    }
  };

  const handleupdateFancyOdds = (payload) => {
    if (payload) {
      dispatch({ type: "SOCKET_DATA", payload: { updateFancyOdds: payload } });
    }
  };

  const handleaddUpdateFancy = (payload) => {
    if (payload) {
      dispatch({ type: "SOCKET_DATA", payload: { addUpdateFancy: payload } });
    }
  };

  const handleupdateScore = (payload) => {
    if (payload) {
      dispatch({ type: "SOCKET_DATA", payload: { updateScore: payload } });
    }
  };

  const handlebet_placed = (payload) => {
    if (payload) {
      dispatch({ type: "SOCKET_DATA", payload: { bet_placed: payload } });
    }
  };

  const handleUpdateCommentary = (payload) => {
    if (payload) {
      dispatch({ type: "SOCKET_DATA", payload: { updateCommentary: payload } });
    }
  };


  const createRoom = (socket, market) => {
    for (let i = 0; i < market.length; i++) {
      socket.emit('market_room', event_id + "_" + market[i]);
    }
    setRoomCreated(true)
  }

  const closeRoom = (socket, market) => {
    if(market?.length){
      const list = market.map(mk => event_id ? event_id+"_"+mk : mk)
      socket.emit('unsubscribe', list);
    }
  }

  const handleUpdateUserLockStatus = (payload) => {
    if (payload) {
      dispatch({
        type: "SOCKET_DATA",
        payload: { updateUserLockStatus: payload },
      });
    }
  };

  const handleReloadPageStatus = (payload) => {
    if (payload) {
      dispatch({
        type: "SOCKET_DATA",
        payload: { reloadPage: true },
      });
    }
  };


  const handleSettlementDone = (payload) => {
    if (payload) {
      dispatch({
        type: "SOCKET_DATA",
        payload: { settlementDone: payload },
      });
    }
  };

  const handleAllActiveFancy = (payload) => {
    if (payload) {
      dispatch({ type: "SOCKET_DATA", payload: { allActiveFancy: payload } });
    }
  };

  const handleLoggedCount = (payload) => {
    if (payload) {
      dispatch({ type: "SOCKET_DATA", payload: { loggedCount: payload } });
    }
  };
  const handlebetsCount = (payload) => {
    if (payload) {
      dispatch({ type: "SOCKET_DATA", payload: { betsCount: payload } });
    }
  };

  const handleRollbackCount = (payload) => {
    if (payload) {
      dispatch({ type: "SOCKET_DATA", payload: { rollback: payload } });
    }
  };
  const handleMarketAnnouncement = (payload) => {
    if (payload) {
      dispatch({ type: "SOCKET_DATA", payload: { marketAnnouncement: payload } });
    }
  };

  return <></>;
}
export default React.memo(SocketHelper);