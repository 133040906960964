const apiEndPoints = {
  methodType: {
    PUT: "put",
    GET: "get",
    POST: "post",
    DELETE: "delete",
    PATCH: "patch",
  },
  listUsers: "/users/downline",
  userProfile: "/users/profile",
};

export default apiEndPoints;
