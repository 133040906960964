import InnerPageHeader from "components/common/InnerPageHeader";
import SectionTitle from "components/common/SectionTitle";
import TagAndChips from "components/common/TagAndChips";
import { Button } from "react-bootstrap";
import { useLocation, Link, useHistory } from "react-router-dom";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getAgentReport } from "_services";
import { getUserReport } from "_services";
import { getSubCompanyReport } from "_services/sports.services"
import { convertParseFloat } from "_helpers";

function ClientReport() {
  const history = useHistory();
  const location = useLocation();
  const { matchData } = location.state;

  const userLogin = JSON.parse(localStorage.getItem("userLogin"));

  const [subCompanyPage, setSubCompanyPage] = useState(userLogin)

  const { event_id } = useParams();

  // ------------- sub company report -------------

  const [subCompanyReportList, setSubCompanyReportList] = useState([]);

  useEffect(() => {
    if (event_id) {
      fetchSubCompanyReport();
    }
  }, []);

  const fetchSubCompanyReport = () => {
    let sendData = {};
    sendData.current_page = 1;
    sendData.items_perpage = 100;
    sendData.event_id = event_id;
    getSubCompanyReport(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.sc_report &&
          response.data.data.sc_report.length
        ) {
          setSubCompanyReportList(response.data.data.sc_report);
        } else {
          setSubCompanyReportList([]);
        }
      })
      .catch((error) => {
        setSubCompanyReportList([]);
      });
  };

  // -------------- user report --------------

  const [userReportList, setUserReportList] = useState([]);

  useEffect(() => {
    if (event_id) {
      fetchUserReport();
    }
  }, []);

  const fetchUserReport = () => {
    let sendData = {};
    sendData.current_page = 1;
    sendData.items_perpage = 100;
    sendData.event_id = event_id;
    getUserReport(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.user_report &&
          response.data.data.user_report.length
        ) {
          setUserReportList(response.data.data.user_report);
        } else {
          setUserReportList([]);
        }
      })
      .catch((error) => {
        setUserReportList([]);
      });
  };

  // --------------- agent report ---------------

  const [agentReportList, setAgentReportList] = useState([]);

  useEffect(() => {
    if (event_id) {
      fetchAgentReport();
    }
  }, []);

  const fetchAgentReport = () => {
    let sendData = {};
    sendData.current_page = 1;
    sendData.items_perpage = 100;
    sendData.event_id = event_id;
    getAgentReport(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.agent_report &&
          response.data.data.agent_report.length
        ) {
          setAgentReportList(response.data.data.agent_report);
        } else {
          setAgentReportList([]);
        }
      })
      .catch((error) => {
        setAgentReportList([]);
      });
  };

  return (
    <>
      <InnerPageHeader
        pageTitle="Client Report"
        breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: matchData.sports_id == "6" ? "Games list" : "Matches", url: matchData.sports_id == "6" ? "/game" : "/matches_list" },
          {
            title: matchData.event_name, url: {
              pathname: `/${matchData.sports_id == "6" ? "games_list" : "match_dashboard"}/${event_id}`,
              state: { matchData: matchData },
            }
          },
        ]}
        currenPageTitle="Client Report"
      />
      {
        subCompanyPage && (subCompanyPage.role === '1') ?
          (
            <div className="wrapper wrapper-content animated fadeInRight">
              <div className="row">
                <div className="col-lg-12">
                  <div className="ibox float-e-margins">
                    <div className="ibox-title">
                      <h5>Sub Company Report</h5>
                    </div>
                    <div className="ibox-content table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Sub Company</th>
                            <th>DATE / TIME</th>
                            <th>Company Match Earnings Share Percentage</th>
                            <th>Company Usage Charges Share Percentage</th>
                            <th>Sub Company Match Earnings</th>
                            <th>Sub Company Usage Charges Earnings</th>
                            <th>Company Match Earnings</th>
                            <th>Company Usage Charges Earnings</th>
                            <th>Sub Company Match Earnings After Company Share</th>
                            <th>Sub Company Usage Charges Earnings After Company Share</th>
                            <th>Sub Company Earnings After Company Share</th>
                            <th>Company Earnings</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                        <thead>
                          {
                            subCompanyReportList && subCompanyReportList.length ? (
                              subCompanyReportList.map((el, ind) => {
                                return (
                                  <tr key={ind}>

                                    {/* <th>
                                      <a
                                        href="/"
                                        onClick={(e) => {
                                          localStorage.setItem("username", JSON.stringify(el.name));
                                          e.preventDefault();
                                          history.push(`/userview/collection-report/${el.user_guid}/${el.username}`
                                          );
                                        }}
                                      >
                                        {el.username}
                                      </a>
                                    </th> */}
                                    <th>{el.username}  ({(el.first_name)}) </th>

                                    <th>{el.date}</th>
                                    <th>{convertParseFloat(Math.round((el.comp_share_per) * 100) / 100)}</th>
                                    <th> {convertParseFloat(Math.round((el.usage_share_per) * 100) / 100)}</th>
                                    <th>{convertParseFloat(Math.round((el.sc_match_pm) * 100) / 100)}</th>
                                    <th> {convertParseFloat(Math.round((el.sc_usage_pm) * 100) / 100)}</th>
                                    <th> {convertParseFloat(Math.round((el.comp_match_pm) * 100) / 100)} </th>
                                    <th>{convertParseFloat(Math.round((el.comp_usage_pm) * 100) / 100)} </th>
                                    <th> {convertParseFloat(Math.round((el.sc_share) * 100) / 100)}</th>
                                    <th>{convertParseFloat(Math.round((el.sc_usage_share) * 100) / 100)}</th>
                                    <th>{convertParseFloat(Math.round((el.sc_earning) * 100) / 100)}</th>
                                    <th>{convertParseFloat(Math.round((el.company_earning) * 100) / 100)} </th>
                                  </tr>
                                )
                              }))
                              :
                              (
                                null
                              )
                          }

                          <tr>
                            <th>Total</th>
                            <th> - </th>
                            <th> - </th>
                            <th> - </th>
                            <th>{convertParseFloat(Math.round(((subCompanyReportList?.reduce((a, v) => a = a + v.sc_match_pm, 0))) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round(((subCompanyReportList?.reduce((a, v) => a = a + v.sc_usage_pm, 0))) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round(((subCompanyReportList?.reduce((a, v) => a = a + v.comp_match_pm, 0))) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round(((subCompanyReportList?.reduce((a, v) => a = a + v.comp_usage_pm, 0))) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round(((subCompanyReportList?.reduce((a, v) => a = a + v.sc_share, 0))) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round(((subCompanyReportList?.reduce((a, v) => a = a + v.sc_usage_share, 0))) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round(((subCompanyReportList?.reduce((a, v) => a = a + v.sc_earning, 0))) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round(((subCompanyReportList?.reduce((a, v) => a = a + v.company_earning, 0))) * 100) / 100)}</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
          :
          (null)
      }

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Users Report</h5>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>MatchOdds Plus Minus</th>
                      <th>Other Market Plus Minus</th>
                      <th>Bookmaker Plus Minus</th>
                      <th>Session Plus Minus</th>
                      <th>Total</th>
                      <th>Match Commission</th>
                      <th>Session Commission</th>
                      <th>Other Commission</th>
                      <th>Total Commission</th>
                      <th>Net</th>
                      <th>OTHERS</th>
                      <th>Final</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                  <thead>
                    {
                      userReportList?.map((el, ind) => {
                        return (
                          <tr key={ind}>
                            <th>{el.username} ({(el.first_name)}) </th>
                            <th>{convertParseFloat(Math.round((el.match_odds_pm) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.other_pm) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.bookmaker_pm) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.session_pm) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.match_pm + el.session_pm) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.match_commission) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.session_commission) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.eo_commission + el.sd_commission) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round(((el.eo_commission + el.sd_commission)+ el.match_commission + el.session_commission) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round(((el.match_pm + el.session_pm) - ((el.eo_commission + el.sd_commission) + el.match_commission + el.session_commission)) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.other_charges) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.final_total) * 100) / 100)}</th>
                          </tr>
                        )
                      })
                    }

                    <tr>
                      <th>Total</th>
                      <th>{convertParseFloat(userReportList.reduce((a, v) => a = a + v.match_odds_pm, 0))}</th>
                      <th>{convertParseFloat(userReportList.reduce((a, v) => a = a + v.other_pm, 0))}</th>
                      <th>{convertParseFloat(userReportList.reduce((a, v) => a = a + v.bookmaker_pm, 0))}</th>
                      <th>{convertParseFloat(userReportList.reduce((a, v) => a = a + v.session_pm, 0))}</th>
                      <th>{convertParseFloat(Math.round(((userReportList.reduce((a, v) => a = a + v.match_odds_pm, 0)) + (userReportList.reduce((a, v) => a = a + v.other_pm, 0)) + (userReportList.reduce((a, v) => a = a + v.session_pm, 0))) * 100) / 100)}
                      </th>
                      <th>{convertParseFloat(Math.round(((userReportList.reduce((a, v) => a = a + v.match_commission, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((userReportList.reduce((a, v) => a = a + v.session_commission, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((userReportList.reduce((a, v) => a = a + v.eo_commission +v.sd_commission, 0))) * 100) / 100)}</th>
                      <th>
                        {convertParseFloat(Math.round(((userReportList.reduce((a, v) => a = a + v.match_commission, 0)) + (userReportList.reduce((a, v) => a = a + (v.session_commission + (v.eo_commission +v.sd_commission)), 0))) * 100) / 100)}
                      </th>
                      <th>
                        {convertParseFloat(((userReportList.reduce((a, v) => a = a + v.match_pm, 0)) + (userReportList.reduce((a, v) => a = a + v.session_pm, 0))) - ((userReportList.reduce((a, v) => a = a + v.match_commission, 0)) + (userReportList.reduce((a, v) => a = a + (v.session_commission + (v.eo_commission +v.sd_commission)), 0))))}
                      </th>
                      <th>{convertParseFloat(Math.round(((userReportList.reduce((a, v) => a = a + v.other_charges, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((userReportList.reduce((a, v) => a = a + v.final_total, 0))) * 100) / 100)}</th>
                    </tr>

                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Agent Report</h5>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>MatchOdds Plus Minus</th>
                      <th>Other Market Plus Minus</th>
                      <th>Bookmaker Plus Minus</th>
                      <th>Session Plus Minus</th>
                      <th>Total</th>
                      <th>Match Commission</th>
                      <th>Session Commission</th>
                      <th>Other Commission</th>
                      <th>Total Commission</th>
                      <th>Net</th>
                      <th>Agent Share</th>
                      <th>OTHERS</th>
                      <th>Final</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                  <thead>
                    {
                      agentReportList.map((el, ind) => {
                        return (
                          <tr key={ind}>
                            <th>{el.username} ({(el.first_name)}) </th>
                            <th>{convertParseFloat(Math.round((el.match_odds_pm) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.other_pm) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.bookmaker_pm) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.session_pm) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.match_pm + el.session_pm) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.match_commission) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.session_commission) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.eo_commission + el.sd_commission) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round(((el.eo_commission + el.sd_commission) + el.match_commission + el.session_commission) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round(((el.match_pm + el.session_pm) - ((el.eo_commission + el.sd_commission) + el.match_commission + el.session_commission)) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.agent_share) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.other_charges) * 100) / 100)}</th>
                            <th>{convertParseFloat(Math.round((el.final_total) * 100) / 100)}</th>
                          </tr>
                        )
                      })
                    }

                    <tr>
                      <th>Total</th>
                      <th>{convertParseFloat(agentReportList.reduce((a, v) => a = a + v.match_odds_pm, 0))}</th>
                      <th>{convertParseFloat(agentReportList.reduce((a, v) => a = a + v.other_pm, 0))}</th>
                      <th>{convertParseFloat(agentReportList.reduce((a, v) => a = a + v.bookmaker_pm, 0))}</th>
                      <th>{convertParseFloat(agentReportList.reduce((a, v) => a = a + v.session_pm, 0))}</th>
                      <th>{convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.match_odds_pm, 0)) + (agentReportList.reduce((a, v) => a = a + v.other_pm, 0)) + (agentReportList.reduce((a, v) => a = a + v.session_pm, 0))) * 100) / 100)}
                      </th>
                      <th>{convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.match_commission, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.session_commission, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + (v.eo_commission + v.sd_commission), 0))) * 100) / 100)}</th>
                      <th>
                        {convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.match_commission, 0)) + (agentReportList.reduce((a, v) => a = a + (v.session_commission + (v.eo_commission +v.sd_commission)), 0))) * 100) / 100)}
                      </th>
                      <th>
                        {convertParseFloat(((agentReportList.reduce((a, v) => a = a + v.match_pm, 0)) + (agentReportList.reduce((a, v) => a = a + v.session_pm, 0))) - ((agentReportList.reduce((a, v) => a = a + v.match_commission, 0)) + (agentReportList.reduce((a, v) => a = a +( v.session_commission + (v.eo_commission +v.sd_commission)), 0))))}
                      </th>
                      <th>{convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.agent_share, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.other_charges, 0))) * 100) / 100)}</th>
                      <th>{convertParseFloat(Math.round(((agentReportList.reduce((a, v) => a = a + v.final_total, 0))) * 100) / 100)}</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientReport;
