import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../lib/app-config';
import { getStorage } from "../utils";

export const userLogin = (postData) => {

    return axios.post(config.api_baseurl + 'login', postData)
        .then((response) => {
            return response.data;
        })
        .catch(err => {
            if (err && err.message == "Network Error") {
                toast.error("Please check your network connection.")
            }
            throw err;
        });
};

export const createNewUserRequest = () => {

}


//////////////////////////////////////////////////////////////////////

export const getUserLocation = () => {
    return axios
        .get(`https://pro.ip-api.com/json?key=vUjaSOlQ4RnPU1B`)
        .then((response) => {
            if (response?.status === 200) {
                return response?.data
            }
        })
        .catch((err) => {
            if (err && err.message === "Network Error") {
                toast.error("Please check your network connection.");
            }
            throw err;
        });
};
