import React from "react";
import { Link } from "react-router-dom";
class InnerPageHeader extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    var {
      pageTitle,
      breadcrumb,
      breadcrumbPath,
      action,
      breadcrumbs,
      currenPageTitle,
    } = this.props;

    const getFirstletterCaps = (data) => {
      try {
        if (data) {
          return `${data.charAt(0).toUpperCase()}${data.slice(1)}`;
        }
      } catch (err) {}
    };
    return (
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>{pageTitle ? getFirstletterCaps(pageTitle) : "Title"}</h2>
          <ol className="breadcrumb">
            {breadcrumbs && breadcrumbs.length
              ? breadcrumbs.map((breadcrumb, index) => (
                  <li key={index}>
                    <Link to={breadcrumb.url ? breadcrumb.url : ""}>
                      {breadcrumb.title}
                    </Link>
                  </li>
                ))
              : null}

            <li className="active">
              <strong>{currenPageTitle}</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2"></div>
      </div>
    );
  }
}

export default InnerPageHeader;
