import React, { Component } from "react";
import Main from "../../components/layouts/Main";
import MatkaCategoryForm from "../../components/MatkaCategory/MatkaCategoryForm";
import { connect } from "react-redux";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submit = (data) => {
    
  };

  render() {
    return (
      <Main>
        <MatkaCategoryForm
          {...this.props}
          breadcrumbTitle={'Edit Category'}
          submit={this.submit}
          edit={true}
        />
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export const EditMatkaCategory = connect(mapstatetop)(Edit);
