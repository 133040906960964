import React from "react";
import { Accordion, Button, Card, Modal } from "react-bootstrap";

function DeclaredOddsFancyView(props) {
  const {
    showDeclared,
    setDeclaredColumn,
    declaredFancyData,
    userRole,
    getDeclaredSatatusSum,
    showConfirmPoppup,
    declaredMarketData,
    isLoading,
    getWinner
  } = props;
  return (
    <Accordion activeKey={showDeclared}>
      <Card>
        <Card.Header>
          <div
            className="bet-place-tbl-th session-th-strip pointer justify-between d-flex align-center"
            onClick={() =>
              setDeclaredColumn(showDeclared === "session" ? "" : "session")
            }
            style={{ padding: "15px 6px" }}
          >
            <p className="align-left m-0">DECLARED SESSIONS</p>
            <span
              className={
                (showDeclared === "session" ? " active " : "") + " fa arrow"
              }
            ></span>
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="session">
          {declaredFancyData && declaredFancyData.length ? (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    className="bet-place-tbl-th"
                    style={{ backgroundColor: "#49494A" }}
                  >
                    SESSION
                  </th>
                  <th className="bet-odds-type bet-place-tbl-th">
                    Settled at Runs
                  </th>
                  <th className="bet-odds-type bet-place-tbl-th">Status</th>
                  {userRole?.role == 1 && (
                    <th className="bet-odds-type bet-place-tbl-th">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {declaredFancyData.map((declared, index) => (
                  <tr key={index}>
                    <td
                      data-column="session_title"
                      className="bet-place-tbl-td"
                      style={{ textAlign: "left" }}
                    >
                      {declared.runner_name}{" "}
                    </td>
                    <td className="bet-place-tbl-td bet-val-cent">
                      {declared.settlement_result}
                    </td>
                    <td
                      className="bet-place-tbl-td bet-val-cent"
                      style={{
                        color: declared.pl >= 0 ? "#228C22" : "#FF0000",
                      }}
                    >
                      {declared.pl}
                    </td>
                    {userRole?.role == 1 && (
                      <td className="bet-place-tbl-td bet-val-cent">
                        <a
                          className="btn btn-info"
                          data-method="get"
                          data-remote="true"
                          onClick={() => showConfirmPoppup("session", declared)}
                        >
                          Rollback
                        </a>
                      </td>
                    )}
                  </tr>
                ))}

                <tr>
                  <td className="bet-place-tbl-td bet-val-cent">
                    Declare Session Total
                  </td>
                  <td className="bet-place-tbl-td bet-val-cent"></td>
                  <td
                    className="bet-place-tbl-td bet-val-cent"
                    style={{
                      color:
                        getDeclaredSatatusSum(declaredFancyData) >= 0
                          ? "#228C22"
                          : "#FF0000",
                    }}
                  >
                    {getDeclaredSatatusSum(declaredFancyData)}
                  </td>
                  {userRole?.role == 1 && (
                    <td className="bet-place-tbl-td bet-val-cent"></td>
                  )}
                </tr>
              </tbody>
            </table>
          ) : isLoading ? (
            <p>Loading...</p>
          ) : (
            <p> No Data Found</p>
          )}
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <div
            className="bet-place-tbl-th session-th-strip pointer justify-between d-flex align-center"
            onClick={() =>
              setDeclaredColumn(showDeclared === "market" ? "" : "market")
            }
            style={{ padding: "15px 6px" }}
          >
            <p className="align-left m-0">DECLARED MARKET</p>
            <span
              className={
                (showDeclared === "market" ? " active " : "") + " fa arrow"
              }
            ></span>
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="market">
          {declaredMarketData && declaredMarketData.length ? (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    className="bet-place-tbl-th"
                    style={{ backgroundColor: "#49494A" }}
                  >
                    MARKET
                  </th>
                  <th className="bet-odds-type bet-place-tbl-th">WINNER</th>
                  <th className="bet-odds-type bet-place-tbl-th">Status</th>
                  {userRole?.role == 1 && (
                    <th className="bet-odds-type bet-place-tbl-th">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {declaredMarketData.map((declared, index) => (
                  <tr key={index}>
                    <td
                      data-column="session_title"
                      className="bet-place-tbl-td"
                      style={{ textAlign: "left" }}
                    >
                      {declared.market_name}{" "}
                    </td>
                    <td className="bet-place-tbl-td bet-val-cent">
                      {getWinner(declared)}
                    </td>
                    <td
                      className="bet-place-tbl-td bet-val-cent"
                      style={{
                        color: declared.pl >= 0 ? "#228C22" : "#FF0000",
                      }}
                    >
                      {declared.pl}
                    </td>
                    {userRole?.role == 1 && (
                      <td className="bet-place-tbl-td bet-val-cent">
                        <a
                          className="btn btn-info"
                          data-method="get"
                          data-remote="true"
                          onClick={() => showConfirmPoppup("market", declared)}
                        >
                          Rollback
                        </a>
                      </td>
                    )}
                  </tr>
                ))}

                <tr>
                  <td className="bet-place-tbl-td bet-val-cent">
                    Declare Market Total
                  </td>
                  <td className="bet-place-tbl-td bet-val-cent"></td>
                  <td
                    className="bet-place-tbl-td bet-val-cent"
                    style={{
                      color:
                        getDeclaredSatatusSum(declaredMarketData) >= 0
                          ? "#228C22"
                          : "#FF0000",
                    }}
                  >
                    {getDeclaredSatatusSum(declaredMarketData)}
                  </td>
                  {userRole?.role == 1 && (
                    <td className="bet-place-tbl-td bet-val-cent"></td>
                  )}
                </tr>
              </tbody>
            </table>
          ) : isLoading ? (
            <p>Loading...</p>
          ) : (
            <p> No Data Found</p>
          )}
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default DeclaredOddsFancyView;
