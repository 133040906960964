import React, { Component } from 'react'
import Main from "../../components/layouts/Main";
import CashBookComponent from '../../components/ManageLedger/CashBook';
export class CashBook extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Main>
                <CashBookComponent />
            </Main>

        )
    }
}

export default CashBook