import React, { Component } from "react";
import Main from "../../components/layouts/Main";
import AddComponent from "../../components/Staff/Add";
import { connect } from "react-redux";

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Main>
        <AddComponent {...this.props} />
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
