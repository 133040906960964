import React, { Component } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import { Link } from "react-router-dom";
import Pagination from "../common/Pagination";
import SimpleReactValidator from "simple-react-validator";
import { userProfileRequest } from "../../_actions/userprofile/userprofile.actions";
import { creditCompanyCoinsRequest } from "../../_actions/creditcompanycoins/creditcompanycoins.actions";
import { profileDetails } from "_services/userprofile.services";

class CreditCoins extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.showhideForm = this.showhideForm.bind(this);
  }
  state = {
    formObj: {
      amount: "",
    },
    showForm: "none",
    userprofile: [],
  };

  componentDidMount() {
    let sendObj = {};
    this.props.dispatch(
      userProfileRequest(sendObj, (res) => {
        if (res && res.data) {
          this.setState({
            userprofile: res.data,
          });
        }
      })
    );
  }

  handleInput = (e) => {
    const { name, value } = e.target; // <-- moved outside asynchronous context

    this.setState((state) => ({
      formObj: {
        ...state.formObj,
        [name]: value,
      },
    }));
  };

  showhideForm = () => {
    //e.preventDefault();
    this.state.showForm = "block";
    //alert(this.state.showForm);
  };

  onSubmit = (e) => {
    e.preventDefault();
    let { formObj } = this.state;
    if (this.validator.allValid()) {
      this.props.dispatch(
        creditCompanyCoinsRequest(formObj, (res) => {
          this.state.showForm = "none";
          this.componentDidMount();

          //if (res) this.setState({ redirect: `/credit_coins` });
          if (res) {
            let sendObj = {};
            this.props.dispatch(
              userProfileRequest(sendObj, (res) => {
                if (res && res.data) {
                  this.setState({
                    userprofile: res.data,
                  });
                }
              })
            );
          }

          /*profileDetails()
            .then((response) => {
              if (response && response.data && response.data.data) {
                localStorage.setItem(
                  "profile",
                  JSON.stringify(response.data.data)
                );
              }
            })
            .catch((error) => {});
          if (res) this.setState({ redirect: `/credit_coins` });*/
        })
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const {
      userprofile,
      formObj: { amount },
    } = this.state;
    //alert(showForm);
    //alert(this.state.showForm);
    return (
      <>
        <InnerPageHeader
          pageTitle="Dena Coins"
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Dena Coins", url: "/credit_coins" },
          ]}
          currenPageTitle="Dena Coins"
        />
        <div
          className="wrapper wrapper-content animated fadeInRight"
          style={{ paddingBottom: "0px" }}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}></h5>
                  <div className="ibox-tools">
                    <a
                      href="#"
                      className="btn btn-primary btn-xs"
                      onClick={() => this.showhideForm()}
                      style={{ cursor: "pointer" }}
                    >
                      Create Here For Deposit
                    </a>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Available Coins</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="gradeX">
                            <td>Company</td>
                            <td>{userprofile.balance}</td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="wrapper wrapper-content animated fadeInRight"
          style={{ paddingTop: "0px", display: this.state.showForm }}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-content">
                  <form onSubmit={this.onSubmit} className="form-horizontal">
                    <div className="form-group">
                      <label className="col-sm-3 control-label">Amount</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="amount"
                          id="amount"
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message("amount", amount, "required")}
                        </em>
                      </div>
                    </div>

                    <div className="hr-line-dashed"></div>

                    <div className="form-group">
                      <div className="col-sm-4 col-sm-offset-2">
                        <button className="btn btn-primary" type="submit">
                          Deposit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default CreditCoins;
