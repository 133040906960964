import React, { Component } from "react";
import Main from "../../../components/layouts/Main";
import { connect } from "react-redux";
import TournamentComponent from "../../../components/Tournament/TournamentComponent";
class Tournament extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Main>
        <TournamentComponent />
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Tournament);
