import axios from "axios";
import { toast } from "react-toastify";
import config from "../lib/app-config";
import { getStorage } from "../utils";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

// export const create = (data) => {
//   return axios
//     .post(config.api_baseurl + "users/create", data)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       if (err && err.message == "Network Error") {
//         toast.error("Please check your network connection.");
//       }
//       throw err;
//     });
// };

export const createNewManualMarket = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}create_manual_market`,
    data
  );
};

// export const list = (data) => {
//   const token = localStorage.getItem("token") || null;
//   return axios
//     .post(
//         `${config.api_baseurl}users/downline`,
//         data,
//     //   `${config.api_baseurl}users/downline?user_guid=${data.user_guid}&current_page=${data.current_page}&items_perpage=${data.items_perpage}&keyword=${data.keyword}&role=${data.role}`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       },
//     )
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       if (err && err.message == "Network Error") {
//         toast.error("Please check your network connection.");
//       }
//       throw err;
//     });
// };

export const listManualMarketMatchDashboard = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}manual_match_list`,
    data
  );
};

export const listMarket = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}market_list`,
    data
  );
};

export const listMatchDetail = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}match_detail`,
    data
  );
};


export const createFancy = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}create_manual_fancy`,
    data
  );
};

export const listFancyType = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}fancy_type_list`,
    data
  );
};

export const createExistManualSession = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}update_fancy_market_odds`,
    data
  );
};

export const reset = (data) => {

  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}reset_fancy_odds`,
    data
  );
};

export const createExistManualMarket = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}update_market_odds`,
    data
  );
};

export const resetMarket = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}reset_market_odds`,
    data
  );
};




