import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { Redirect } from "react-router-dom";
import AddUser from "components/common/AddUser";
import { createRequest } from "_actions/superstockists/superstockists.actions";
class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 4,
      updateData: "",
    };

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    if (this.props.location?.state?.selectedData?.user_guid) {
      this.setState({
        updateData: this.props.location.state.selectedData,
      });
    }
  }

  addSuperStockists = (sendObj) => {
    sendObj.role = this.state.role;
    this.props.dispatch(
      createRequest(sendObj, (res) => {
        this.props.history.push("/super-stockists");
      })
    );
  };

  render() {
    let { redirect, updateData } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <>
        <AddUser
          heading={
            updateData && updateData.username
              ? updateData.username
              : "New Super Stockists"
          }
          breadcrumbTitle={
            updateData && updateData.username
              ? updateData.username
              : "New Super Stockists"
          }
          breadcrumb="Agents"
          navigationUrl="/super-stockists"
          onSubmitFunc={this.addSuperStockists}
          role={this.state.role}
          action={
            updateData ? "Edit Super Stockists" : "Create Super Stockists"
          }
          history={this.props.history}
          parentType="superstockist"
          isEdit={Boolean(updateData)}
          updateData={updateData}
          showFixSharing={true}
          {...this.props}
        />
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
