import React, { Component } from "react";
import Main from "../../components/layouts/Main";
import LedgesViewPage from "components/common/LedgesViewPage";
import DirectAgentsClients from "components/common/DirectAgentsClients";
import UserCashPayReceive from "components/common/UserCashPayReceive";
import CashLedger from "components/common/CashLedger";
import MatchLedger from "components/common/MatchLedger";

import CoinHistoryComp from "components/CoinHistoryComp/CoinHistoryComp"

import { connect } from "react-redux";

class LedgesView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { subtype } = this.props.match.params;
    return (
      <Main>
        {subtype ? (
          <>
            {subtype === "directagents" || subtype === "directclients" ? (
              <DirectAgentsClients {...this.props} />
            ) : null}

            {subtype === "ledger"
              // subtype === "cashledger" ||
              // subtype === "matchledger" 
              ? (
              <LedgesViewPage {...this.props} />
            ) : null}

            {
              subtype === "matchledger" ?
                (
                  <MatchLedger {...this.props} />
                )
                :
                (null)
            }

            {
              subtype === "cashledger" ?
                (
                  <CashLedger {...this.props} />
                )
                :
                (null)
            }

            {
              subtype === "coinhistory" ?
                (
                  <CoinHistoryComp {...this.props} />
                )
                :
                (null)
            }

            {subtype === "receivecash" || subtype === "paycash" ? (
              <UserCashPayReceive {...this.props} />
            ) : null}
          </>
        ) : null}
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(LedgesView);
