import axios from "axios";
import { toast } from "react-toastify";
import config from "../lib/app-config";
import { getStorage } from "../utils";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";



export const userUplinesByUserId = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/get_user_upline`,
    data
  );
};

