import React from 'react'

export default function AllSubCompReportComp() {
    return (
        <>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-10">
                    <h2> Sub Company Report </h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong> Sub Company Report </strong>
                        </li>
                    </ol>
                </div>
                <div className="col-lg-2"></div>
            </div>

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox float-e-margins">
                            <div className="ibox-title">
                                <h5 style={{ fontWeight: 700 }}>Sub Company Report</h5>
                            </div>
                            <div className="ibox-content">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered table-hover dataTables-example">
                                        <thead>
                                            <tr>
                                                <th>DATE/TIME</th>
                                                <th>Match Id</th>
                                                <th>Match Title</th>
                                                <th>Company Match Earnings Share Percentage</th>
                                                <th>Company Usage Charges Share Percentage</th>
                                                <th>Sub Company Match Earnings</th>
                                                <th>Sub Company Usage Charges Earnings</th>
                                                <th>Company Match Earnings</th>
                                                <th>Company Usage Charges Earnings</th>
                                                <th>Sub Company Match Earnings After Company Share</th>
                                                <th>Sub Company Usage Charges Earnings After Company Share</th>
                                                <th>Sub Company Earnings After Company Share</th>
                                                <th>Company Earnings</th>
                                                <th>Company Remain Share Earnings</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Total</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
