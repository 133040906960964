import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";



export const eventScore = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}event_score`,
    data
  );
};

export const getEventScore = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}fetch_score`,
    data
  );
};
