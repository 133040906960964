import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { Redirect } from "react-router-dom";
import AddUser from "components/common/AddUser";
import { createRequest } from "_actions/master/master.actions";
class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 3,
      updateData: "",
    };

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.selectedData &&
      this.props.location.state.selectedData.user_guid
    ) {
      this.setState({
        updateData: this.props.location.state.selectedData,
      });
    }
  }
  addMaster = (sendObj) => {
    sendObj.role = this.state.role;
    this.props.dispatch(
      createRequest(sendObj, (res) => {
        this.props.history.push("/master");
      })
    );
  };

  onEditComplete = () =>{
    console.log("Edit complete");
  }
  render() {
    let { redirect, updateData } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <>
        <AddUser
          heading={
            updateData && updateData.username
              ? updateData.username
              : "New Master"
          }
          breadcrumbTitle={
            updateData && updateData.username
              ? updateData.username
              : "New Master"
          }
          breadcrumb="Agents"
          navigationUrl="/master"
          onSubmitFunc={this.addMaster}
          role={this.state.role}
          action={updateData ? "Edit Master" : "Create Master"}
          history={this.props.history}
          parentType="master"
          isEdit={Boolean(updateData)}
          updateData={updateData}
          onEditComplete={()=>this.onEditComplete()}
          showFixSharing={true}
          {...this.props}
        />
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
