import React, { Component } from "react";
import { Link } from "react-router-dom";
import InnerPageHeader from "../common/InnerPageHeader";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import Form from "react-bootstrap/Form";
import {
  createRequest,
  listMarketRequest,
} from "../../_actions/manualmarketmatchdashboard/manualmarketmatchdashboard.actions";
class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObj: {
        market_type: "",
        team_1: "",
        team_2: "",
        team_3: "",
        market_name: "",
        event_id: "",
      },
      marketList: [],
      role: 3,
      currentPage: 1,
      event_name: "",
    };
    //this.state.date = "";
    //this.state.time = "";

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    let sendObj = {
      user_guid: "",
      current_page: 1,
      items_perpage: 10,
      keyword: "",
      role: this.state.role,
    };
    this.props.dispatch(
      listMarketRequest(sendObj, (res) => {
        if (res && res.data) {
          this.setState({
            marketList: res.data,
            //totalRecords: res.data.total_records,
          });
        }
      })
    );
  }

  onSubmit = (e) => {
    e.preventDefault();
    let { formObj } = this.state;
    //this.state.formObj.datetime = '${this.state.date} ${this.state.time}';
    if (this.validator.allValid()) {
      this.props.dispatch(
        createRequest(formObj, (res) => {
          toast();
          this.setState((state) => ({
            formObj: {
              ...state.formObj,
              market_type: "",
              team_1: "",
              team_2: "",
              team_3: "",
              team_4: "",
              team_5: "",
              team_6: "",
              team_7: "",
              team_8: "",
              team_9: "",
              team_10: "",
              market_name: "",
              event_id: "",
            },
           
          }));
          if(res){
            setTimeout(() => {
              window.location.reload();

            }, 1000);
          }
        })
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInput = (e) => {
    const { name, value } = e.target; // <-- moved outside asynchronous context
    if (name == "market_type") {
        if(value!=6 && value!=7){
          let marketName = '';
          const marketList = this.state.marketList;
          for(let i=0;i<marketList.length;i++){
            if(marketList[i].type_id==value){
              marketName = marketList[i].type;
            }
          }
          this.setState((state) => ({
            formObj: {
              ...state.formObj,
              market_name: marketName
            },
          }));
        }
        if(value==6){
          this.setState((state) => ({
            formObj: {
              ...state.formObj,
              ['team_1']: 'Even',
              ['team_2']: 'Odd',
              ['team_3']: '',
              ['team_4']: '',
              ['team_5']: '',
              ['team_6']: '',
              ['team_7']: '',
              ['team_8']: '',
              ['team_9']: '',
              ['team_10']: '',
            },
          }));
        }
        else if(value==7){
          this.setState((state) => ({
            formObj: {
              ...state.formObj,
              ['team_1']: '0',
              ['team_2']: '1',
              ['team_3']: '2',
              ['team_4']: '3',
              ['team_5']: '4',
              ['team_6']: '5',
              ['team_7']: '6',
              ['team_8']: '7',
              ['team_9']: '8',
              ['team_10']: '9',
            },
          }));
        }
        else{
          this.setState((state) => ({
            formObj: {
              ...state.formObj,
              ['team_1']: '',
              ['team_2']: '',
              ['team_3']: '',
              ['team_4']: '',
              ['team_5']: '',
              ['team_6']: '',
              ['team_7']: '',
              ['team_8']: '',
              ['team_9']: '',
              ['team_10']: '',
            },
          }));
        }
    }
    this.setState((state) => ({
      formObj: {
        ...state.formObj,
        [name]: value,
      },
    }));
    
  };

  render() {
    const { event_id, event_name } = this.props.match.params;
    const url =
      "/manual-market-match-dashboard/list/" + event_id + "/" + event_name;
    const {
      redirect,
      marketList,
      formObj: { market_type, team_1, team_2,team_3,
        team_4, team_5, team_6, team_7, team_8, team_9, team_10,
        market_name },
    } = this.state;
    this.state.formObj.event_id = event_id;
    this.state.event_name = event_name.replace(/\s+/g, " ");
    const {
      heading,
      breadcrumbTitle,
      breadcrumb,
      navigationUrl,
      action,
      parentType,
    } = this.props;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <>
        
        <InnerPageHeader
          pageTitle={event_name}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Manual Market Dashboard", url: url },
          ]}
          currenPageTitle="Add"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-content">
                  <form onSubmit={this.onSubmit} className="form-horizontal">
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Match Type
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="hidden"
                          name="event_id"
                          id="event_id"
                          value={event_id}
                        />
                        <select
                          className="form-control"
                          name="market_type"
                          id="market_type"
                          onChange={(e) => this.handleInput(e)}
                        >
                          <option value="">Select Market</option>
                          {marketList && marketList.length ? (
                            marketList.map((user, index) => (
                              <option value={user.type_id} key={index}>
                                {user.type}
                              </option>
                            ))
                          ) : (
                            <option value="">No data available in table</option>
                          )}
                        </select>
                        <em>
                          {this.validator.message(
                            "market_type",
                            market_type,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    {(this.state.formObj.market_type==6 || this.state.formObj.market_type==7)&&
                    <>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">Market Name</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="market_name"
                          id="market_name"
                          value={market_name}
                          onChange={(e) => this.handleInput(e)}
                        />
                        {(this.state.formObj.market_type==6 || this.state.formObj.market_type==7)&&
                        <em>
                          {this.validator.message("market_name", market_name, "required")}
                        </em>
                        }
                      </div>
                    </div>
                    </>
                    }
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">Option 1</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="team_1"
                          id="team_1"
                          value={team_1}
                          disabled={this.state.formObj.market_type==6 || this.state.formObj.market_type==7}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message("team_1", team_1, "required")}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">Option 2</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="team_2"
                          id="team_2"
                          value={team_2}
                          disabled={this.state.formObj.market_type==6 || this.state.formObj.market_type==7}
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message("team_2", team_2, "required")}
                        </em>
                      </div>
                    </div>
                      {this.state.formObj.market_type!=6&&
                      <>      
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Option 3
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="team_3"
                            id="team_3"
                            disabled={this.state.formObj.market_type==7}
                            value={team_3}
                            onChange={(e) => this.handleInput(e)}
                          />
                        
                        </div>
                      </div>
                        </>
                      }
                      {this.state.formObj.market_type==7 &&
                      <>      
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Option 4
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="team_4"
                              id="team_4"
                              disabled={true}
                              value={team_4}
                              onChange={(e) => this.handleInput(e)}
                            />
                          
                          </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Option 5
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="team_5"
                              id="team_5"
                              disabled={true}
                              value={team_5}
                              onChange={(e) => this.handleInput(e)}
                            />
                          
                          </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Option 6
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="team_6"
                              id="team_6"
                              disabled={true}
                              value={team_6}
                              onChange={(e) => this.handleInput(e)}
                            />
                          
                          </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Option 7
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="team_7"
                              id="team_7"
                              disabled={true}
                              value={team_7}
                              onChange={(e) => this.handleInput(e)}
                            />
                          
                          </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Option 8
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="team_8"
                              id="team_8"
                              disabled={true}
                              value={team_8}
                              onChange={(e) => this.handleInput(e)}
                            />
                          
                          </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Option 9
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="team_9"
                              id="team_9"
                              disabled={true}
                              value={team_9}
                              onChange={(e) => this.handleInput(e)}
                            />
                          
                          </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="form-group">
                          <label className="col-sm-3 control-label">
                            Option 10
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="team_10"
                              id="team_10"
                              disabled={true}
                              value={team_10}
                              onChange={(e) => this.handleInput(e)}
                            />
                          
                          </div>
                        </div>
                      </>
                      }
                    <div className="hr-line-dashed"></div>

                    <div className="form-group">
                      <div className="col-sm-4 col-sm-offset-2">
                        <button className="btn btn-primary" type="submit">
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
