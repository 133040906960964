import React from 'react'
import { Button, Col, Modal, Row } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import './colorModal.css'

const ColorModal = (props) => {
    const { isShow = false, title = "Choose Theme Colour", colorList, onCancle, onSubmit, selectColor, pickedColor } = props

    return (
        <Modal
            show={isShow}
            onHide={() => onCancle()}
            style={{ opacity: 1 }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='color_modal'
        >
            <Modal.Header closeButton><h3>{title}</h3></Modal.Header>
            <Modal.Body>
                <Row className="mt-3 color_row ">
                    {colorList.filter((item) => +item.is_default === 1).map(theme =>
                        <Col key={theme?.id} className={(theme?.id === pickedColor.id ? "picked_color " : "") + " color_col mx-1 my-0"} xs="2" md="2" sm="2" lg="2" style={{ margin: "10px 20px" }}>
                            <div className="d-flex" onClick={() => selectColor(theme)}>
                                <div
                                    className="color-botton even"
                                    style={{ backgroundColor: theme?.color_code?.primary }}
                                >
                                </div>
                                <div
                                    className="color-botton odd"
                                    style={{ backgroundColor: theme?.color_code?.secondary }}
                                >
                                </div>
                            </div>

                        </Col>
                    )}
                </Row>
                <Row className="mt-3 color_row ">

                    {colorList.filter((item) => +item.is_default !== 1).map(theme =>
                        <Col key={theme?.id} className={(theme?.id === pickedColor.id ? "picked_color " : "") + " color_col mx-1 my-0"} xs="2" md="2" sm="2" lg="2" style={{ margin: "10px 20px" }}>
                            <div className="d-flex" onClick={() => selectColor(theme)}>
                                <div
                                    className="color-botton even"
                                    style={{ backgroundColor: theme?.color_code?.primary }}
                                >
                                </div>
                                <div
                                    className="color-botton odd"
                                    style={{ backgroundColor: theme?.color_code?.secondary }}
                                >
                                </div>
                            </div>

                        </Col>
                    )}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex flex-center'>
                    <Button className='save_color' size='lg' onClick={() => onSubmit()}>Save
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ColorModal