export const subcompanyConstants = {
  SUBCOMPANY_CREATE_REQUEST: "SUBCOMPANY_CREATE_REQUEST",
  SUBCOMPANY_CREATE_SUCCESS: "SUBCOMPANY_CREATE_SUCCESS",
  SUBCOMPANY_CREATE_FAILED: "SUBCOMPANY_CREATE_FAILED",
  SUBCOMPANY_LIST_REQUEST: "SUBCOMPANY_LIST_REQUEST",
  SUBCOMPANY_LIST_SUCCESS: "SUBCOMPANY_LIST_SUCCESS",
  SUBCOMPANY_LIST_FAILED: "SUBCOMPANY_LIST_FAILED",
  themeColors : {
    color1: {
      primary: "#49418C", light: "#9A93E0", dark: "#302555" // Purple
    },
    color2: {
      primary: "#E84B18", light: "#F49D7E", dark: "#D34315" // Red
    },
    color3: {
      primary: "#43b5e0", light:"#84d0eb", dark: "#22a0ce" // Blue
    },
    color4: {
      primary: "#7dd834", light: "#bbeb94", dark: "#3B7531" // Green
    },
  }
};
