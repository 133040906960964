import React, { Component } from "react";
import { Link } from "react-router-dom";
import InnerPageHeader from "../common/InnerPageHeader";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import Form from "react-bootstrap/Form";
import {
  createFancyRequest,
  listFancyTypeRequest,
} from "../../_actions/manualmarketmatchdashboard/manualmarketmatchdashboard.actions";
class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObj: {
        event_id: "",
        session_type_id: "",
        session_name: "",
      },
      FancyTypeList: [],
      role: 3,
      currentPage: 1,
      event_name: "",
    };
    //this.state.date = "";
    //this.state.time = "";

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    let sendObj = {
      user_guid: "",
      current_page: 1,
      items_perpage: 10,
      keyword: "",
      role: this.state.role,
    };
    this.props.dispatch(
      listFancyTypeRequest(sendObj, (res) => {
        if (res && res.data) {
          this.setState({
            FancyTypeList: res.data,
            //totalRecords: res.data.total_records,
          });
        }
      })
    );
  }

  onSubmit = (e) => {
    e.preventDefault();
    const customId = "custom-id-yes";
    let { formObj } = this.state;
    //this.state.formObj.datetime = '${this.state.date} ${this.state.time}';
    if (this.validator.allValid()) {
      this.props.dispatch(
        createFancyRequest(formObj, (res) => {
          if (res.error != "") {
            toast(res.error, {
              toastId: customId,
            });
          } else {
            toast.dismiss();
            toast({
              toastId: customId,
            });
          }
        })
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInput = (e) => {
    const { name, value } = e.target; // <-- moved outside asynchronous context

    if (name == "market_type") {
      this.state.formObj.market_name =
        e.target.options[e.target.selectedIndex].text;
    }
    this.setState((state) => ({
      formObj: {
        ...state.formObj,
        [name]: value,
      },
    }));
  };

  render() {
    const { event_id, event_name } = this.props.match.params;
    this.state.event_name = event_name.replace(/\s+/g, " ");
    this.state.formObj.event_id = event_id;
    const { FancyTypeList } = this.state;
    let { redirect } = this.state;
    const breadcrumbPath = `/manual-market-match-dashboard/list/${this.state.formObj.event_id}/${this.state.event_name}`;
    const {
      heading,
      breadcrumbTitle,
      breadcrumb,
      navigationUrl,
      action,
      parentType,
    } = this.props;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <>
        
        <InnerPageHeader
          pageTitle={event_name}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Manual Market Dashboard", url: breadcrumbPath },
          ]}
          currenPageTitle="Add"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-content">
                  <form onSubmit={this.onSubmit} className="form-horizontal">
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Match Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="hidden"
                          name="event_id"
                          id="event_id"
                          value={event_id}
                        />
                        <input
                          type="text"
                          value={event_name}
                          onChange={(e) => this.handleInput(e)}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Select Session Type
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="session_type_id"
                          id="session_type_id"
                          onChange={(e) => this.handleInput(e)}
                        >
                          <option value="">Select...</option>
                          {FancyTypeList && FancyTypeList.length ? (
                            FancyTypeList.map((user, index) => (
                              <option value={user.id} key={index}>
                                {user.session_type}
                              </option>
                            ))
                          ) : (
                            <option value="">No data available in table</option>
                          )}
                        </select>
                        <em>
                          {this.validator.message(
                            "session_type_id",
                            this.state.formObj.session_type_id,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                      <label className="col-sm-3 control-label">
                        Session Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="session_name"
                          id="session_name"
                          onChange={(e) => this.handleInput(e)}
                        />
                        <em>
                          {this.validator.message(
                            "session_name",
                            this.state.formObj.session_name,
                            "required"
                          )}
                        </em>
                      </div>
                    </div>

                    <div className="hr-line-dashed"></div>

                    <div className="form-group">
                      <div className="col-sm-4 col-sm-offset-2">
                        <button className="btn btn-primary" type="submit">
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Add);
