import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";
import config from "../lib/app-config";

export const alltime_pl = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}alltime_pl`,
    data
  );
};
