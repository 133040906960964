import { userListByKeywordConstants } from "../../_constants";
import { userListByKeyword } from "../../_services";
import { toast } from "react-toastify";

export const userListByKeywordRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({
      type: userListByKeywordConstants.USER_LIST_BY_KEYWORD_REQUEST,
      data: {},
    });
    userListByKeyword(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: userListByKeywordConstants.USER_LIST_BY_KEYWORD_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: userListByKeywordConstants.USER_LIST_BY_KEYWORD_FAILURE,
          data: error.response,
        });
      });
  };
};
  