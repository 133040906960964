import React, { Component } from "react";
import Main from "../../components/layouts/Main";
import CommissionLimitComponent from "components/Client/CommissionLimit";
export class CommissionLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Main>
        <CommissionLimitComponent {...this.props} />
      </Main>
    );
  }
}

export default CommissionLimit;
