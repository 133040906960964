export const manualmarketmatchdashboardConstants = {
  MANUALMARKET_CREATE_REQUEST: "MANUALMARKET_CREATE_REQUEST",
  MANUALMARKET_CREATE_SUCCESS: "MANUALMARKET_CREATE_SUCCESS",
  MANUALMARKET_CREATE_FAILED: "MANUALMARKET_CREATE_FAILED",
  MANUALMARKETMATCHDASHBOARD_LIST_REQUEST: "MANUALMARKETMATCHDASHBOARD_LIST_REQUEST",
  MANUALMARKETMATCHDASHBOARD_LIST_SUCCESS: "MANUALMARKETMATCHDASHBOARD_LIST_SUCCESS",
  MANUALMARKETMATCHDASHBOARD_LIST_FAILED: "MANUALMARKETMATCHDASHBOARD_LIST_FAILED",
  MARKET_LIST_REQUEST: "MARKET_LIST_REQUEST",
  MARKET_LIST_SUCCESS: "MARKET_LIST_SUCCESS",
  MARKET_LIST_FAILED: "MARKET_LIST_FAILED",
  MATCH_DETAIL_REQUEST: "MATCH_DETAIL_REQUEST",
  MATCH_DETAIL_SUCCESS: "MATCH_DETAIL_SUCCESS",
  MATCH_DETAIL_FAILED: "MATCH_DETAIL_FAILED",
  FANCY_CREATE_REQUEST: "FANCY_CREATE_REQUEST",
  FANCY_CREATE_SUCCESS: "FANCY_CREATE_SUCCESS",
  FANCY_CREATE_FAILED: "FANCY_CREATE_FAILED",
  FANCY_TYPE_LIST_REQUEST: "FANCY_TYPE_LIST_REQUEST",
  FANCY_TYPE_LIST_SUCCESS: "FANCY_TYPE_LIST_SUCCESS",
  FANCY_TYPE_LIST_FAILED: "FANCY_TYPE_LIST_FAILED",
  EXIST_MANUAL_SESSION_CREATE_REQUEST: "EXIST_MANUAL_SESSION_CREATE_REQUEST",
  EXIST_MANUAL_SESSION_CREATE_SUCCESS: "EXIST_MANUAL_SESSION_CREATE_SUCCESS",
  EXIST_MANUAL_SESSION_CREATE_FAILED: "EXIST_MANUAL_SESSION_CREATE_FAILED",
  RESET_CREATE_REQUEST: "RESET_CREATE_REQUEST",
  RESET_CREATE_SUCCESS: "RESET_CREATE_SUCCESS",
  RESET_CREATE_FAILED: "RESET_CREATE_FAILED",
  EXIST_MANUAL_MARKET_CREATE_REQUEST: "EXIST_MANUAL_MARKET_CREATE_REQUEST",
  EXIST_MANUAL_MARKET_CREATE_SUCCESS: "EXIST_MANUAL_MARKET_CREATE_SUCCESS",
  EXIST_MANUAL_MARKET_CREATE_FAILED: "EXIST_MANUAL_MARKET_CREATE_FAILED",
  RESET_MARKET_CREATE_REQUEST: "RESET_MARKET_CREATE_REQUEST",
  RESET_MARKET_CREATE_SUCCESS: "RESET_MARKET_CREATE_SUCCESS",
  RESET_MARKET_CREATE_FAILED: "RESET_MARKET_CREATE_FAILED",
  RESET_MARKET_REQUEST: "RESET_MARKET_REQUEST",
  RESET_MARKET_SUCCESS: "RESET_MARKET_SUCCESS",
  RESET_MARKET_FAILED: "RESET_MARKET_FAILED"
}
