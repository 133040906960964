import React from "react";
import Progress from "../common/Progress";
import Navigation from "../common/Navigation";
import Footer from "../common/Footer";
import TopHeader from "../common/TopHeader";
import { correctHeight, detectBody } from "./Helpers";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import Banner from "components/common/Banner";

export const BannerContext = React.createContext();
export const BannerProvider = BannerContext.Provider;
export const BannerConsumer = BannerContext.Consumer;

class Main extends React.Component {
  state = {
    toggleBanner: true,
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      toggleBanner: !prevState.toggleBanner,
    }));
  };
  render() {
    let wrapperClass = "gray-bg ";
    return (
      <BannerProvider
        value={{
          toggleBanner: this.state.toggleBanner,
          setToggleBaner: this.handleToggle,
        }}
      >
        <div id="wrapper">
          <Progress />
          <Navigation location={this.props.location} />

          <div id="page-wrapper" className={wrapperClass}>
            <TopHeader />
            <Banner toggleBanner={this.state.toggleBanner} />
            {this.props.children}

            <Footer />
          </div>
        </div>
      </BannerProvider>
    );
  }

  componentDidMount() {
    // Run correctHeight function on load and resize window event
    $(window).bind("load resize", function () {
      correctHeight();
      detectBody();
    });

    // Correct height of wrapper after metisMenu animation.
    $(".metismenu a").click(() => {
      setTimeout(() => {
        correctHeight();
      }, 300);
    });
  }
}

export default withRouter(Main);
