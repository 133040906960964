import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { Redirect } from "react-router-dom";
import EditSubCompany from "components/SubCompany/Edit";
import { createRequest } from "_actions/subcompany/subcompany.actions";
import Main from "components/layouts/Main";
import { updateSubCompany } from "_services";
import { toast } from "react-toastify";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 2,
      updateData: "",
    };

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    if (this.props.location?.state?.selectedData?.user_guid) {
      this.setState({
        updateData: this.props.location.state.selectedData,
      });
    }
  }

  //   addSuperStockists = (sendObj) => {
  //     sendObj.role = this.state.role;
  //     this.props.dispatch(
  //       createRequest(sendObj, (res) => {
  //         this.props.history.push("/manage-subcompany");
  //       })
  //     );
  //   };

  editSubCompany = (editObj = {}) => {
    updateSubCompany(editObj)
      .then((response) => {
        this.props.history.goBack()

        toast.success(
          <div>
            <i
              className="fa fa-check"
              style={{ fontWeight: "bold", marginRight: "1rem" }}
              aria-hidden="true"
            ></i>
            <span> updated successfully</span>
          </div>,
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );

      })
      .catch((error) => {

        let msg = error.response.data.error

        toast.error(
          <div>
            <i
              className="fa fa-check"
              style={{ fontWeight: "bold", marginRight: "1rem" }}
              aria-hidden="true"
            ></i>
            <span> {msg} </span>
          </div>,
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      });
  };

  render() {
    let { redirect, updateData } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <Main>
        <EditSubCompany
          heading={
            updateData && updateData.username
              ? updateData.username
              : "New Sub Company"
          }
          breadcrumbTitle={
            updateData && updateData.username
              ? updateData.username
              : "New Sub Company"
          }
          breadcrumb={"Sub Company List"}
          navigationUrl={this.props.location.state.lastPath ? this.props.location.state.lastPath : "/manage-subcompany"}
          onSubmitFunc={this.editSubCompany}
          role={this.state.role}
          action={updateData ? "Edit Sub Company" : "Create Sub Company"}
          history={this.props.history}
          parentType="subcompany"
          isEdit={Boolean(updateData)}
          updateData={updateData}
          {...this.props}
        />

        
      </Main>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(Edit);
