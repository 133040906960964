import { leagueUpdateConstants } from "../../_constants";
import { activeDeactiveLeague } from "../../_services";
import { toast } from "react-toastify";

export const updateLeagueStatus = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: leagueUpdateConstants.LEAGUE_UPDATE_REQUEST, data: {} });
    activeDeactiveLeague(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: leagueUpdateConstants.LEAGUE_UPDATE_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: leagueUpdateConstants.LEAGUE_UPDATE_FAILED,
          data: error.response,
        });
      });
  };
};
