import React, { Component, Fragment } from 'react';
import './Language.css';
import { Link } from "react-router-dom";
export class Language extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeLanguage: 'en'
        }
    }

    getRole = () => {
        var roleValue = "User";
        let role = localStorage.getItem("role") || null;
        switch (role) {
            case "1":
                roleValue = "Company";
                break;
            case "2":
                roleValue = "Sub Company";
                break;
            case "3":
                roleValue = "Master";
                break;
            case "4":
                roleValue = "Super Stockist";
                break;
            case "5":
                roleValue = "Stockist";
                break;
            case "6":
                roleValue = "Agent";
                break;
            case "7":
                roleValue = "Client";
                break;
            default:
                roleValue = "User";
        }

        return roleValue;
    };

    handleClick = (language) => {
        let active = '';
        switch (language) {
            case 'hi':
                active = language;
                break;
            case 'en':
                active = language;
                break;
            case 'ta':
                active = language;
                break;
            case 'mr':
                active = language;
                break;
            default:
                active = '';
        }

        this.setState({ activeLanguage: active })
    }


    render() {
        const { activeLanguage } = this.state;
        return (
            <Fragment>
                <div className="row wrapper border-bottom white-bg page-heading">
                    <div className="col-lg-10">
                        <h2>{this.getRole()}</h2>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <strong>Language</strong>
                            </li>
                        </ol>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
                <div className="wrapper wrapper-content animated fadeInRight">
                    <div className="row">
                        <div className="col-12">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <h5>Change Language</h5>
                                </div>
                                <div className="ibox-content"
                                    style={{ padding: "15px 20px 56px" }}
                                >
                                    <div className="dd">
                                        <ol className="dd-list">
                                            <li className="dd-item"
                                                onClick={() => { this.handleClick('en') }}
                                            >
                                                <div className={"dd-handle " + (activeLanguage === 'en' && ' active-lang')}>
                                                    English
                                                </div>
                                            </li>
                                            <li className="dd-item"
                                                onClick={() => { this.handleClick('hi') }}>
                                                <div className={"dd-handle " + (activeLanguage === 'hi' && ' active-lang')}>
                                                    हिंदी
                                                </div>
                                            </li>
                                            <li className="dd-item"
                                                onClick={() => { this.handleClick('ta') }}>
                                                <div className={"dd-handle " + (activeLanguage === 'ta' && ' active-lang')}>
                                                    தமிழ்
                                                </div>
                                            </li>
                                            <li className="dd-item"
                                                onClick={() => { this.handleClick('mr') }}
                                            >
                                                <div className={"dd-handle " + (activeLanguage === 'mr' && ' active-lang')}>
                                                    मराठी
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </Fragment >



        )
    }
}

export default Language