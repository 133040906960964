import { authConstants } from "../../_constants";
import { userLogin,createNewUserRequest } from "../../_services";
import {toast} from 'react-toastify';

export const loginUser = (postData,callback) => {
  return (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST, data: {} });
      userLogin(postData).then((response) => {
      callback(response?.data);
      dispatch({ type: authConstants.LOGIN_SUCCESS, data: response.data });

    }).catch((error) => {
      const { response } = error
      toast.error(response?.data?.error);
      callback(false);
      dispatch({ type: authConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const logoutUser = (callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: authConstants.LOGOUT_REQUEST, data: {} });
      localStorage.clear(); 
      callback(true);
      dispatch({ type: authConstants.LOGOUT_SUCCESS, data: {} });
      // toast.success("Logout successfully");
    } catch (error) {
      localStorage.clear();
      callback(false);
      dispatch({ type: authConstants.LOGOUT_FAILED, data: {} });
    }
  };
};