import { userUplinesByUserIdConstants } from "../../_constants";
import { userUplinesByUserId } from "../../_services";
import { toast } from "react-toastify";

export const userUplinesByUserIdRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({
      type: userUplinesByUserIdConstants.USER_UPLINES_BY_USERID_REQUEST,
      data: {},
    });
    userUplinesByUserId(data)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: userUplinesByUserIdConstants.USER_UPLINES_BY_USERID_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        callback(false);
        dispatch({
          type: userUplinesByUserIdConstants.USER_UPLINES_BY_USERID_FAILURE,
          data: error.response,
        });
      });
  };
};
  