import React, { Component } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import { Link } from "react-router-dom";
import { listRequest } from "../../_actions/client/client.actions";
import Pagination from "../common/Pagination";

import ReactPaginate from "react-paginate";
import { debounce } from "lodash";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientList: [],
      totalRecords: 0,
      role: 7,
      currentPage: 1,
      currentlyPage: 1,
      perPage: 10,
      pageCount: 0,
      records_all: "",
      keyword: "",
    };

    this.getTodos = this.getTodos.bind(this);
  }
  debouncedLog = debounce((text) => this.getTodos(text), 500);

  componentDidMount() {
    this.getTodos();
  }

  getTodos = () => {
    let sendObj = {
      user_guid: "",
      current_page: this.state.currentlyPage,
      items_perpage: this.state.perPage,
      keyword: this.state.keyword,
      role: this.state.role,
    };
    this.props.dispatch(
      listRequest(sendObj, (res) => {
        if (res && res.data) {
          this.setState({
            clientList: res.data.userdownlist,
            totalRecords: res.data.total_records,
            records_all: Math.ceil(res.data.total_records / this.state.perPage),
          });
        }
      })
    );
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState({ currentlyPage: selectedPage + 1 }, () => {
      this.getTodos();
    });
  };

  handleChange = (e) => {
    this.setState({ keyword: e.target.value });
    this.debouncedLog(e.target.value);
  };

  getRecordDetails = () => {
    const { currentPage, totalRecords } = this.state;
    let startingCount =
      this.state.currentPage === 1
        ? 1
        : this.state.currentPage * this.state.perPage - 9;
    let endingCount =
      this.state.perPage < totalRecords
        ? this.state.currentPage === 1
          ? this.state.perPage
          : this.state.currentPage * 10
        : totalRecords;
    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };

  render() {
    const {
      clientList,
      totalRecords,
      perPage,
      pageCount,
      records_all,
      currentPage,
    } = this.state;

    return (
      <>
        <InnerPageHeader
          pageTitle="Client List"
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: "Client", url: "/my-clients" },
          ]}
          currenPageTitle="List"
        />

        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5 style={{ fontWeight: 700 }}>All Users</h5>
                  <div className="ibox-tools">
                    <a
                      href="/my-clients/add"
                      className="btn btn-primary btn-xs"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/my-clients/add");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Create new User
                    </a>
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      <div className="dt-buttons btn-group">
                        <a
                          className="dt-button buttons-csv buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                          style={{ marginRight: "0.5rem" }}
                        >
                          <span>CSV</span>
                        </a>
                        <a
                          className="dt-button buttons-pdf buttons-html5"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          href="#"
                        >
                          <span>PDF</span>
                        </a>
                      </div>

                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          Search:
                          <input
                            type="search"
                            className="form-control input-sm"
                            placeholder=""
                            aria-controls="DataTables_Table_0"
                            value={this.state.keyword}
                            onChange={this.handleChange}
                          />
                        </label>
                      </div>

                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>User Name</th>
                            <th>Name</th>
                            <th>Fixed Limit</th>
                            <th>My Matka Share</th>
                            {/* <th>Agent Matka Share</th> */}
                            <th>Matka commission</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clientList && clientList.length ? (
                            clientList.map((user, index) => (
                              <tr className="gradeX" key={index}>
                                <td>
                                  {index + 1 + (currentPage - 1) * perPage}
                                </td>
                                <td>
                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.history.push(
                                        `/userview/client/${user.user_guid}/${user.username}`,
                                        {
                                          lastPath:
                                            this.props.location.pathname,
                                        }
                                      );
                                    }}
                                  >
                                    {user.username} ({user.first_name})
                                  </a>
                                </td>
                                <td>
                                  {user.first_name} {user.last_name}
                                </td>
                                <td className="center">{user?.fixed_limit}</td>
                                <td className="center">{user?.matka_share}</td>
                                {/* <td className="center">
                                  {user?.matka_agent_share}
                                </td> */}
                                <td className="center">
                                  {user?.matka_commission}
                                </td>
                                <td className="">
                                  <a
                                    className="btn btn-white btn-sm"
                                    href={`/my-clients/edit/${user?.user_guid}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.history.push({
                                        pathname: `/my-clients/edit/${user?.user_guid}`,
                                        state: { selectedData: user },
                                      });
                                    }}
                                  >
                                    <i className="fa fa-pencil-square"></i> Edit
                                  </a>
                                  &nbsp;&nbsp; &nbsp;&nbsp;
                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.history.push(
                                        `/edit_password/${user.user_guid}`
                                      );
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-w-m btn-primary"
                                    >
                                      Change Password
                                    </button>
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="gradeX">
                              <td colSpan={7}>No data available in table</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      <div className="row" style={{ margin: "15px 0px" }}>
                        <div className="col-xs-12 col-sm-6">
                          <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite"
                          >
                            {totalRecords
                              ? this.getRecordDetails()
                              : "Showing 0 to 0 of 0 entries"}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="paginate_class">
                            <ReactPaginate
                              previousLabel={"prev"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.records_all}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                        <div className="clearfix visible-xs"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default List;
