import config from "../lib/app-config";
import { performRequest } from "_apiConfig/apiHandler";
import { default as apiEndPoints } from "_apiConfig/apiEndPoints";

export const usersReceiveCash = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/pay_receive_cash`,
    data
  );
};

export const usersPayCash = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/pay_receive_cash`,
    data
  );
};

export const usersLedger = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}agentledger`,
    data
  );
};

export const usersDirectAgent = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/direct_agent`,
    data
  );
};

export const usersDirectClient = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}users/direct_client`,
    data
  );
};

export const cashLedger = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}userLedger`,
    data
  );
};

export const matchLedger = (data) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${config.api_baseurl}userLedger`,
    data
  );
};
